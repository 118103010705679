import { useEffect, useState } from "react"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import {
  Typography,
  Card,
  CardHeader,
  Tooltip,
  IconButton,
  CardBody,
} from "@material-tailwind/react"
import { FaTrash } from "react-icons/fa"

import http from "services/http-common"
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap"
import Substances from "./Substances"
import AddGroup from "./AddGroup"
import AddSubstance from "./AddSubstance"
import {
  getAllConditions,
  getAllContraindications,
  getAllGroups,
  getAllSubstances,
} from "store/actions"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"

const AllGroups = () => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState("")
  const { allGroups, allSubstances, allConditions, allContraindications } =
    useSelector(state => state.Contraindication)

  const toggle = id => {
    if (open === id) {
      setOpen()
    } else {
      setOpen(id)
    }
  }

  const deleteGroup = useMutation(
    async groupId => {
      return await http.deleteWholeGroup("user/substances/deleteGroupMapping", {
        userId: {
          id: groupId,
        },
      })
    },
    {
      onSuccess: () => {
        dispatch(getAllGroups())
      },
      onError: error => {
        toast.error(error)
      },
    },
  )

  const onDeleteGroup = id => {
    deleteGroup.mutate(id)
  }

  useEffect(() => {
    !allSubstances?.length > 0 && dispatch(getAllSubstances())
    !allConditions?.length > 0 && dispatch(getAllConditions())
    !allContraindications?.length > 0 && dispatch(getAllContraindications())
    !allGroups?.length > 0 && dispatch(getAllGroups())
  }, [])

  return (
    <>
      <div className="mt-4 mb-8 flex flex-col gap-12">
        <Card>
          <CardHeader color="gray" className="mb-8 p-6 bg-[#887bd9]">
            <Typography variant="h6" color="white">
              All Groups
            </Typography>
          </CardHeader>
          <CardBody className="overflow-x-auto px-2 pt-0 pb-2">
            <div className="flex w-full justify-center">
              <div className="flex w-full flex-col gap-4">
                <div>
                  <Card className="shadow-none">
                    <CardBody>
                      <AddGroup />
                      <div>
                        <Accordion flush open={open} toggle={toggle}>
                          {allGroups?.map((group, idx) => {
                            return (
                              <AccordionItem key={idx}>
                                <AccordionHeader targetId={group.id}>
                                  <div className="w-full flex justify-between items-center">
                                    {group.label}
                                    <div className="flex">
                                      <Tooltip content="Delete">
                                        <IconButton
                                          variant="text"
                                          onClick={() => {
                                            onDeleteGroup(group.id)
                                          }}
                                        >
                                          <FaTrash
                                            color="#A02e31"
                                            className="h-4 w-4"
                                          />
                                        </IconButton>
                                      </Tooltip>
                                      <AddSubstance groupId={group.id} />
                                    </div>
                                  </div>
                                </AccordionHeader>
                                <AccordionBody
                                  className="!visible"
                                  accordionId={group.id}
                                >
                                  <Substances
                                    allGroups={allGroups}
                                    groupId={group.id}
                                  />
                                </AccordionBody>
                              </AccordionItem>
                            )
                          })}
                        </Accordion>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  )
}

export default AllGroups
