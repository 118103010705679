import React, { useCallback, useEffect, useState } from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import http from "services/http-common"
import { useMutation } from "react-query"
import { LoadingText } from "pages/Loading/Loading"
import { Pagination, PaginationItem, PaginationLink } from "reactstrap"

const ListOfSubstances = contraId => {
  const [contraDetail, setContraDetail] = useState(null)
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    id: contraId.contraindication,
    cp: 1,
    ps: 5,
  })
  const getContraById = useMutation(
    data => {
      return http.post("user/substances/findSubstanceById", data, null)
    },
    {
      onSuccess: ({ data }) => {
        setContraDetail(data?.records)
        setLoading(false)
      },
      onError: error => {
        console.log(error)
        setLoading(false)
      },
    },
  )

  const getContraBy = useCallback((data = pagination) => {
    getContraById.mutate(data)
  }, [])

  const handlePagination = type => {
    setLoading(true)
    if (type === "prev") {
      if (pagination.cp === 1) return
      setPagination(prevState => ({
        ...prevState,
        cp: pagination.cp - 1,
      }))
      getContraBy({
        id: contraId.contraindication,
        cp: pagination.cp - 1,
        ps: pagination.ps,
      })
    } else {
      setPagination(prevState => ({
        ...prevState,
        cp: pagination.cp + 1,
      }))
      getContraBy({
        id: contraId.contraindication,
        cp: pagination.cp + 1,
        ps: pagination.ps,
      })
    }
  }
  useEffect(() => {
    getContraBy()
  }, [contraId])

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  {contraDetail === null ? (
                    <LoadingText count={3} />
                  ) : contraDetail?.conditions?.length === 0 &&
                    contraDetail?.substances?.length === 0 &&
                    contraDetail?.groups?.length === 0 ? (
                    <div className="flex justify-center items-center gap-2 ">
                      <i className="mdi mdi-progress-close !text-xl "></i>
                      No data found
                    </div>
                  ) : (
                    <Table
                      id="tech-companies-1"
                      className="table table-striped table-bordered"
                    >
                      <Thead>
                        <Tr>
                          <Th>Name</Th>
                          <Th>Url</Th>
                          <Th data-priority="3">Type</Th>
                          <Th data-priority="1">Interaction</Th>
                          <Th data-priority="1">Actions</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {contraDetail?.substances?.map(substance => {
                          return (
                            <Tr>
                              <Th>
                                <a
                                  href={substance.url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {substance.substanceName}
                                </a>
                              </Th>
                              <Td>
                                <a
                                  href={substance.url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {substance.url}
                                </a>
                              </Td>
                              <Td>Substance</Td>
                              <Td>{substance.interaction}</Td>
                              <Td>
                                <div className="flex items-center mx-2 gap-2">
                                  <button
                                    size="sm"
                                    className="btn btn-secondary waves-effect waves-light"
                                    // onClick={() => {
                                    //   onDeleteContraindication(
                                    //     contraindication.id,
                                    //   )
                                    // }}
                                    // disabled={loading}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </Td>
                            </Tr>
                          )
                        })}
                      </Tbody>
                    </Table>
                  )}
                  {contraDetail !== null && (
                    <div className="flex justify-end mx-2">
                      <Pagination>
                        <PaginationItem
                          disabled={loading || pagination.cp === 1}
                        >
                          <PaginationLink
                            tabIndex="-1"
                            onClick={() => handlePagination("prev")}
                          >
                            Previous
                          </PaginationLink>
                        </PaginationItem>

                        <PaginationItem disabled={loading}>
                          <PaginationLink
                            onClick={() => handlePagination("next")}
                          >
                            Next
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </div>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}
export default ListOfSubstances
