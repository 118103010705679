import React, { Component } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import ReactApexChart from "react-apexcharts"

class DocsStatus extends Component {
  constructor(props) {
    super(props)

    this.state = {
      options: {
        colors: ["#ccc", "#7a6fbe", "rgb(40, 187, 227)"],
        chart: {
          toolbar: {
            show: !1,
          },
        },
        dataLabels: {
          enabled: !1,
        },
        stroke: {
          curve: "smooth",
          width: 0.1,
        },
        grid: {
          borderColor: "#f8f8fa",
          row: {
            colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: ["2011", "2012", "2013", "2014", "2015", "2016", "2017"],
          axisBorder: {
            show: !1,
          },
          axisTicks: {
            show: !1,
          },
        },
        legend: {
          show: !1,
        },
      },
      series: [
        {
          name: "Series A",
          data: [0, 150, 60, 180, 90, 75, 30],
        },
        {
          name: "Series B",
          data: [0, 45, 150, 36, 60, 240, 30],
        },
        {
          name: "Series C",
          data: [0, 15, 195, 21, 360, 120, 30],
        },
      ],
    }
  }
  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <Row className="text-center my-3">
              <Col xs="3">
                <h5 className="font-size-20">100</h5>
                <p className="text-muted">Total Documents</p>
              </Col>
              <Col xs="3">
                <h5 className="font-size-20">90</h5>
                <p className="text-muted">Approved</p>
              </Col>
              <Col xs="3">
                <h5 className="font-size-20">5</h5>
                <p className="text-muted">Rejected</p>
              </Col>
              <Col xs="3">
                <h5 className="font-size-20">3</h5>
                <p className="text-muted">Pending</p>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

export default DocsStatus
