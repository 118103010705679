import React, { useState } from "react"
import { Tooltip, IconButton } from "@material-tailwind/react"
import * as messages from "services/messages"
import { useMutation } from "react-query"
import http from "services/http-common"
import { toast } from "react-toastify"
import { FaPlus, FaTrash } from "react-icons/fa"
import { Modal, ModalBody, Row, Col, Input, ModalHeader } from "reactstrap"
import { getAllContraindications } from "store/actions"
import { useDispatch } from "react-redux"

function AddNewSubstance() {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = useState(false)
  const [inputList, setInputList] = useState([{ value: "" }])

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index][name] = value
    setInputList(list)
  }

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { value: "" }])
  }

  const handleOpen = () => {
    setOpen(!open)
  }

  const errorFunc = error => {
    let message
    switch (error.response.status) {
      case 500: {
        message = messages.SERVER_ERROR
        break
      }
      case 404: {
        message = messages.NOT_FOUND_ERROR
        break
      }
      case 400: {
        message = messages.BAD_REQUEST
        break
      }
      default: {
        message = error.message
      }
    }
    toast.error(message)
  }

  const CreateSubstances = useMutation(
    newArr => {
      return http.post(
        `/user/substances/createSubstance`,
        {
          substanceName: newArr,
        },
        null,
      )
    },
    {
      onSuccess: ({ data }) => {
        setInputList([{ value: "" }])
        dispatch(getAllContraindications())
        setLoading(false)
        handleOpen()
      },
      onError: error => {
        errorFunc(error)
        setLoading(false)
      },
      onMutate: () => {
        setLoading(true)
      },
      onSettled: () => {
        setLoading(false)
      },
    },
  )

  const onSubmit = () => {
    setLoading(true)
    if (inputList) {
      let newArr = inputList?.map(obj => obj.value)
      CreateSubstances.mutate(newArr)
    }
  }
  return (
    <>
      <div className="flex justify-end  items-center me-2">
        <Tooltip content="Add Substance">
          <IconButton variant="text" onClick={handleOpen}>
            <FaPlus className="h-4 w-4 text-[#7a6fbe]" />
          </IconButton>
        </Tooltip>
      </div>

      <Modal
        isOpen={open}
        size="md"
        toggle={() => {
          handleOpen()
        }}
        centered={true}
      >
        <ModalHeader toggle={handleOpen} tag="h4">
          Add Substances
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col className="col-12 mb-3">
              <div className="flex flex-col justify-end  items-center gap-2 me-2 mb-3">
                {inputList.map((x, i) => {
                  return (
                    <div className="w-full flex gap-2 ">
                      <Input
                        type="text"
                        name="value"
                        className="w-full !min-h-[15px] form-control"
                        placeholder="Substance"
                        value={x.value}
                        onChange={e => handleInputChange(e, i)}
                      />
                      {inputList.length !== 1 && (
                        <button
                          className="btn !w-auto  waves-effect waves-light"
                          onClick={() => handleRemoveClick(i)}
                        >
                          <FaTrash color="#A02e31" className="h-4 w-4" />
                        </button>
                      )}
                      <div className="btn-toolbar form-group mb-0 flex-col gap-2">
                        {inputList.length - 1 === i &&
                        inputList[i].value.length ? (
                          <button
                            className="btn btn-primary !w-max waves-effect waves-light"
                            onClick={handleAddClick}
                          >
                            <span> Add more</span>
                          </button>
                        ) : null}
                      </div>
                    </div>
                  )
                })}
                <div className="btn-toolbar form-group mt-2">
                  <button
                    className="btn btn-primary !w-max waves-effect waves-light"
                    onClick={onSubmit}
                    disabled={loading}
                  >
                    <span>Submit now</span>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  )
}

export default AddNewSubstance
