import React, { useState } from "react"

import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import CreatableSelect from "react-select/creatable"
import TimezoneSelect from "react-timezone-select"

import "./style.css"
import http from "services/http-common"
import { useMutation } from "react-query"
import toast from "react-hot-toast"

const AddEvent = ({ getEvents }) => {
  document.title = "Calendar | MushPro by the Entheology Project"
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  )

  const [modalAddEvent, setModalAddEvent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [event, setEvent] = useState({
    seekerEmails: [""],
    startDate: "",
    endDate: "",
    title: "",
    description: "",
    location: "",
    meetingLink: "",
    timezone: "Etc/GMT",
  })

  const addEvent = useMutation(
    () => {
      return http.post("user/scheduling/create-event", event, null)
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message)
        setLoading(false)
        setEvent({
          seekerEmails: [""],
          startDate: "",
          endDate: "",
          title: "",
          description: "",
          location: "",
          meetingLink: "",
          timezone: "Etc/GMT",
        })
        getEvents.mutate()

        toggleCategory()
      },
      onError: error => {
        toast.error(error.response.data.message)
        setLoading(false)
        toggleCategory()
      },
    },
  )
  function handleSeekerMail(values) {
    let newArr = values?.map(obj => obj.label)
    setEvent({ ...event, seekerEmails: newArr })
  }
  const onChangeContent = e => {
    setEvent({ ...event, [e.target.name]: e.target.value })
  }
  const onChangeTimezone = e => {
    setEvent({ ...event, timezone: e.value })
  }
  const toggleCategory = () => {
    setModalAddEvent(!modalAddEvent)
  }
  const onSubmit = () => {
    setLoading(true)
    addEvent.mutate()
  }

  return (
    <React.Fragment>
      <Button
        color="primary"
        className="btn-block !bg-[#7a6fbe]"
        onClick={toggleCategory}
      >
        <i className="mdi mdi-plus-circle-outline" /> Add Events Appointments
      </Button>
      <Modal isOpen={modalAddEvent} toggle={toggleCategory}>
        <ModalHeader toggle={toggleCategory} tag="h4">
          Events Appointments
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={onSubmit}>
            <Row form>
              <Col className="col-12 mb-3">
                <Label>Title</Label>
                <Input
                  name="title"
                  label="Title"
                  type="text"
                  onChange={e => onChangeContent(e)}
                  errorMessage="Invalid Title"
                  validate={{
                    required: { value: true },
                  }}
                  value={event.title}
                />
              </Col>
              <Col className="col-12 mb-3">
                <Label>Description</Label>

                <Input
                  name="description"
                  label="Description"
                  type="textarea"
                  size="2"
                  onChange={e => onChangeContent(e)}
                  errorMessage="Invalid description"
                  validate={{
                    required: { value: true },
                  }}
                  value={event.description}
                />
              </Col>
              <Col className="col-12 mb-3">
                <Label>Seeker Mail </Label>
                <CreatableSelect
                  isMulti={true}
                  onChange={e => {
                    handleSeekerMail(e)
                  }}
                  options={[]}
                  classNamePrefix="select2-selection"
                />
              </Col>
              <Col className="col-12 mb-3">
                <Label>Meeting Platform</Label>
                <Input
                  name="location"
                  label="Meeting Platform"
                  type="text"
                  onChange={e => onChangeContent(e)}
                  errorMessage="Invalid Location"
                  value={event.location}
                />
              </Col>
              <Col className="col-12 mb-3">
                <Label>Meeting Link</Label>
                <Input
                  name="meetingLink"
                  label="Meeting Link"
                  type="text"
                  onChange={e => onChangeContent(e)}
                  errorMessage="Invalid meetingLink"
                  value={event.meetingLink}
                />
              </Col>
              <Col className="col-12 mb-3 ">
                <Label>Timezone</Label>
                <div className="select-wrapper">
                  <TimezoneSelect
                    value={event.timezone}
                    onChange={e => onChangeTimezone(e)}
                  />
                </div>
              </Col>
              <Col className="col-12 mb-3 ">
                <Label>Start - End</Label>
                <div className="flex justify-start items-center gap-2">
                  <Input
                    name="startDate"
                    className="form-control w-auto"
                    type="datetime-local"
                    onChange={e => onChangeContent(e)}
                    defaultValue={event.startDate}
                    id="example-time-input"
                  />{" "}
                  {" - "}
                  <Input
                    name="endDate"
                    className="form-control w-auto"
                    type="datetime-local"
                    onChange={e => onChangeContent(e)}
                    defaultValue={event.endDate}
                    id="example-time-input"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <div className="text-right">
                  <button
                    type="button"
                    className="btn btn-light me-2 bg-[#f8f9fa]"
                    onClick={toggleCategory}
                  >
                    Close
                  </button>
                  <button
                    disabled={loading}
                    type="submit"
                    className="btn btn-success save-event bg-[#7a6fbe] hover:bg-[#7a6fbe] border-none outline-none"
                  >
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default AddEvent
