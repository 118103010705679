import React, { useState } from "react"
import { Button } from "@material-tailwind/react"
import { Input } from "reactstrap"
import { useMutation } from "react-query"
import http from "services/http-common"
import { CircularProgress } from "@mui/material"
import toast from "react-hot-toast"

function InviteSeeker({ getReferrals }) {
  const [isLoading, setIsLoading] = React.useState(false)
  const [referralEmail, setReferralEmail] = useState("")

  const inviteNow = useMutation(
    () => {
      return http.post("user/referral/generate", {
        referralEmail: referralEmail,
      })
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message)
        setReferralEmail("")
        setIsLoading(false)
        getReferrals.mutate()
      },
      onError: error => {
        toast.error(error.response.data.message)
        setIsLoading(false)
      },
    },
  )

  const onSubmit = () => {
    setIsLoading(true)
    inviteNow.mutate()
  }

  return (
    <>
      <div className="flex items-center gap-2 my-3">
        <Input
          className="w-full "
          name="referralEmail"
          placeholder="Email"
          type="email"
          rows="1"
          value={referralEmail}
          onChange={e => {
            setReferralEmail(e.target.value)
          }}
        />
        <Button
          className="flex items-center gap-3 sm:w-auto w-full text-center justify-center btn !bg-[#7a6fbe]"
          size="sm"
          disabled={isLoading}
          onClick={onSubmit}
        >
          {isLoading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <>Invite</>
          )}
        </Button>
      </div>
    </>
  )
}

export default InviteSeeker
