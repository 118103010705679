/* CHATS */
export const GET_BOT_DETAILS = "GET_BOT_DETAILS"
export const GET_BOT_DETAILS_SUCCESS = "GET_BOT_DETAILS_SUCCESS"
export const GET_CHATS_SUCCESS = "GET_CHATS_SUCCESS"
export const GET_CHATS_FAIL = "GET_CHATS_FAIL"

/* MESSAGES */
export const GET_MESSAGES = "GET_MESSAGES"
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS"
export const GET_MESSAGES_FAIL = "GET_MESSAGES_FAIL"
export const POST_ADD_MESSAGE = "POST_ADD_MESSAGE"
export const POST_ADD_MESSAGE_SUCCESS = "POST_ADD_MESSAGE_SUCCESS"
export const POST_ADD_MESSAGE_FAIL = "POST_ADD_MESSAGE_FAIL"
export const DELETE_MESSAGE = "DELETE_MESSAGE"
export const DELETE_MESSAGE_SUCCESS = "DELETE_MESSAGE_SUCCESS"
export const DELETE_MESSAGE_FAIL = "DELETE_MESSAGE_FAIL"

// UPLOADER
export const UPLOAD_IMAGE_REQUEST = "UPLOAD_IMAGE_REQUEST"
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS"
