import React from "react";
import PropTypes from "prop-types";

export const MainContext = React.createContext(null);
MainContext.displayName = "MainContextContext";

export function reducer(state, action) {
  switch (action.type) {
    case "OPEN_SIDENAV": {
      return { ...state, openSidenav: action.value };
    }
    case "SIDENAV_TYPE": {
      return { ...state, sidenavType: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "SET_USERINFO": {
      return { ...state, userinfo: action.value };
    }
    case "SET_SESSIONEXPIRED": {
      return { ...state, sessionExpired: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export function MainControllerProvider({ children }) {
  const initialState = {
    openSidenav: false,
    sidenavColor: "blue",
    sidenavType: "dark",
    transparentNavbar: true,
    fixedNavbar: false,
    openConfigurator: false,
    userinfo: null,
    sessionExpired: false,
  };

  const [controller, dispatch] = React.useReducer(reducer, initialState);
  const value = React.useMemo(
    () => [controller, dispatch],
    [controller, dispatch]
  );

  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
}

export function useMainController() {
  const context = React.useContext(MainContext);

  if (!context) {
    throw new Error(
      "useMainController should be used inside the MainControllerProvider."
    );
  }

  return context;
}

MainControllerProvider.displayName = "/src/context/index.jsx";

MainControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const setSessionExpired = (dispatch, value) => {
  dispatch({ type: "SET_SESSIONEXPIRED", value });
};
