import React, { useEffect, useState } from "react"
import { Button } from "@material-tailwind/react"
import ReactQuill from "react-quill"
import parse from "html-react-parser"
import { useMutation } from "react-query"
import { httpForMushGpt } from "../../services/http-common"
import { toast } from "react-toastify"
import { FaRegEdit } from "react-icons/fa"
import { IconButton, Tooltip, useTheme } from "@mui/material"
import { useSelector } from "react-redux"
import { Container } from "reactstrap"
import { Col, Modal, ModalBody, Row } from "reactstrap"
import UserService from "KeyCloackServices/UserService"

function EditChatFooter() {
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const [content, setContent] = useState("")
  const [loading, setLoading] = useState(false)

  const getFooterContent = useMutation(
    () => {
      return httpForMushGpt.get("user/footer-note")
    },
    {
      onSuccess: ({ data }) => {
        setContent(data?.footerNote)
      },
      onError: error => {
        toast.error(error)
      },
    },
  )

  useEffect(() => {
    getFooterContent.mutate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpen = () => {
    setOpen(!open)
  }

  const saveFooter = useMutation(
    async () => {
      return await httpForMushGpt.put("user/footer-note", { content: content })
    },
    {
      onSuccess: () => {
        toast.success("Successfully Saved!")
        setLoading(false)
        handleOpen()
      },
      onError: error => {
        setLoading(false)
        toast.error(error?.response?.data?.message)
      },
    },
  )

  const onChangePrompt = html => {
    setContent(html)
  }

  const onUpdateFooter = () => {
    setLoading(true)
    saveFooter.mutate()
  }

  return (
    <>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col sm={12}>
              <span className="d-sm-inline-block">{parse(content)}</span>
              {UserService.getRole()?.roles?.includes("admin") && (
                <Tooltip content="Edit">
                  <IconButton variant="text" onClick={handleOpen}>
                    <FaRegEdit
                      color={theme.palette.mode === "dark" && "white"}
                      className="h-5 w-5"
                    />
                  </IconButton>
                </Tooltip>
              )}
            </Col>
          </Row>
        </Container>
      </footer>
      <Modal isOpen={open} toggle={handleOpen} centered={true}>
        <ModalBody className="p-3">
          <Row>
            <Col lg={12}>
              <div
                className={`w-full flex justify-center gap-2 items-center ${
                  theme.palette.mode === "dark" && "text-[#e3e3e3]"
                } `}
              >
                Update Chat Footer
              </div>
              <div className="flex w-full justify-center">
                <div className="mt-5 flex w-full max-w-[800px] flex-col gap-8">
                  <ReactQuill
                    theme="snow"
                    className={`mb-5 w-auto max-h-[300px] ${
                      theme.palette.mode === "dark" && "!text-white"
                    } `}
                    value={content}
                    onChange={onChangePrompt}
                  />
                </div>
              </div>
              <div className="text-center mt-4">
                <Button
                  size="sm"
                  variant="text"
                  color="red"
                  onClick={handleOpen}
                  className="mr-1"
                >
                  <span>Close</span>
                </Button>
                <Button
                  size="sm"
                  className="btn btn-primary !bg-[#7a6fbe]"
                  onClick={onUpdateFooter}
                  disabled={loading}
                  color={theme.palette.mode === "dark" ? "white" : "green"}
                >
                  <span>Update</span>
                </Button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      {/* <Dialog
        open={open}
        handler={handleOpen}
        className={`h-140 ${theme.palette.mode === "dark" && "!bg-[#282d3d]"}`}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogBody
          divider
          className={`flex-col max-h-full  ${
            theme.palette.mode === "dark" && "!bg-[#282d3d] border-y-[#363b4e] "
          }`}
        >
          <div
            className={`w-full flex justify-center gap-2 items-center ${
              theme.palette.mode === "dark" && "text-[#e3e3e3]"
            } `}
          >
            Update Chat Footer
          </div>
          <div className="flex w-full justify-center">
            <div className="mt-5 flex w-full max-w-[800px] flex-col gap-8">
              <ReactQuill
                theme="snow"
                className={`mb-3 max-h-[300px] ${
                  theme.palette.mode === "dark" && "!text-white"
                } `}
                value={content}
                onChange={onChangePrompt}
              />
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Close</span>
          </Button>
          <Button
            variant="gradient"
            onClick={onUpdateFooter}
            disabled={loading}
            color={theme.palette.mode === "dark" ? "white" : "green"}
          >
            <span>Update</span>
          </Button>
        </DialogFooter>
      </Dialog> */}
    </>
  )
}

export default EditChatFooter
