import React, { useState } from "react"
import { Button, Input, Label, Modal } from "reactstrap"
import { useMutation } from "react-query"
import http from "../../../services/http-common"
import { useSelector } from "react-redux"
import toast from "react-hot-toast"

function Appointment() {
  const { user } = useSelector(state => state.Login)
  const { botProfile } = useSelector(state => state.bot)
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = useState(false)
  const [content, setContent] = useState({
    seekerId: user?.kcId,
    proId: botProfile?.kcId,
    startDate: "",
    endDate: "",
    title: "",
    description: "",
    location: "",
    meetingLink: "",
  })

  const fixAppointment = useMutation(
    () => {
      return http.post(`user/scheduling/appointment`, content)
    },
    {
      onSuccess: ({ data }) => {
        toast.success("Appointment Create Successfully!")
        handleOpen()
        setContent({
          seekerId: user?.kcId,
          proId: botProfile?.kcId,
          startDate: "",
          endDate: "",
          title: "",
          description: "",
          location: "",
          meetingLink: "",
        })
      },
      onError: error => {
        toast.error(error.response.data.message)
      },
      onMutate: () => {
        setLoading(true)
      },
      onSettled: () => {
        setLoading(false)
      },
    },
  )

  const handleOpen = () => {
    setOpen(!open)
  }

  const onChangeContent = e => {
    if (loading) return false
    setContent({ ...content, [e.target.name]: e.target.value })
  }

  const onSubmit = () => {
    fixAppointment.mutate()
  }

  return (
    <>
      <Button
        variant="text"
        className="!bg-[#7a6fbe] text-white !btn !btn-primary !w-md mb-1"
        onClick={handleOpen}
      >
        Schedule Meeting
      </Button>
      <Modal
        isOpen={open}
        toggle={() => {
          handleOpen()
        }}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Schedule Meeting with Coach</h5>
          <button
            type="button"
            onClick={() => {
              handleOpen(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form className="mt-2 mb-2  w-auto  max-w-screen-md sm:w-full ">
            <div className="mb-1 flex flex-col gap-6 w-auto ">
              <div className="sm:w-auto md:w-full">
                <Label>Title</Label>
                <Input
                  name="title"
                  label="Title"
                  type="text"
                  onChange={e => onChangeContent(e)}
                  errorMessage="Invalid Title"
                  validate={{
                    required: { value: true },
                  }}
                  value={content.title}
                />
              </div>
              <div className="sm:w-auto md:w-full">
                <Label>Description</Label>
                <Input
                  name="description"
                  label="Description"
                  type="textarea"
                  size="2"
                  onChange={e => onChangeContent(e)}
                  errorMessage="Invalid description"
                  validate={{
                    required: { value: true },
                  }}
                  value={content.description}
                />
              </div>
              <div className="sm:w-auto md:w-full">
                <Label>Meeting Platform</Label>
                <Input
                  name="location"
                  label="Meeting Platform"
                  type="text"
                  onChange={e => onChangeContent(e)}
                  errorMessage="Invalid Location"
                  value={content.location}
                />
              </div>
              <div className="sm:w-auto md:w-full">
                <Label>Meeting Link</Label>
                <Input
                  name="meetingLink"
                  label="Meeting Link"
                  type="text"
                  onChange={e => onChangeContent(e)}
                  errorMessage="Invalid meetingLink"
                  value={content.meetingLink}
                />
              </div>
              <div className="sm:w-auto md:w-full">
                <Label>Start Date</Label>
                <input
                  name="startDate"
                  value={content.startDate}
                  className="form-control w-full"
                  type="datetime-local"
                  onChange={e => {
                    onChangeContent(e)
                  }}
                />
              </div>
              <div className="sm:w-auto md:w-full">
                <Label>End Date</Label>
                <input
                  name="endDate"
                  value={content.endDate}
                  className="form-control w-full"
                  type="datetime-local"
                  onChange={e => {
                    onChangeContent(e)
                  }}
                />
              </div>
            </div>
          </form>
          <div className="flex justify-end items-center">
            <Button
              size="sm"
              variant="tex"
              onClick={handleOpen}
              className="mr-1 bg-gray-700"
              disabled={loading}
            >
              <span>Close</span>
            </Button>
            <Button
              size="sm"
              className="bg-[#7a6fbe]"
              onClick={onSubmit}
              disabled={loading}
            >
              <span>Request Appointment</span>
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Appointment
