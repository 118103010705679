import UserService from "KeyCloackServices/UserService"

export const getToken = () => {
  try {
    // const token = localStorage.getItem("accessToken");
    const token = `Bearer ${UserService.getToken()}`
    return token
  } catch (error) {
    return
  }
}

export const getJwtToken = () => {
  try {
    const token = localStorage.getItem("botAccessTokenEncoded")
    return token
  } catch (error) {
    return
  }
}

export function setToken(token) {
  localStorage.setItem("accessToken", token)
}

export function removeToken() {
  localStorage.removeItem("accessToken")
}

export default setToken
