import React, { useEffect, useState } from "react"
import { Button, Tooltip, IconButton } from "@material-tailwind/react"
import { Input, Label, Modal } from "reactstrap"
import * as messages from "services/messages"
import { toast } from "react-hot-toast"
import { FaRegEdit } from "react-icons/fa"
import { httpForMushGpt } from "services/http-common"
import { useMutation } from "react-query"

function EditLLM({ llm, getLLMs }) {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = useState(false)
  const [content, setContent] = useState({
    id: llm?.id,
    isEnabled: llm?.isEnabled,
    name: llm?.name,
  })

  const errorFunc = error => {
    let message
    switch (error.response.status) {
      case 500: {
        message = messages.SERVER_ERROR
        break
      }
      case 404: {
        message = messages.NOT_FOUND_ERROR
        break
      }
      case 400: {
        message = messages.BAD_REQUEST
        break
      }
      default: {
        message = error.message
      }
    }
    toast.error(message)
  }

  const handleOpen = () => {
    setOpen(!open)
  }

  const onChangeContent = e => {
    if (loading) return false
    setContent({ ...content, [e.target.name]: e.target.value })
  }

  const onSubmit = () => {
    updateContent.mutate()
  }

  const updateContent = useMutation(
    () => {
      return httpForMushGpt.put(`llms/update`, content)
    },
    {
      onSuccess: () => {
        toast.success("Successfully Updated!")
        getLLMs()
        setContent({
          id: llm?.id,
          isEnabled: llm?.isEnabled,
          name: llm?.name,
        })
        handleOpen()
      },
      onError: error => {
        errorFunc(error)
      },
      onMutate: () => {
        setLoading(true)
      },
      onSettled: () => {
        setLoading(false)
      },
    },
  )

  useEffect(() => {
    llm && setContent(llm)
  }, [llm])
  return (
    <>
      <Tooltip content="Edit">
        <IconButton variant="text" onClick={handleOpen}>
          <FaRegEdit className="h-5 w-5 text-[#7a6fbe]" />
        </IconButton>
      </Tooltip>
      <Modal
        isOpen={open}
        toggle={() => {
          handleOpen()
        }}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Add LLM</h5>
          <button
            type="button"
            onClick={() => {
              handleOpen(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form className="mt-2 mb-2  w-auto  max-w-screen-md sm:w-full ">
            <div className="mb-3 position-relative">
              <Label htmlFor="name">Name</Label>
              <div className="input-group">
                <Input
                  type="text"
                  name="name"
                  className="form-control"
                  id="name"
                  value={content?.name}
                  onChange={onChangeContent}
                />
              </div>
            </div>
            <div className="flex justify-between items-center mb-4">
              <h5 className="font-size-14 ">Enable</h5>
              <div className="flex justify-start items-center gap-2">
                <div className="form-check  ">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="isEnabled"
                    id="Yes"
                    onClick={() => {
                      setContent({ ...content, isEnabled: true })
                    }}
                    value="yes"
                    checked={content.isEnabled}
                  />
                  <label className="form-check-label" htmlFor="Yes">
                    Yes
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="isEnabled"
                    id="false"
                    value="No"
                    onClick={() => {
                      setContent({ ...content, isEnabled: false })
                    }}
                    checked={content.isEnabled === false}
                  />
                  <label className="form-check-label" htmlFor="false">
                    No
                  </label>
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center mb-4">
              <h5 className="font-size-14 ">Status</h5>
              <div className="flex justify-start items-center gap-2">
                <div className="form-check  ">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="status"
                    id="private"
                    onClick={() => {
                      setContent({ ...content, status: "private" })
                    }}
                    value="Private"
                    checked={content.status === "private"}
                  />
                  <label className="form-check-label" htmlFor="private">
                    Private
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="status"
                    id="public"
                    value="Public"
                    onClick={() => {
                      setContent({ ...content, status: "public" })
                    }}
                    checked={content.status === "public"}
                  />
                  <label className="form-check-label" htmlFor="public">
                    Public
                  </label>
                </div>
              </div>
            </div>
          </form>
          <div className="flex justify-end items-center">
            <Button
              size="sm"
              variant="tex"
              onClick={handleOpen}
              className="mr-1 bg-gray-700"
              disabled={loading}
            >
              <span>Close</span>
            </Button>
            <Button
              size="sm"
              className="bg-[#7a6fbe]"
              onClick={onSubmit}
              disabled={loading}
            >
              <span>Update</span>
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default EditLLM
