import { useRef, useEffect, useState, useCallback } from "react"
import ContentEditable from "react-contenteditable"
import { useDispatch } from "react-redux"
import { FiRefreshCcw } from "react-icons/fi"
import { Button } from "reactstrap"
// ** Import MUI
import { IconButton, Box } from "@mui/material"

// ** Import action
import { getAnswer, deleteChatHistory } from "../../store/messages/actions"

document.title = "Magic Myc | MushPro by The Entheology Project"
const ContentEdit = () => {
  const dispatch = useDispatch()
  const [text, setText] = useState("")
  const inputBoxRef = useRef(null)

  const deleteHandler = () => {
    dispatch(deleteChatHistory())
  }

  const handleChange = evt => {
    const value = evt.target.value.replace(/<[^>]+>/g, "")
    setText(value)
  }

  const onSubmit = useCallback(() => {
    if (text !== "") {
      dispatch(
        getAnswer({
          finalChat: text,
          llm: localStorage.getItem("model")?.toLocaleLowerCase(),
        }),
      )
      setText("")
    }
  }, [text, dispatch])

  useEffect(() => {
    const handleKeyDown = e => {
      if (e.key === "Enter") {
        e.preventDefault()
        if (e.ctrlKey || e.shiftKey) {
          document.execCommand("insertLineBreak")
        } else {
          onSubmit()
        }
      }
    }
    window.addEventListener("keydown", handleKeyDown)
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [onSubmit])

  return (
    <>
      <div className="flex items-center relative">
        <IconButton
          className="bg-white "
          aria-label="Refresh"
          sx={{ border: "2px solid #A2A5C6", mr: "20px" }}
          onClick={deleteHandler}
        >
          <FiRefreshCcw className="text-xl" />
        </IconButton>
        <div className="bg-[#f2f2f7] w-full me-2 flex-1">
          <Box className=" flex items-center ">
            <ContentEditable
              className="
              !max-h-[40px] text-base overflow-y-hidden py-1 px-2 border-[2px solid rgb(162, 165, 198)] rounded-sm w-full  "
              data-text="Send a message"
              innerRef={inputBoxRef}
              html={text}
              onChange={handleChange}
            />
          </Box>
        </div>
        <div className="col-auto">
          <Button
            type="button"
            color="primary"
            disabled={text === "" ? true : false}
            onClick={onSubmit}
            className="chat-send w-md waves-effect waves-ligh !bg-[#7a6fbe]"
          >
            <span className="d-none d-sm-inline-block me-2">Send</span>{" "}
            <i className="mdi mdi-send float-end"></i>
          </Button>
        </div>
      </div>
    </>
  )
}

export default ContentEdit
