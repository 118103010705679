export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST"
export const UPDATE_PROFILE = "UPDATE_PROFILE_REQUEST"
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS"
export const EDIT_PROFILE = "EDIT_PROFILE"
export const POST_Delete_Profile_REQUEST = "POST_Delete_Profile_REQUEST"
export const POST_Delete_Profile_SUCCESS = "POST_Delete_Profile_SUCCESS"
export const PROFILE_ERROR = "PROFILE_ERROR"
export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG"

export const GET_ALL_TITLES = "GET_ALL_TITLES"
export const GET_ALL_TITLES_SUCCESS = "GET_ALL_TITLES_SUCCESS"

export const GET_ALL_EDUCATIONS = "GET_ALL_EDUCATIONS"
export const GET_ALL_EDUCATIONS_SUCCESS = "GET_ALL_EDUCATIONS_SUCCESS"

export const GET_ALL_EXPERTISES = "GET_ALL_EXPERTISES"
export const GET_ALL_EXPERTISES_SUCCESS = "GET_ALL_EXPERTISES_SUCCESS"

export const GET_ALL_TRAININGS = "GET_ALL_TRAININGS"
export const GET_ALL_TRAININGS_SUCCESS = "GET_ALL_TRAININGS_SUCCESS"

export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST"

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS"
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS"
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS"
