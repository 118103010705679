import { IconButton, Tooltip } from "@mui/material"
import { setSessionExpired, useMainController } from "context"
import React, { useEffect, useState } from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import toast from "react-hot-toast"
import { FaRegCopy } from "react-icons/fa"
import { useMutation } from "react-query"
import { Card, CardBody, CardTitle, Label } from "reactstrap"
import http from "services/http-common"

export default function WebsiteEmbedding({ websiteId }) {
  const [dispatch] = useMainController()
  const [websiteEmbeddings, setWebsiteEmbeddings] = useState(null)
  const [clipboardText, setClipboardText] = useState("Click to copy")

  const HandleTooltipClose = () => {
    setTimeout(() => {
      setClipboardText("Click to copy")
    }, 200)
  }

  const getAllWebsites = useMutation(
    () => {
      return http.post(
        `embedding/findById`,
        {
          id: websiteId,
        },
        null,
        setSessionExpired,
        dispatch,
      )
    },
    {
      onSuccess: ({ data }) => {
        setWebsiteEmbeddings(data?.embedding)
      },
      onError: error => {
        toast.error(error?.response?.data?.message)
      },
    },
  )

  useEffect(() => {
    websiteId && getAllWebsites.mutate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websiteId])
  return (
    <>
      <div className="w-100 user-chat mt-4 mt-sm-0 ms-lg-3">
        <Card>
          <CardBody>
            <CardTitle className="h4">
              Installation guides below (HTML/CSS or WordPress)
            </CardTitle>
            <div className="mt-3">
              <Label>
                1. Paste this code snippet just before the {`</head>`} tag.
              </Label>
            </div>
            <div className={"relative  mb-3"} id="scroll-dialog-description">
              <CopyToClipboard
                className="absolute top-0 right-0"
                text={`<link rel="stylesheet" href="https://mushgptawsbucket.s3.us-west-2.amazonaws.com/assets/static/mushGpt/chatbot.css" /> \n <script src="https://mushgptawsbucket.s3.us-west-2.amazonaws.com/assets/static/mushGpt/script.js"></script>`}
                onCopy={() => {
                  // alert("copied");
                  setClipboardText("Copied")
                }}
              >
                <div
                  className="clipboard-copy"
                  sx={{
                    // position: { xs: 'inherit', md: 'absolute' },
                    // marginLeft: { xs: 'calc(100% - 98px)', md: '0px' },
                    position: "absolute",
                    top: 0,
                    right: 0,
                    // marginLeft: 0,
                  }}
                >
                  <Tooltip
                    title={clipboardText}
                    placement="top"
                    onClose={HandleTooltipClose}
                  >
                    <IconButton aria-label="copy">
                      <FaRegCopy className="text-lg" />
                    </IconButton>
                  </Tooltip>
                </div>
              </CopyToClipboard>
              <pre
                className={
                  "text-sm text-balance bg-[#f5f5f5] p-2 overflow-auto"
                }
              >
                {`<link rel="stylesheet" href="https://mushgptawsbucket.s3.us-west-2.amazonaws.com/assets/static/mushGpt/chatbot.css" /> \n <script src="https://mushgptawsbucket.s3.us-west-2.amazonaws.com/assets/static/mushGpt/script.js"></script>`}
              </pre>
            </div>
            <div className="mt-3">
              <Label>
                2. Paste this code snippet just in the {`<body>`} tag.
              </Label>
            </div>
            <div className={"relative  mb-3"} id="scroll-dialog-description">
              <CopyToClipboard
                className="absolute top-0 right-0"
                text={websiteEmbeddings}
                onCopy={() => {
                  // alert("copied");
                  setClipboardText("Copied")
                }}
              >
                <div
                  className="clipboard-copy"
                  sx={{
                    // position: { xs: 'inherit', md: 'absolute' },
                    // marginLeft: { xs: 'calc(100% - 98px)', md: '0px' },
                    position: "absolute",
                    top: 0,
                    right: 0,
                    // marginLeft: 0,
                  }}
                >
                  <Tooltip
                    title={clipboardText}
                    placement="top"
                    onClose={HandleTooltipClose}
                  >
                    <IconButton aria-label="copy">
                      <FaRegCopy className="text-lg" />
                    </IconButton>
                  </Tooltip>
                </div>
              </CopyToClipboard>
              <pre
                className={
                  "text-sm text-balance bg-[#f5f5f5] p-2 overflow-auto"
                }
              >
                {websiteEmbeddings}
              </pre>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  )
}
