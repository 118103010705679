import React, { useState } from "react"
import { Modal } from "reactstrap"
import * as messages from "services/messages"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { Input } from "reactstrap"
import { useDispatch } from "react-redux"
import http from "services/http-common"
import { getAllContraindications } from "store/actions"

function EditSubstance({ substanceName, substanceId }) {
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = useState(false)
  const [content, setContent] = useState({
    id: substanceId,
    substanceName: substanceName || "",
    url: "",
  })

  const handleOpen = () => {
    setOpen(!open)
  }

  const errorFunc = error => {
    let message
    switch (error.response.status) {
      case 500: {
        message = messages.SERVER_ERROR
        break
      }
      case 404: {
        message = messages.NOT_FOUND_ERROR
        break
      }
      case 400: {
        message = messages.BAD_REQUEST
        break
      }
      default: {
        message = error.message
      }
    }
    toast.error(message)
  }

  const onSubmit = () => {
    setLoading(true)
    UpdateSubstance.mutate()
  }
  const UpdateSubstance = useMutation(
    () => {
      return http.put(`user/substances/updateSubstance`, content, null)
    },
    {
      onSuccess: ({ data }) => {
        setOpen(!open)
        dispatch(getAllContraindications())
        setLoading(false)
      },
      onError: error => {
        errorFunc(error)
        setLoading(false)
      },
      onMutate: () => {
        setLoading(true)
      },
      onSettled: () => {
        setLoading(false)
      },
    },
  )
  const onChangeContent = e => {
    if (loading) return false
    setContent({
      ...content,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <>
      <button
        size="sm"
        className="btn btn-secondary waves-effect waves-light"
        onClick={handleOpen}
      >
        Edit
      </button>

      <Modal
        isOpen={open}
        size="md"
        toggle={() => {
          handleOpen()
        }}
        centered={true}
      >
        <div className="modal-header ">
          <h5 className="modal-title font-bold">Update Substance</h5>
        </div>

        <div className="modal-body w-full flex-col justify-start ">
          <div className="flex-col justify-end items-start gap-2 me-2 ">
            <div className="w-full gap-1 mb-2">
              <label htmlFor="substanceName">Name</label>
              <Input
                type="text"
                name="substanceName"
                id="substanceName"
                className="w-full form-control"
                placeholder="Substance Name"
                value={content?.substanceName}
                onChange={onChangeContent}
              />
            </div>
            <div className="w-full gap-1">
              <label htmlFor="url">Url</label>
              <Input
                type="url"
                name="url"
                className="w-full form-control"
                placeholder="Add url"
                value={content?.url}
                onChange={onChangeContent}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            className="btn btn-primary waves-effect waves-light"
            onClick={onSubmit}
            disabled={loading}
          >
            <span>Save Changings</span>
          </button>
          <button
            type="button"
            onClick={() => {
              handleOpen()
            }}
            className="btn !bg-[#6c757d] !text-white waves-effect"
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  )
}

export default EditSubstance
