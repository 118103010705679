import React from "react"
import { MdOutlineDashboardCustomize } from "react-icons/md"
import { TbForms } from "react-icons/tb"
import { RiStore3Line } from "react-icons/ri"
import { BiConversation } from "react-icons/bi"
import { TbWorldSearch } from "react-icons/tb"
import { AiOutlineFileSearch } from "react-icons/ai"
import { ImEmbed2 } from "react-icons/im"
import { HiMiniUserGroup } from "react-icons/hi2"
import { AiOutlineSchedule } from "react-icons/ai"
import { TbMessageReport } from "react-icons/tb"

const features = [
  {
    title: "Customizable Personality",
    desc: "Tailor your chatbot to reflect your coaching style and personality, creating a more engaging and personalized client experience. This ensures that your clients feel connected and understood, leading to better outcomes and higher satisfaction rates.",
    icon: <MdOutlineDashboardCustomize className="text-3xl" />,
  },
  {
    title: "PsiloScreen Intake & Risk Assessment",
    desc: "Streamline the intake process with automated risk assessments. Quickly gather and analyze client information, allowing you to focus more on developing personalized coaching strategies. This feature ensures you start each coaching relationship with a comprehensive understanding of your client's needs and risks.",
    icon: <TbForms className="text-3xl" />,
  },
  {
    title: "EntheoGPT Store",
    desc: "Enhance your service offerings by providing clients with access to a wide range of entheogenic resources. This added value can set you apart from other coaches and establish you as a trusted source of knowledge.",
    icon: <RiStore3Line className="text-3xl" />,
  },
  {
    title: "Conversation Starters    ",
    desc: "Keep your clients engaged with pre-defined conversation prompts. These starters can help initiate meaningful discussions, keep the conversation flowing, and ensure that you cover all necessary topics during sessions.",
    icon: <BiConversation className="text-3xl" />,
  },
  {
    title: "Website Scraping",
    desc: "Automatically pull relevant data from trusted sources to keep your chatbot informed and up-to-date. This ensures that your clients receive accurate and current information without requiring you to manually update the chatbot's knowledge base.",
    icon: <TbWorldSearch className="text-3xl" />,
  },
  {
    title: "Document Training",
    desc: "Train your chatbot using your own documents and resources. This customization allows your chatbot to provide answers and advice that are specifically aligned with your coaching philosophy and methods.",
    icon: <AiOutlineFileSearch className="text-3xl" />,
  },
  {
    title: "Embedding",
    desc: "Embed the chatbot on your website to provide instant support and answers to potential clients. This accessibility can improve client acquisition and retention by providing immediate value to visitors.",
    icon: <ImEmbed2 className="text-3xl" />,
  },
  {
    title: "Confidential Seeker to Coach Matching",
    desc: "Enhance client-coach compatibility with automated matching based on detailed profiles and preferences. This feature ensures that clients are paired with the coach best suited to their needs, leading to more effective and satisfying coaching relationships.",
    icon: <HiMiniUserGroup className="text-3xl" />,
  },
  {
    title: "AI Appointment Tracking & Scheduling",
    desc: "Automate your appointment management with AI-powered scheduling. This feature reduces administrative tasks, allowing you to focus more on coaching. Clients can easily book, reschedule, and receive reminders, ensuring a smooth and organized coaching process.",
    icon: <AiOutlineSchedule className="text-3xl" />,
  },
  {
    title: "Push Messaging",
    desc: "Send personalized push messages to clients through the chatbot interface. These messages are filtered to protect privacy and limited to prevent spam, ensuring effective communication without overwhelming clients.",
    icon: <TbMessageReport className="text-3xl" />,
  },
]

function Features() {
  return (
    <>
      <div className="mb-10">
        <div className="max-w-5xl mt-20 w-full mx-auto flex justify-center relative">
          <div className="absolute top-[30%] transform  -translate-y-1/2 mix-blend-multiply filter blur-3xl w-[80%] h-full bg-blue-600 dark:bg-blue-800 opacity-10  rounded-full"></div>
          <div>
            <div className="text-center max-w-3xl">
              <div className="text-4xl font-bold">
                <div>
                  Boost Productivity and Success with Comprehensive{" "}
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
                    Solutions
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-primary-300 mt-2 text-center md:text-lg font-light tracking-wider">
          Entheo.Pro's chatbot services are designed to enhance your coaching
          practice by automating routine tasks and providing valuable insights.
          Here's how each feature can help you become a more productive and
          successful coach.
        </div>
      </div>
      <section className="mx-auto flex w-full max-w-screen-xl px-6">
        <div className="relative flex w-full flex-col ">
          <div className="flex flex-col items-start justify-center gap-16">
            <div className="my-6 grid grid-cols-2 gap-16 md:grid-cols-1 lg:grid-cols-2">
              {features.map(itm => (
                <div className="border border-zinc-200 p-4 bg-white dark:border-zinc-800 dark:bg-zinc-950 dark:text-zinc-50 rounded-xl shadow-none flex flex-col items-start gap-1">
                  {itm.icon}
                  <h5 className="pt-1 text-lg font-medium">{itm.title}</h5>
                  <p className="text-sm font-normal text-[#71717a]">
                    {itm.desc}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Features
