import { useEffect, useState } from "react"
import { useMutation } from "react-query"
import { Table, Card } from "reactstrap"

import { FaTrash } from "react-icons/fa"

import http from "services/http-common"
import { Tooltip, IconButton } from "@material-tailwind/react"
import AddConditions from "./AddConditions"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { getAllConditions } from "store/actions"

const AllConditions = () => {
  const { allConditions } = useSelector(state => state.Contraindication)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const deleteSubstance = useMutation(
    async substanceId => {
      return await http.deleteSubstance("user/substances/deleteCondition", {
        userId: {
          id: substanceId,
        },
      })
    },
    {
      onSuccess: () => {
        dispatch(getAllConditions())
        setLoading(false)
      },
      onError: error => {
        console.log(error)
        setLoading(false)
      },
    },
  )

  const onDeleteSubstance = id => {
    setLoading(true)
    deleteSubstance.mutate(id)
  }
  useEffect(() => {
    !allConditions?.length > 0 && dispatch(getAllConditions())
  }, [])

  return (
    <>
      <div className="mt-4 mb-8 flex flex-col gap-12">
        <Card>
          <div className="card-body">
            <div className="flex justify-between items-center">
              <h4 className="card-title mb-4">All Conditions</h4>
              <AddConditions />
            </div>
            {allConditions.length > 0 && (
              <div className="table-responsive">
                <Table className="align-middle table-centered table-vertical table-nowrap mb-1">
                  <tbody>
                    <tr>
                      <th>ID</th>
                      <th>Conditions</th>
                      <th>Actions</th>
                    </tr>
                    {allConditions?.map((condition, key) => (
                      <tr key={key}>
                        <td className="w-5">{key + 1}</td>
                        <td className="text-start">{condition.label}</td>
                        <td className="w-5">
                          <Tooltip content="Delete">
                            <IconButton
                              variant="text"
                              disabled={loading}
                              onClick={() => {
                                onDeleteSubstance(condition.id)
                              }}
                            >
                              <FaTrash color="#A02e31" className="h-4 w-4" />
                            </IconButton>
                          </Tooltip>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </Card>
      </div>
    </>
  )
}

export default AllConditions
