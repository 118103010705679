import * as actionTypes from "./actionTypes"

export function botRegister(botInfo, navigate, helpers) {
  return {
    type: actionTypes.REGISTER_BOT_REQUEST,
    payload: botInfo,
    navigate,
    helpers,
  }
}
export function updateBot(botInfo, navigate, helpers) {
  return {
    type: actionTypes.UPDATE_BOT_REQUEST,
    payload: botInfo,
    navigate,
    helpers,
  }
}
export function updateMycBot(botInfo, navigate, helpers) {
  return {
    type: actionTypes.UPDATE_BOT_MYC_REQUEST,
    payload: botInfo,
    navigate,
    helpers,
  }
}

export function getBotChatHistory(uniqueId = "1703183351530") {
  return {
    type: actionTypes.GET_BOT_CHAT_HISTORY_REQUEST,
    payload: uniqueId,
  }
}

export function getBotData(callBack) {
  return {
    type: actionTypes.GET_BOT_DATA_REQUEST,
    callBack,
  }
}

export function getBotAnswer(message) {
  return {
    type: actionTypes.GET_BOT_ANSWER_REQUEST,
    payload: message,
  }
}

export function getBotAnswerAgain(message) {
  return {
    type: actionTypes.GET_BOT_ANSWER_AGAIN_REQUEST,
    payload: message,
  }
}

export function handleUploadLogo(file, callBack) {
  return {
    type: actionTypes.UPLOAD_IMAGE_REQUEST,
    payload: file,
    callBack,
  }
}
export function deleteBotChatHistory() {
  return {
    type: actionTypes.DELETE_BOT_CHAT_HISTORY_REQUEST,
  }
}

export function getBotQuickResponses(callBack) {
  return {
    type: actionTypes.GET_BOT_QUICK_RESPONSES_REQUEST,
    callBack,
  }
}

export function handleApplyforPro(callBack) {
  return {
    type: actionTypes.UPGRADE_TO_PRO_REQUEST,
    callBack,
  }
}

export const getBotDetails = () => ({
  type: actionTypes.GET_BOT_DETAILS_REQUEST,
})

export const getMagicMycBotDetails = () => ({
  type: actionTypes.GET_BOT_DATA_MYC_REQUEST,
})
export const getBotSuccess = botData => ({
  type: actionTypes.GET_BOT_DETAILS_SUCCESS,
  payload: botData,
})

export function getEmbeddings() {
  return {
    type: actionTypes.EMBEDDING_HISTORY_REQUEST,
  }
}

export function clearBotChatHistory() {
  return {
    type: actionTypes.CLEAR_BOT_MESSAGES_HISTORY,
  }
}
