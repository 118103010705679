import React from "react"
import MushroomIcon from "../../assets/images/ep_logo_trippy.png"
import { IoIosMail } from "react-icons/io"
import { FaSquarePhone } from "react-icons/fa6"

function Footer() {
  return (
    <>
      <section
        className="appie-footer-area appie-footer-about-area appie-footer-8-area"
        id="footer-section"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-about-widget">
                <a href="/">
                  <img src={MushroomIcon} alt="" className="!w-[100px]" />
                </a>
                <p className="pt-0">
                  Mush.Pro empowers coaches to streamline administrative tasks,
                  provide ongoing client support, and gain deeper insights.
                </p>

                <div className="social mt-30">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-pinterest-p"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="footer-widget-info">
                <h4 className="title">Get In Touch</h4>
                <ul>
                  <li>
                    <a
                      href="mailto:info@entheo.info"
                      className="flex justify-start items-center gap-2"
                    >
                      <IoIosMail className="text-xl" /> info@entheo.info
                    </a>
                  </li>
                  <li>
                    <a
                      href="tel:+1 415-390-6250"
                      className="flex justify-start items-center gap-2"
                    >
                      <FaSquarePhone className="text-xl" />
                      +1 415-390-6250
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div
                className="
                footer-copyright
                d-flex
                align-items-center
                justify-content-between
                pt-35
              "
              >
                <div className="copyright-text">
                  <p>Copyright © 2024 Mush.Pro. All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Footer
