/* DATA */
export const GET_PDFS_REQUEST = "GET_PDFS_REQUEST"
export const GET_MYDOCS_REQUEST = "GET_MYDOCS_REQUEST"
export const UPLOAD_CONTRIBUTE_FILES_REQUEST = "UPLOAD_CONTRIBUTE_FILES_REQUEST"
export const CONVERT_DOCUMENTS_PRIVATE_REQUEST =
  "CONVERT_DOCUMENTS_PRIVATE_REQUEST"

export const DELETE_DOCUMENTS_REQUEST = "DELETE_DOCUMENTS_REQUEST"
export const GET_WEBSITES_REQUEST = "GET_WEBSITES_REQUEST"
export const GET_MYWEBSITES_REQUEST = "GET_MYWEBSITES_REQUEST"
export const REGSITER_URL_REQUEST = "REGSITER_URL_REQUEST"
export const REGSITER_CONTRIBUTE_URL_REQUEST = "REGSITER_CONTRIBUTE_URL_REQUEST"
export const DELETE_WEBSITES_REQUEST = "DELETE_WEBSITES_REQUEST"
export const UPLOAD_FILES_REQUEST = "UPLOAD_FILES_REQUEST"
export const GET_PDFS_REQUEST_SUCCESS = "GET_PDFS_REQUEST_SUCCESS"
export const GET_MYWEBSITES_REQUEST_SUCCESS = "GET_MYWEBSITES_REQUEST_SUCCESS"
export const GET_MYDOCS_REQUEST_SUCCESS = "GET_MYDOCS_REQUEST_SUCCESS"
export const GET_WEBSITES_REQUEST_SUCCESS = "GET_WEBSITES_REQUEST_SUCCESS"
export const DELETE_BOT_CHAT_HISTORY_REQUEST_SUCCESS =
  "DELETE_BOT_CHAT_HISTORY_REQUEST_SUCCESS"
