import { setSessionExpired, useMainController } from "context"
import { convertToTitleCase } from "lib/util"
import React, { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useMutation } from "react-query"
import { useSelector } from "react-redux"
import {
  Col,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  Label,
  Input,
  Button,
} from "reactstrap"
import http from "services/http-common"

function AddQuestionManually({ getFaqs }) {
  const { userRole } = useSelector(state => state.Login)
  const [dispatch] = useMainController()
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [allIntents, setAllIntents] = useState(null)
  const [faqContent, setFaqContent] = useState({
    question: "",
    answer: "",
  })

  const toggle = () => {
    setModal(!modal)
  }

  const onChangeContent = e => {
    setFaqContent({ ...faqContent, [e.target.name]: e.target.value })
  }

  const getIntents = useMutation(
    () => {
      return http.get(
        "user/scheduling/fetch-intents",
        null,
        setSessionExpired,
        dispatch,
      )
    },
    {
      onSuccess: ({ data }) => {
        setAllIntents(data.intents)
      },
      onError: error => {
        toast.error(error.response.data.message)
      },
    },
  )

  const saveContent = useMutation(
    () => {
      return http.post(
        `${
          userRole === "admin"
            ? "user/quick-replies/createForAdmin"
            : "user/quick-replies/createForPro"
        }`,
        faqContent,
        null,
      )
    },
    {
      onSuccess: ({ data }) => {
        toast.success("Successfully Added!")
        getFaqs()
        toggle()
        setFaqContent({
          question: "",
          answer: "",
        })
      },
      onError: error => {
        toast.error(error.response.data.message)
      },
      onMutate: () => {
        setLoading(true)
      },
      onSettled: () => {
        setLoading(false)
      },
    },
  )

  const onSubmit = () => {
    setLoading(true)
    saveContent.mutate()
  }

  useEffect(() => {
    getIntents.mutate()
  }, [])

  return (
    <React.Fragment>
      <Button
        className="btn btn-primary waves-effect waves-light w-full mt-2 addtask-btn bg-[#7a71bb]"
        onClick={toggle}
      >
        <i className="mdi mdi-plus me-1"></i> Add Manually
      </Button>
      <Modal
        id="modalForm"
        isOpen={modal}
        toggle={toggle}
        centered={true}
        size="lg"
      >
        <ModalHeader toggle={toggle}>Add Faqs</ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              e.preventDefault()
              onSubmit()
              return false
            }}
          >
            <div className="form-group mb-3">
              <Label htmlFor="question" className="col-form-label">
                Question <span className="text-danger">*</span>
              </Label>
              <Col lg={12}>
                <Input
                  id="question"
                  name="question"
                  type="text"
                  className="form-control validate"
                  value={faqContent?.question}
                  onChange={onChangeContent}
                  placeholder="Enter Question "
                  validate={{ required: { value: true } }}
                />
              </Col>
            </div>
            <div className="form-group mb-3">
              <Label htmlFor="answer" className="col-form-label">
                Answer <span className="text-danger">*</span>
              </Label>
              <Col lg={12}>
                <textarea
                  id="answer"
                  name="answer"
                  className="form-control"
                  placeholder="Enter Answer"
                  value={faqContent?.answer}
                  onChange={onChangeContent}
                  validate={{ required: { value: true } }}
                ></textarea>
              </Col>
            </div>

            <div className="form-group mb-4">
              <Label htmlFor="function" className="col-form-label">
                Question's Intent
              </Label>{" "}
              <div className="col-lg-12">
                <select
                  className="form-select validate"
                  name="function"
                  id="function"
                  onChange={onChangeContent}
                  // onChange={validation.handleChange}
                  // onBlur={validation.handleBlur}
                  // value={validation.values.badgeText}
                >
                  <option value={""}>Select Question's Intent</option>
                  {allIntents &&
                    allIntents.map(intent => (
                      <option value={intent.tag}>
                        {convertToTitleCase(intent.tag)}
                      </option>
                    ))}
                  <option value={""}>Other</option>
                </select>
              </div>
            </div>

            <Row>
              <Col lg={10}>
                <button
                  disabled={loading}
                  type="submit"
                  className="btn btn-primary bg-[#7a71bb]"
                  id="updatetaskdetail"
                >
                  Add Now
                </button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default AddQuestionManually
