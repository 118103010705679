import React from "react"

import { Button, Modal } from "reactstrap"
import parse from "html-react-parser"
import { useSelector } from "react-redux"
import http from "../../services/http-common"
import { useMutation } from "react-query"
import { setSessionExpired, useMainController } from "../../context"

import mushGptIcon from "../../assets/images/image 7.png"
import toast from "react-hot-toast"
import FormattedResponse from "lib/FormattedResponse"

const ShowSources = ({
  isMagicMyc = false,
  personalityBot = false,
  questionId,
  question,
  message,
  refer,
}) => {
  const [open, setOpen] = React.useState(false)
  const { user } = useSelector(state => state.Login)
  const [dispatch] = useMainController()
  const { botProfile } = useSelector(state => state.bot)

  const handleOpen = () => setOpen(!open)
  const scrapeWebSiteContent = useMutation(
    id => {
      return http.post(
        `chat/email-references`,
        {
          chatId: id,
          email: user?.email,
          botName: personalityBot ? botProfile?.botName : "Magic Myc AI",
          flag: isMagicMyc,
        },
        null,
        setSessionExpired,
        dispatch,
      )
    },
    {
      onSuccess: ({ data }) => {
        setOpen(!open)
        toast.success(data.message)
      },
      onError: err => {
        errorFunc(err)
      },
    },
  )
  const errorFunc = error => {
    let message = error.response.data.message
    toast.error(message)
  }
  const handleMailSources = id => {
    scrapeWebSiteContent.mutate(id)
  }

  return (
    <>
      <div className="flex justify-start  items-center">
        {refer?.length && (
          <Button
            type="button"
            color="link"
            className="waves-effect waves-light font-bold text-sm w-auto !p-0"
            data-toggle="modal"
            data-target=".bs-example-modal-center"
            onClick={() => {
              handleOpen()
            }}
          >
            Refs: [{refer?.length}]
          </Button>
        )}
      </div>

      <Modal
        size="lg"
        isOpen={open}
        toggle={() => {
          handleOpen()
        }}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">References</h5>
          <button
            type="button"
            onClick={() => {
              handleOpen(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="grid gap-6 w-full ">
            {question && (
              <p>
                <span className="font-bold me-1">{user.given_name}: </span>
                {parse(question.replace(/\n/g, "<br>"))}
              </p>
            )}
          </div>
          <div className="grid gap-6 w-full ">
            <p>
              <span className="font-bold me-1">
                {personalityBot ? botProfile?.botName : "Magic Myc AI"}
                {": "}
              </span>
              <FormattedResponse response={message} />

              {/* {parse(message.replace(/\n/g, "<br>"))} */}
            </p>
          </div>
          <div className="grid gap-1 w-full ">
            <p>
              <span className="font-bold"> References: </span>
            </p>
            {refer.map((item, index) => {
              // console.log(item);
              return (
                <p key={index}>
                  <a
                    href={item?.url ? item.url : "#"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.url?.includes("book") ? (
                      <p>
                        {index + 1 + ". Doc: "}
                        {item.title}
                        {/* {item.title.length > 25
                          ? item.title.slice(0, 25)
                          : item.title} */}

                        {item.author && " by " + item.author}

                        {/* {item.author.length > 10
                          ? item.author.slice(0, 10) + "."
                          : item.author} */}
                        {(item.page || item.section) && (
                          <>
                            {". ( "}
                            {typeof item.page === "string" ? (
                              <>
                                {item.page
                                  ?.toString()
                                  .split(",")
                                  .map((itm, idx) => (
                                    <React.Fragment key={idx}>
                                      {`${
                                        itm !== "undefined" ? `p. ${itm}` : ""
                                      } sec. ${
                                        item.section.toString().split(",")[idx]
                                      } ${
                                        item.section.toString().split(",")
                                          .length !==
                                        idx + 1
                                          ? ","
                                          : ""
                                      } `}
                                    </React.Fragment>
                                  ))}
                              </>
                            ) : (
                              <>
                                {(item.page || item.section) && (
                                  <>
                                    {`${
                                      item.page ? "p." + item.page + " " : ""
                                    }${item.section && "sec." + item.section}`}
                                  </>
                                )}
                              </>
                            )}

                            {") "}
                          </>
                        )}
                      </p>
                    ) : (
                      <>
                        <p className="break-all">
                          {index + 1 + ". Website: "}
                          {item.title ? item.title : item?.url}
                          {item.author && " by " + item.author}
                          {(item.page || item.section) &&
                            `. (${item.page ? "p." + item.page + " " : ""}${
                              item.section && "sec." + item.section
                            })`}
                        </p>
                      </>
                    )}
                  </a>
                </p>
              )
            })}
          </div>
          <div className="flex justify-center items-center">
            <a href="https://mushgpt.com/" target="_blank" rel="noreferrer">
              <img
                src={mushGptIcon}
                alt="MushGPT"
                className="max-w-[171px] max-h-[73px]"
              />
            </a>
          </div>
        </div>
        <div className="modal-footer">
          <Button
            type="button"
            onClick={() => {
              handleOpen()
            }}
            className="btn btn-secondary waves-effect bg-[#5c636a]"
            data-dismiss="modal"
          >
            Close
          </Button>
          <Button
            type="button"
            className="btn btn-primary waves-effect waves-light bg-[#7a6fbe]"
            onClick={() => {
              handleMailSources(questionId)
            }}
          >
            Email this result
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default ShowSources
