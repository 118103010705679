import React, { useEffect } from "react"

const PrefineryForm = () => {
  useEffect(() => {
    // Initialize Prefinery function
    window.prefinery =
      window.prefinery ||
      function () {
        ;(window.prefinery.q = window.prefinery.q || []).push(arguments)
      }

    // Load the Prefinery script
    const script = document.createElement("script")
    script.src = "https://widget.prefinery.com/widget/v2/y4rxqi5y.js"
    script.defer = true
    document.body.appendChild(script)

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <div className="w-full">
      <div className="prefinery-form-embed"></div>
    </div>
  )
}

export default PrefineryForm
