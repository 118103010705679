import React, { useState } from "react"
import { Tooltip, IconButton } from "@material-tailwind/react"
import { Modal } from "reactstrap"
import * as messages from "services/messages"
import { useMutation } from "react-query"
import http from "services/http-common"
import { toast } from "react-toastify"
import { FaPlus } from "react-icons/fa"
import Select from "react-select"
import { useSelector } from "react-redux"
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { getAllGroups } from "store/actions"
import { useDispatch } from "react-redux"

function AddSubstance({ groupId }) {
  const dispatch = useDispatch()
  const { allSubstances, allConditions } = useSelector(
    state => state.Contraindication,
  )
  const [activeTab, setActiveTab] = useState("1")
  const [open, setOpen] = React.useState(false)
  const [selectedSubstances, setSelectedSubstances] = useState(null)
  const [selectedConditions, setSelectedConditions] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleOpen = () => {
    setOpen(!open)
  }

  const errorFunc = error => {
    let message
    switch (error.response.status) {
      case 500: {
        message = messages.SERVER_ERROR
        break
      }
      case 404: {
        message = messages.NOT_FOUND_ERROR
        break
      }
      case 400: {
        message = messages.BAD_REQUEST
        break
      }
      default: {
        message = error.message
      }
    }
    toast.error(message)
  }

  const onSubmitSubstances = () => {
    setLoading(true)
    if (selectedSubstances) {
      let newArr = selectedSubstances?.map(obj => obj.id)
      createSubsctance.mutate(newArr)
    }
  }

  const onSubmitGroups = () => {
    setLoading(true)
    if (selectedConditions) {
      let newArr = selectedConditions?.map(obj => obj.id)
      CreateConditions.mutate(newArr)
    }
  }

  const createSubsctance = useMutation(
    susbtancesId => {
      return http.post(
        `user/substances/grouping`,
        {
          substanceIds: susbtancesId,
          groupId: groupId,
        },
        null,
      )
    },
    {
      onSuccess: ({ data }) => {
        // toast.success("Successfully Added!")
        setOpen(!open)
        dispatch(getAllGroups())
        setLoading(false)
      },
      onError: error => {
        errorFunc(error)
        setLoading(false)
      },
      onMutate: () => {
        setLoading(true)
      },
      onSettled: () => {
        setLoading(false)
      },
    },
  )

  const CreateConditions = useMutation(
    susbtancesId => {
      return http.post(
        `user/substances/grouping`,
        {
          conditionIds: susbtancesId,
          groupId: groupId,
        },
        null,
      )
    },
    {
      onSuccess: ({ data }) => {
        // toast.success("Successfully Added!")
        setOpen(!open)
        dispatch(getAllGroups())
        setLoading(false)
      },
      onError: error => {
        errorFunc(error)
        setLoading(false)
      },
      onMutate: () => {
        setLoading(true)
      },
      onSettled: () => {
        setLoading(false)
      },
    },
  )

  function handleSubstance(values) {
    setSelectedSubstances(values)
  }
  function handleConditions(values) {
    setSelectedConditions(values)
  }

  return (
    <>
      <div className="flex justify-end  items-center me-2">
        <Tooltip content="Add Substances">
          <IconButton variant="text" onClick={handleOpen}>
            <FaPlus className="h-4 w-4 text-[#7a6fbe]" />
          </IconButton>
        </Tooltip>
      </div>

      <Modal
        isOpen={open}
        size="md"
        toggle={() => {
          handleOpen()
        }}
        centered={true}
      >
        <div className="modal-body w-full flex flex-col justify-center">
          <Card className="!mb-0">
            <CardBody>
              <Nav pills className="navtab-bg nav-justified">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={`${activeTab === "1" && "active"}`}
                    onClick={() => setActiveTab("1")}
                  >
                    Add Substances
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={`${activeTab === "2" && "active"}`}
                    onClick={() => setActiveTab("2")}
                  >
                    Add Groups
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab} className="p-3 text-muted">
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <div className="modal-body w-full flex flex-col justify-center">
                        {allSubstances && (
                          <div className="w-full ">
                            <div className="mb-3">
                              <label className="control-label">
                                Select Substances
                              </label>
                              <Select
                                isMulti={true}
                                name="colors"
                                options={allSubstances}
                                className="basic-multi-select select2-selection"
                                onChange={v => {
                                  handleSubstance(v)
                                }}
                                classNamePrefix="select"
                              />
                            </div>
                          </div>
                        )}

                        <button
                          size="sm"
                          className="btn btn-primary py-2 px-4 mt-2 "
                          onClick={onSubmitSubstances}
                          disabled={loading}
                        >
                          Submit
                        </button>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <div className="modal-body w-full flex flex-col justify-center">
                        {allConditions && (
                          <div className="w-full ">
                            <div className="mb-3">
                              <label className="control-label">
                                Select Conditions
                              </label>
                              <Select
                                isMulti={true}
                                name="colors"
                                options={allConditions}
                                className="basic-multi-select select2-selection"
                                onChange={v => {
                                  handleConditions(v)
                                }}
                                classNamePrefix="select"
                              />
                            </div>
                          </div>
                        )}
                        <button
                          size="sm"
                          className="btn btn-primary py-2 px-4 mt-2 "
                          onClick={onSubmitGroups}
                          disabled={loading}
                        >
                          Submit
                        </button>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  )
}

export default AddSubstance
