import PropTypes from "prop-types"
import React from "react"

import { connect } from "react-redux"

import { Link } from "react-router-dom"

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

// import megamenuImg from "../../assets/images/megamenu-img.png"
import MushroomIcon from "../../assets/images/ep_logo_trippy.png"

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"
import { useLocation } from "react-router-dom"
import ModelSelection from "./ModelSelection"
import NotificationDropdown from "components/CommonForBoth/TopbarDropdown/NotificationDropdown"

const Header = props => {
  const location = useLocation()

  // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT,
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    body.classList.toggle("vertical-collpsed")
    body.classList.toggle("sidebar-enable")
  }
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex justify-center items-center">
            <div className="navbar-brand-box sm:flex hidden justify-center items-center py-2">
              <Link
                to="/dashboard"
                className="logo logo-dark flex flex-col justify-center items-center"
              >
                <span className="logo-sm">
                  <img
                    src={MushroomIcon}
                    alt="Mush Pro"
                    className="!max-w-[60px] !max-h-[60px]"
                  />
                </span>
                <span className="logo-lg">
                  <img
                    src={MushroomIcon}
                    alt="Mush Pro"
                    className="!max-w-[60px] !max-h-[60px]"
                  />
                </span>
              </Link>

              <Link to="/dashboard" className="logo logo-light">
                <span className="md:!hidden !block ">
                  <img
                    src={MushroomIcon}
                    alt="Mush Pro"
                    className="!max-w-[30px] !max-h-[60px]"
                  />
                </span>
                <span className="sm:!block !hidden">
                  <img
                    src={MushroomIcon}
                    alt="Mush Pro"
                    className="!max-w-[60px] !max-h-[60px]"
                  />
                </span>
              </Link>
            </div>
            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-24 header-item waves-effect vertical-menu-btn"
              id="vertical-menu-btn"
            >
              <i className="mdi mdi-menu"></i>
            </button>
          </div>
          {(location.pathname === "/magic-myc" ||
            location.pathname === "/my-bot") && <ModelSelection />}

          <div className="d-flex">
            <div className="dropdown d-none d-lg-inline-block">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="mdi mdi-fullscreen font-size-24"></i>
              </button>
            </div>
            <NotificationDropdown />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
