import { useEffect, useState } from "react"
import { useMutation } from "react-query"
import {
  Card,
  Collapse,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap"

import http from "services/http-common"
import {
  getAllConditions,
  getAllContraindications,
  getAllGroups,
  getAllSubstances,
} from "store/actions"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import AddContraindication from "./AddContraindication"
import AddNewSubstance from "./AddNewSubstance"
import ListOfSubstances from "./ListOfSubstances"
import EditSubstance from "./EditSubstance"

const AllContraindication = () => {
  const dispatch = useDispatch()
  const { allSubstances, allConditions, allGroups, allContraindications } =
    useSelector(state => state.Contraindication)
  const [showData, setShowData] = useState("0")
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    cp: 1,
    ps: 10,
  })

  const deleteSubstance = useMutation(
    async substanceId => {
      console.log(substanceId)
      return await http.deleteSubstance("user/substances/deleteSubstance", {
        userId: {
          id: substanceId,
        },
      })
    },
    {
      onSuccess: () => {
        setLoading(false)
        dispatch(getAllContraindications())
      },
      onError: error => {
        console.log(error)
      },
    },
  )

  const onDeleteContraindication = id => {
    setLoading(true)
    deleteSubstance.mutate(id)
  }

  const handlePagination = type => {
    if (type === "prev") {
      if (pagination.cp === 1) return
      setPagination(prevState => ({
        ...prevState,
        cp: pagination.cp - 1,
      }))
      dispatch(
        getAllContraindications({ cp: pagination.cp - 1, ps: pagination.ps }),
      )
    } else {
      setPagination(prevState => ({
        ...prevState,
        cp: pagination.cp + 1,
      }))
      dispatch(
        getAllContraindications({ cp: pagination.cp + 1, ps: pagination.ps }),
      )
    }
  }

  useEffect(() => {
    !allSubstances?.length > 0 && dispatch(getAllSubstances())
    !allConditions?.length > 0 && dispatch(getAllConditions())
    !allContraindications?.length > 0 &&
      dispatch(getAllContraindications(pagination))
    !allGroups?.length > 0 && dispatch(getAllGroups())
  }, [])
  return (
    <>
      <div className=" flex flex-col gap-12">
        <Card>
          <div className="card-body">
            <div className="flex justify-between items-center">
              <h4 className="card-title ">All Substances</h4>
              <AddNewSubstance />
            </div>

            {allContraindications?.map(contraindication => (
              <div className="accordion my-2" id="accordion">
                <div className="accordion-item">
                  <div
                    className="accordion-header flex flex-col sm:flex-row justify-between items-center !w-full border-b-2 border-[#e4e2f2] "
                    id="headingOne"
                  >
                    <button
                      className="accordion-button fw-medium "
                      type="button"
                      onClick={() => {
                        showData === contraindication.id
                          ? setShowData("null")
                          : setShowData(contraindication.id)
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {contraindication.substanceName}
                    </button>
                    <div className="flex items-center mx-2 gap-2">
                      <button
                        size="sm"
                        className="btn btn-danger waves-effect waves-light"
                        onClick={() => {
                          onDeleteContraindication(contraindication.id)
                        }}
                        disabled={loading}
                      >
                        Delete
                      </button>
                      <EditSubstance
                        substanceName={contraindication.substanceName}
                        substanceId={contraindication.id}
                      />
                      <AddContraindication substanceId={contraindication.id} />
                    </div>
                  </div>

                  <Collapse
                    isOpen={showData === contraindication.id}
                    className="accordion-collapse"
                  >
                    <div className="accordion-body text-muted !visible">
                      {showData === contraindication.id && (
                        <ListOfSubstances
                          contraindication={contraindication.id}
                        />
                      )}

                      {/* <ol type="1">
                        {contraindication?.groups?.map(group => {
                          return <li>{group.groupName + " (Group)"} </li>
                        })}
                      </ol>
                      <ol type="1">
                        {contraindication?.substances?.map(substance => {
                          return (
                            <li>{substance.substanceName + " (Substance)"} </li>
                          )
                        })}
                      </ol>

                      <ol type="1">
                        {contraindication?.conditions?.map(condition => {
                          return (
                            <li>{condition.conditionName + " (Condition)"} </li>
                          )
                        })}
                      </ol> */}
                    </div>
                  </Collapse>
                </div>
              </div>
            ))}
            <div className="flex justify-end mx-2">
              <Pagination>
                <PaginationItem disabled={loading || pagination.cp === 1}>
                  <PaginationLink
                    tabIndex="-1"
                    onClick={() => handlePagination("prev")}
                  >
                    Previous
                  </PaginationLink>
                </PaginationItem>

                <PaginationItem disabled={loading}>
                  <PaginationLink onClick={() => handlePagination("next")}>
                    Next
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </div>
          </div>
        </Card>
      </div>
    </>
  )
}

export default AllContraindication
