import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import parse from "html-react-parser"

import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
import listPlugin from "@fullcalendar/list"
import CreatableSelect from "react-select/creatable"
import {
  addNewEvent,
  deleteEvent,
  getCategories,
  getEvents,
  updateEvent,
} from "../../store/actions"
import DeleteModal from "./DeleteModal"
import "./style.css"
import { calenderDefaultCategories } from "common/data"
import { useMutation } from "react-query"
import http from "services/http-common"
import toast from "react-hot-toast"
import { setSessionExpired, useMainController } from "context"
import { restructureEvents } from "lib/util"
import AddEvent from "./AddEvent"
import { gapi } from "gapi-script"

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
const API_KEY = process.env.REACT_APP_API_KEY
const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
]
const SCOPES = "https://www.googleapis.com/auth/calendar.readonly"

const Calender = props => {
  document.title = "Appointments | Entheo.Pro by the Entheology Project"
  const [dispatch] = useMainController()
  const [allEvents, setAllEvents] = useState(null)
  const { onGetCategories, onGetEvents } = props
  const [setCalenderView, updatedCalenderView] = useState("dayGridMonth")
  const [modal, setModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [modalAddEvent, setModalAddEvent] = useState(false)
  const [event, setEvent] = useState({})
  const [selectedDay, setSelectedDay] = useState(0)
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [allSeekerMails, setallSeekerMails] = useState(null)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isFetchedEvents, setIsFetchedEvents] = useState(false)

  const calendarRef = useRef()

  const getEvents = useMutation(
    () => {
      return http.get(
        "user/scheduling/events",
        null,
        setSessionExpired,
        dispatch,
      )
    },
    {
      onSuccess: ({ data }) => {
        let newEvents = restructureEvents(data.events)
        setAllEvents(newEvents)
        setIsFetchedEvents(true)
      },
      onError: error => {
        toast.error(error.response.data.message)
        setIsFetchedEvents(false)
      },
    },
  )

  const deleteActiveEvent = useMutation(
    eventId => {
      return http.delete(`user/scheduling/delete-event?id=${eventId}`, null)
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message)
        getEvents.mutate()
      },
      onError: error => {
        toast.error(error.response.data.message)
      },
    },
  )

  const updateContent = useMutation(
    () => {
      return http.put(`user/scheduling/update-event`, event, null)
    },
    {
      onSuccess: ({ data }) => {
        toast.success("Successfully Updated!")
        setallSeekerMails([])
        getEvents.mutate()
      },
      onError: error => {
        toast.error(error.response.data.message)
      },
      onMutate: () => {
        setLoading(true)
      },
      onSettled: () => {
        setLoading(false)
      },
    },
  )

  useEffect(() => {
    getEvents.mutate()
  }, [])

  useEffect(() => {
    const initClient = () => {
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
        })
        .then(() => {
          const authInstance = gapi.auth2?.getAuthInstance()
          setIsAuthenticated(authInstance.isSignedIn.get())
          authInstance.isSignedIn?.listen(setIsAuthenticated)
        })
    }

    gapi.load("client:auth2", initClient)
  }, [])

  const handleAuthClick = () => {
    gapi.auth2.getAuthInstance()?.signIn()
  }

  const handleSignOutClick = () => {
    gapi.auth2.getAuthInstance().signOut()
    getEvents.mutate()
  }

  const listUpcomingEvents = () => {
    gapi.client.calendar.events
      .list({
        calendarId: "primary",
        timeMin: new Date().toISOString(),
        showDeleted: false,
        singleEvents: true,
        maxResults: 10,
        orderBy: "startTime",
      })
      .then(response => {
        // const events = response.result.items.map(convertCalendarEvents)
        if (allEvents) {
          const mergedArray = allEvents.concat(
            response.result.items.map(event => ({
              isGoogleCalender: true,
              id: new Date().valueOf(),
              start: new Date(event.start.dateTime).toISOString(),
              startDate: new Date(event.start.dateTime).toString(),
              end: new Date(event.end.dateTime).toISOString(),
              endDate: new Date(event.end.dateTime).toString(),
              title: event.summary ? event.summary : "null",
              location: event.location ? event.location : null,
              meetingLink: event.location ? event.location : null,
              description: event.description ? event.description : null,
              isAppointment: true,
              created_at: new Date(event.created).toISOString(),
              updated_at: new Date(event.updated).toISOString(),
              attendees: event.attendees
                ? event.attendees.map(attendee => ({
                    email: attendee.email,
                    confirmed: attendee.responseStatus === "accepted",
                  }))
                : [],
              url: "#",
              allDay: true,
              className: "bg-info text-white",
            })),
          )
          setAllEvents(mergedArray)
        }
        // setEvents(events)
      })
  }

  const getApi = () => {
    const { current: calendarDom } = calendarRef

    return calendarDom ? calendarDom.getApi() : null
  }

  const changeView = (view, API) => {
    API && API.changeView(view)
  }

  useEffect(() => {
    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    })

    getInitialView()
    const api = getApi()
    changeView(setCalenderView, api)
  }, [onGetCategories, onGetEvents, setCalenderView])

  useEffect(() => {
    if (!modal && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({})
        setIsEdit(false)
      }, 500)
    }
  }, [modal, event, isEdit])

  useEffect(() => {
    isAuthenticated && isFetchedEvents && listUpcomingEvents()
  }, [isAuthenticated, isFetchedEvents])

  /**
   * Handling the modal state
   */
  const toggle = () => {
    setModal(!modal)
  }

  const toggleCategory = () => {
    setModalAddEvent(!modalAddEvent)
  }

  /**
   * Handling date click on calendar
   */
  const handleDateClick = arg => {
    setSelectedDay(arg)
    // toggle()
  }

  /**
   * Handling click on event on calendar
   */
  const handleEventClick = arg => {
    const event = arg.event
    let targetEvent = allEvents?.find(itm => itm.id === parseInt(event.id))
    setEvent(targetEvent)
    let seekersMails = []
    targetEvent?.attendees?.map(_item => {
      if (_item.role !== "user") {
        seekersMails.push({
          id: _item.id,
          label: _item.email,
          value: _item.email,
        })
      }
    })
    setallSeekerMails(seekersMails)
    setIsEdit(true)
    toggle()
  }

  const onChangeContent = e => {
    setEvent({ ...event, [e.target.name]: e.target.value })
  }
  /**
   * Handling submit event on event form
   */
  const handleValidEventSubmit = (e, values) => {
    const { onAddNewEvent, onUpdateEvent } = props
    if (isEdit) {
      updateContent.mutate()
    } else {
      const newEvent = {
        id: Math.floor(Math.random() * 100),
        title: values["title"],
        start: selectedDay ? selectedDay.date : new Date(),
        className: values.category + " text-white",
      }
      // save new event
      onAddNewEvent(newEvent)
    }
    setSelectedDay(null)
    toggle()
  }

  const formatDefaultDate = originalDateString => {
    const originalDate = new Date(originalDateString)
    const formattedDate = `${originalDate.getFullYear()}-${(
      "0" +
      (originalDate.getMonth() + 1)
    ).slice(-2)}-${("0" + originalDate.getDate()).slice(-2)}T${(
      "0" + originalDate.getHours()
    ).slice(-2)}:${("0" + originalDate.getMinutes()).slice(-2)}:${(
      "0" + originalDate.getSeconds()
    ).slice(-2)}`

    return formattedDate
  }
  function handleSeekerMail(values) {
    if (isEdit) {
      let newArr = values?.map(obj => obj.label)
      setEvent({ ...event, seekerEmails: newArr })
      setallSeekerMails(values)
    } else {
      let newArr = values?.map(obj => obj.label)
      setEvent({ ...event, seekerEmails: newArr })
    }
  }
  const handleValidEventSubmitcategory = values => {
    const { onAddNewEvent } = props

    const newEvent = {
      id: Math.floor(Math.random() * 100),
      title: values["title_category"],
      start: selectedDay ? selectedDay.date : new Date(),
      className: values.event_category + " text-white",
    }
    // save new event

    onAddNewEvent(newEvent)
    toggleCategory()
  }

  /**
   * On delete event
   */
  const handleDeleteEvent = () => {
    const { onDeleteEvent } = props
    onDeleteEvent(event)
    deleteActiveEvent.mutate(event.id)
    setDeleteModal(false)
    toggle()
  }

  /**
   * On category darg event
   */
  const onDrag = event => {
    event.preventDefault()
  }

  /**
   * On calendar drop event
   */
  const onDrop = event => {
    const { onAddNewEvent } = props
    const draggedEl = event.draggedEl
    const newEvent = {
      id: Math.floor(Math.random() * 100),
      title: draggedEl.innerText,
      start: event.date,
      className: draggedEl.className,
    }
    onAddNewEvent(newEvent)
  }

  const getInitialView = () => {
    if (window.innerWidth >= 768 && window.innerWidth < 1200) {
      updatedCalenderView("dayGridWeek")
    } else if (window.innerWidth <= 768) {
      updatedCalenderView("listWeek")
    } else {
      updatedCalenderView("dayGridMonth")
    }
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />

      <Row className="mb-4">
        <Col xl={3}>
          <Card>
            <CardBody className="d-grid">
              <div className="d-grid">
                <AddEvent getEvents={getEvents} />
              </div>
              <div id="external-events" className="my-2">
                <div>
                  {isAuthenticated ? (
                    <div>
                      <Button
                        color="primary"
                        className="btn-block flex justify-center items-center gap-1 my-2 !text-[#7a6fbe] w-full hover:!border-1 hover:!border-[#7a6fbe] hover:!text-[#7a6fbe] hover:bg-white"
                        onClick={listUpcomingEvents}
                      >
                        <i className="mdi mdi-calendar-plus text-base" />{" "}
                        Refresh Google Calendar Events
                      </Button>
                      <Button
                        color="primary"
                        className="btn-block flex justify-center items-center gap-1  w-full !text-[#000000] !border-1 !border-[#ef6767] hover:!border-[#ef6767] hover:!text-[#000000] hover:bg-white"
                        onClick={handleSignOutClick}
                      >
                        <i className="mdi mdi-calendar-plus text-base" />{" "}
                        Disconnect Google Calendar
                      </Button>
                    </div>
                  ) : (
                    <Button
                      color="primary"
                      className="btn-block flex justify-center items-center gap-1 !text-[#7a6fbe] w-full hover:!border-1 hover:!border-[#7a6fbe] hover:!text-[#7a6fbe] hover:bg-white"
                      onClick={handleAuthClick}
                    >
                      <i className="mdi mdi-calendar-sync text-base" /> Sync
                      Google Calendar
                    </Button>
                  )}
                </div>
                <br />
                <p className="text-muted">
                  Drag and drop your event or click in the calendar
                </p>
                {calenderDefaultCategories &&
                  calenderDefaultCategories.map((category, i) => (
                    <div
                      className={`external-event fc-event ${category.type} text-white`}
                      key={"cat-" + category.id}
                      draggable
                      onDrag={event => onDrag(event, category)}
                    >
                      <i className="mdi mdi-checkbox-blank-circle font-size-11 me-2" />
                      {category.title}
                    </div>
                  ))}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col className="col-xl-9">
          <div className="card mt-4 mt-xl-0 mb-0">
            <div className="card-body">
              {/* fullcalendar control */}
              <FullCalendar
                plugins={[
                  BootstrapTheme,
                  dayGridPlugin,
                  interactionPlugin,
                  listPlugin,
                ]}
                slotDuration={"00:15:00"}
                handleWindowResize={true}
                themeSystem="bootstrap"
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                }}
                events={allEvents}
                editable={false}
                droppable={false}
                selectable={false}
                dateClick={handleDateClick}
                eventClick={handleEventClick}
                drop={onDrop}
                // ref={calendarRef}
                initialView={setCalenderView}
                windowResize={getInitialView}
              />

              {/* New/Edit event modal */}
              <Modal isOpen={modal} className={props.className}>
                <ModalHeader toggle={toggle} tag="h4">
                  {!!isEdit ? "Edit Event" : "Add Event"}
                </ModalHeader>
                <ModalBody>
                  <AvForm onValidSubmit={handleValidEventSubmit}>
                    <Row form>
                      <Col className="col-12 mb-3">
                        <Label>Title</Label>
                        {event && event.isGoogleCalender ? (
                          <span>{":  " + event.title}</span>
                        ) : (
                          <Input
                            name="title"
                            label="Title"
                            type="text"
                            onChange={e => onChangeContent(e)}
                            errorMessage="Invalid Title"
                            validate={{
                              required: { value: true },
                            }}
                            value={event ? event.title : ""}
                          />
                        )}
                      </Col>
                      <Col className="col-12 mb-3">
                        <Label>Description</Label>
                        {event && event.isGoogleCalender ? (
                          <div>
                            <span>
                              {parse(
                                event?.description?.replace(
                                  /<p>(.*?)<\/p>/g,
                                  "<span>$1</span>",
                                ),
                              )}
                            </span>
                          </div>
                        ) : (
                          <Input
                            name="description"
                            label="Description"
                            type="textarea"
                            size="4"
                            onChange={e => onChangeContent(e)}
                            errorMessage="Invalid description"
                            validate={{
                              required: { value: true },
                            }}
                            value={event ? event?.description : ""}
                          />
                        )}
                      </Col>
                      <Col className="col-12 mb-3">
                        <Label>Attendees Mails </Label>
                        {event && event.isGoogleCalender ? (
                          <div>
                            {allSeekerMails?.map(attendee => (
                              <span className="m-1 p-1 rounded-md border-1 border-dark text-xs">
                                {attendee.value}
                              </span>
                            ))}
                          </div>
                        ) : (
                          <CreatableSelect
                            isMulti={true}
                            onChange={e => {
                              handleSeekerMail(e)
                            }}
                            value={allSeekerMails}
                            classNamePrefix="select2-selection"
                          />
                        )}
                      </Col>
                      {event && !event.isGoogleCalender && (
                        <Col className="col-12 mb-3">
                          <Label>Meeting Platform</Label>
                          <Input
                            name="location"
                            label="Meeting Platform"
                            type="text"
                            onChange={e => onChangeContent(e)}
                            errorMessage="Invalid Location"
                            value={event.location}
                          />
                        </Col>
                      )}

                      <Col className="col-12 mb-3 ">
                        <Label>Meeting Link</Label>
                        {event && event.isGoogleCalender ? (
                          <div>
                            <span className="break-all">
                              {event.meetingLink}
                            </span>
                          </div>
                        ) : (
                          <Input
                            name="meetingLink"
                            label="Meeting Link"
                            type="text"
                            onChange={e => onChangeContent(e)}
                            errorMessage="Invalid meetingLink"
                            value={event.meetingLink}
                          />
                        )}
                      </Col>
                      <Col className="col-12 mb-3">
                        <Label>
                          {event && event.isGoogleCalender
                            ? "Meeting Date"
                            : "Start - End"}
                        </Label>
                        <div className="flex justify-start items-center gap-2">
                          {event && event.isGoogleCalender ? (
                            <>{event.startDate}</>
                          ) : (
                            <>
                              <Input
                                name="startDate"
                                className="form-control w-auto"
                                type="datetime-local"
                                onChange={e => onChangeContent(e)}
                                defaultValue={event && event.startDate}
                                id="example-time-input"
                              />{" "}
                              {" - "}
                              <Input
                                name="endDate"
                                className="form-control w-auto"
                                type="datetime-local"
                                onChange={e => onChangeContent(e)}
                                defaultValue={event.endDate && event.endDate}
                                id="example-time-input"
                              />
                            </>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-end">
                          {!!isEdit && (
                            <button
                              type="button"
                              className={`btn btn-danger bg-[#ec536c] me-2 ${
                                event.isGoogleCalender && "cursor-not-allowed"
                              }`}
                              disabled={event.isGoogleCalender}
                              onClick={() => setDeleteModal(true)}
                            >
                              Delete
                            </button>
                          )}
                          <button
                            type="button"
                            className="btn btn-light bg-[#f8f9fa] me-2 "
                            onClick={toggle}
                          >
                            Close
                          </button>
                          <button
                            disabled={loading || event.isGoogleCalender}
                            type="submit"
                            className="btn btn-success bg-[#58db83] save-event"
                          >
                            Update
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </ModalBody>
              </Modal>

              <Modal
                isOpen={modalAddEvent}
                toggle={toggleCategory}
                className={props.className}
              >
                <ModalHeader toggle={toggleCategory} tag="h4">
                  Add an Event
                </ModalHeader>
                <ModalBody>
                  <AvForm onValidSubmit={handleValidEventSubmitcategory}>
                    <Row form>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="title_category"
                          label="Category Name"
                          type="text"
                          errorMessage="Invalid name"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            event.title_category ? event.title_category : ""
                          }
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          type="select"
                          name="event_category"
                          label="Choose Category Color"
                          value={event ? event.event_category : "bg-primary"}
                        >
                          <option value="bg-danger">Danger</option>
                          <option value="bg-success">Success</option>
                          <option value="bg-primary">Primary</option>
                          <option value="bg-info">Info</option>
                          <option value="bg-dark">Dark</option>
                          <option value="bg-warning">Warning</option>
                        </AvField>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-right">
                          <button
                            type="button"
                            className="btn btn-light me-2 bg-[#f8f9fa]"
                            onClick={toggleCategory}
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="btn btn-success save-event bg-[#58db83]"
                          >
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </ModalBody>
              </Modal>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

Calender.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  c: PropTypes.func,
}

const mapStateToProps = ({ calendar }) => ({
  events: calendar.events,
  categories: calendar.categories,
})

const mapDispatchToProps = dispatch => ({
  onGetEvents: () => dispatch(getEvents()),
  onGetCategories: () => dispatch(getCategories()),
  onAddNewEvent: event => dispatch(addNewEvent(event)),
  onUpdateEvent: event => dispatch(updateEvent(event)),
  onDeleteEvent: event => dispatch(deleteEvent(event)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Calender)
