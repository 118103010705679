import React from "react"
import { useSelector } from "react-redux"
import { Card, CardBody, Row, Col } from "reactstrap"

const Miniwidget = props => {
  const { user } = useSelector(state => state.Login)

  return (
    <React.Fragment>
      <Row>
        <Col xl={4} sm={6}>
          <Card className="mini-stat bg-primary">
            <CardBody className="card-body mini-stat-img">
              <div className="mini-stat-icon">
                <i className={"float-end mdi mdi-cube-outline"}></i>
              </div>
              <div className="text-white">
                <h6 className="text-uppercase mb-3 font-size-16 text-white  font-medium">
                  tokens used (GPT)
                </h6>
                <h2 className="mb-4 text-white !text-xl font-medium">
                  {user?.gptModelsTokenCount.toLocaleString()}{" "}
                </h2>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={4} sm={6}>
          <Card className="mini-stat bg-primary">
            <CardBody className="card-body mini-stat-img">
              <div className="mini-stat-icon">
                <i className={"float-end mdi mdi-buffer"}></i>
              </div>
              <div className="text-white">
                <h6 className="text-uppercase mb-3 font-size-16 text-white  font-medium">
                  tokens used (Gemini)
                </h6>
                <h2 className="mb-4 text-white !text-xl font-medium">
                  {user?.geminiTokenCount.toLocaleString()}{" "}
                </h2>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Miniwidget
