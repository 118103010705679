import * as React from "react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Input, Button, Alert } from "reactstrap"

import { handleUploadContributeFiles } from "../../store/data/actions"
import CircularProgress from "@mui/material/CircularProgress"
import { FaCloudUploadAlt } from "react-icons/fa"
import { XMarkIcon } from "@heroicons/react/20/solid"

export default function ContributeDocs() {
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.Login)
  const [isLoading, setIsLoading] = useState(false)
  const [uploadFiles, setUploadFiles] = useState()
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertContent, setAlertContent] = useState("")
  const [alertStatus, setAlertStatus] = useState("success")
  const onChangeUploadFiles = e => {
    setUploadFiles(e.target.files)
  }

  const onUploadCallBack = ({ status, returnData }) => {
    setAlertStatus(status)
    if (status === "success") {
      // setAlertContent(returnData.data.message);
      if (returnData.data.message == "Only 0 files uploaded") {
        setAlertContent(`This file is already available.`)
        setAlertStatus("error")
      } else {
        setAlertContent(
          `Thank you very much for your document submission! We typically take 24-48 hours to review your submission before we train it. To access the Training Docs and view your submission’s status, please apply for a PRO account at https://entheo.pro. For assistance, please email admin@mushgpt.com or just ask Magic Myc!`,
        )
      }

      setUploadFiles(null)
    } else {
      setAlertContent(returnData.message)
    }
    setIsLoading(false)
    setAlertOpen(true)
  }

  const onSubmit = () => {
    setIsLoading(true)
    if (uploadFiles?.length) {
      const data = new FormData()
      for (let i = 0; i < uploadFiles.length; i++) {
        const file = uploadFiles[i]
        data.append("books", file)
      }
      dispatch(handleUploadContributeFiles(data, onUploadCallBack))
    } else {
      return false
    }
  }

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setAlertOpen(false)
  }
  return (
    <div sx={{ width: "100%", mb: 2, boxShadow: "none" }}>
      {alertOpen && (
        <Alert
          color={alertStatus === "error" ? "danger" : "success"}
          style={{ marginTop: "13px" }}
          isOpen={alertOpen}
          dismissible
          className="flex justify-between items-center"
        >
          <div className="flex flex-col justify-start items-start">
            <h5 className="font-bold">
              {alertStatus &&
                alertStatus[0].toUpperCase() + alertStatus.slice(1)}
            </h5>
            <p>{alertContent}</p>
          </div>
          <Button
            color="inherit"
            onClick={handleAlertClose}
            className="cursor-pointer"
          >
            <XMarkIcon width={"20px"} />
          </Button>
        </Alert>
      )}
      <div className="flex flex-col items-start py-3">
        <div className="w-full flex sm:flex-row flex-col items-center gap-3 ">
          <Input
            multiple
            sx={{ display: "none" }}
            id="documents-upload"
            inputProps={{ multiple: true, accept: ".pdf,.doc,.docx,.txt" }}
            type="file"
            onChange={onChangeUploadFiles}
          />
          <Button
            className="flex items-center gap-3 sm:w-auto w-full text-center justify-center btn !bg-[#7a6fbe]"
            disabled={isLoading ? true : uploadFiles?.length > 0 ? false : true}
            onClick={onSubmit}
          >
            {isLoading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              <>
                <FaCloudUploadAlt className="text-xl" />
                UPLOAD
              </>
            )}
          </Button>
        </div>
      </div>
    </div>
  )
}
