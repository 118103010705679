import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  CardImg,
  Card,
  CardBody,
  Label,
  FormFeedback,
  Alert,
  Input,
  CardTitle,
} from "reactstrap"
import { Button, Box } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
// import { PhoneInput } from "react-international-phone"
import PhoneInput from "react-phone-input-2"
import Creatable from "react-select/creatable"

// Formik Validation
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import withRouter from "components/Common/withRouter"
import toast from "react-hot-toast"

// actions
import seekerLogo from "../../assets/images/seeker.png"
import { handleUploadFilesData, updateProfile } from "store/actions"
import { handleUploadLogo } from "store/bot/actions"
import AlertDeleteAccount from "./AlertDeleteAccount"
import { allExpertise, theraupatic_approaches } from "common/data/profileData"
import Miniwidget from "pages/Dashboard/Miniwidget"
import NotificationSettings from "pages/ProfilePage/NotificationSettings"

const UserProfile = () => {
  document.title = "Profile | Entheo.Pro by The Entheology Project"
  const { user } = useSelector(state => state.Login)
  const [fileName, setFileName] = useState("Change Profile")
  const [loading, setLoading] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [selectedTA, setSelectedTA] = useState(null)
  const [selectedExpertise, setSelectedExpertise] = useState(null)
  const [attributesData, setAttributesData] = useState({
    phone_number: user?.attributes?.phone_number
      ? user?.attributes?.phone_number[0]
      : "",
    expertise: user?.attributes?.expertise ? user?.attributes?.expertise : "",
    theraupatic_approaches: user?.attributes?.theraupatic_approaches
      ? user?.attributes?.theraupatic_approaches
      : "",
    files: user?.attributes?.files ? user?.attributes?.files : "",
    avatar: user?.attributes?.avatar ? user?.attributes?.avatar[0] : "",
  })
  const [previewAvatar, setPreviewAvatar] = useState("")
  const dispatch = useDispatch()

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    error: login.error,
    success: login.success,
  }))

  const { error, success } = useSelector(LoginProperties)

  const onUploadCallBack = ({ status, returnData }) => {
    if (status === "success") {
      setIsUploading(false)
      handleChangeAttributes("avatar", returnData.data.imageUrl)
      setFileName("Upload Successfully")
    } else {
      setIsUploading(false)
      console.log(returnData.message)
    }
  }

  const imageSelect = e => {
    setIsUploading(true)
    const formData = new FormData()
    formData.append("file", e.currentTarget.files[0])
    dispatch(handleUploadLogo(formData, onUploadCallBack))
    e?.currentTarget?.files[0] &&
      setPreviewAvatar(URL.createObjectURL(e.currentTarget.files[0]))
  }

  const onUploadCallBackData = ({ status, returnData }) => {
    if (status === "success") {
      setLoading(false)
      handleChangeAttributes("files", returnData.data.records?.split(", "))
      toast.success("Files uploaded successfully.")
    } else {
      setLoading(false)
      setIsUploading(false)
      console.log(returnData.message)
    }
  }

  const fileSelect = e => {
    let files = Array.prototype.slice.call(e.currentTarget.files)
    const formData = new FormData()
    files.forEach(file => {
      formData.append("books", file)
    })
    setLoading(true)
    dispatch(handleUploadFilesData(formData, onUploadCallBackData))
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      attributes: attributesData || {
        phone_number: "",
        expertise: "",
        theraupatic_approaches: "",
        files: "",
        avatar: "",
      },
    },
    onSubmit: async (values, helpers) => {
      setLoading(true)
      validation.setFieldTouched("address", true)
      validation.setFieldValue("attributes", attributesData)
      dispatch(updateProfile(values, helpers, setLoading))
    },
  })

  const onPhoneNumberChange = c => {
    setAttributesData(prev => ({ ...prev, phone_number: c }))
  }
  const handleChangeAttributes = (name, value) => {
    setAttributesData(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    const initialValues = () => {
      setPreviewAvatar(
        user?.attributes?.avatar ? user?.attributes?.avatar[0] : seekerLogo,
      )
      setSelectedTA(
        user?.attributes?.theraupatic_approaches
          ? user?.attributes?.theraupatic_approaches.map(item => ({
              label: item,
              value: item,
            }))
          : null,
      )
      setSelectedExpertise(
        user?.attributes?.expertise
          ? user?.attributes?.expertise.map(item => ({
              label: item,
              value: item,
            }))
          : null,
      )
      setAttributesData({
        phone_number: user?.attributes?.phone_number
          ? user?.attributes?.phone_number[0]
          : "",
        expertise: user?.attributes?.expertise
          ? user?.attributes?.expertise
          : "",
        theraupatic_approaches: user?.attributes?.theraupatic_approaches
          ? user?.attributes?.theraupatic_approaches
          : "",
        files: user?.attributes?.files ? user?.attributes?.files : "",
        avatar: user?.attributes?.avatar ? user?.attributes?.avatar[0] : "",
      })
    }
    user && initialValues()
  }, [user])

  function handleChangeTheraupatic(values) {
    let newArr = values?.map(obj => obj.value)
    setSelectedTA(
      newArr.map(item => ({
        label: item,
        value: item,
      })),
    )
    handleChangeAttributes("theraupatic_approaches", newArr)
  }

  function handleChangeExpertise(values) {
    let newArr = values?.map(obj => obj.value)
    setSelectedExpertise(
      newArr.map(item => ({
        label: item,
        value: item,
      })),
    )
    handleChangeAttributes("expertise", newArr)
  }

  useEffect(() => {
    ;(error || success) && setLoading(false)
  }, [error, success])

  return (
    <React.Fragment>
      {user.isAdmin && <Miniwidget />}
      <Row>
        <Col xl="8">
          <Card>
            <CardBody>
              <CardTitle className="h4 mb-4">
                Update Profile
                <hr className="mt-2" />
              </CardTitle>

              <div className="page-content p-0">
                <Container fluid>
                  {error && error ? (
                    <Alert color="danger" style={{ marginTop: "13px" }}>
                      {error}
                    </Alert>
                  ) : null}
                  {success ? (
                    <Alert color="success" style={{ marginTop: "13px" }}>
                      {success}
                    </Alert>
                  ) : null}
                  <div className="flex justify-start items-center mb-3">
                    <div className="d-flex">
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          mt: "20px",
                          mb: "10px",
                        }}
                      >
                        <CardImg
                          src={previewAvatar}
                          alt="Profile "
                          className="rounded-circle avatar-md max-w-[118px] max-h-[118px] sm:me-0 me-3"
                        />
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <Button
                            size="small"
                            color="primary"
                            variant="outlined"
                            component="label"
                            className="rounded-sm"
                          >
                            {isUploading ? <CircularProgress /> : fileName}
                            <input
                              type="file"
                              name="avatar"
                              hidden
                              onChange={imageSelect}
                            />
                          </Button>
                        </Box>
                      </Box>
                    </div>
                  </div>
                  <Row>
                    <Col xl="6">
                      <div className="form-group mb-3 ">
                        <Label className="form-label">Name</Label>
                        <Input
                          name="firstName"
                          // value={name}
                          className="form-control"
                          placeholder="Enter User Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.firstName || ""}
                          invalid={
                            validation.touched.firstName &&
                            validation.errors.firstName
                              ? true
                              : false
                          }
                        />
                        {validation.touched.firstName &&
                        validation.errors.firstName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.firstName}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xl="6">
                      <div className="form-group mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          // value={name}
                          className="form-control"
                          placeholder="Enter your valid Email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xl="6">
                      <div className="form-group mb-3">
                        <Label className="form-label">Phone number</Label>
                        <PhoneInput
                          inputStyle={{ width: "100%" }}
                          defaultCountry="us"
                          name="phone_number"
                          onBlur={validation.handleBlur}
                          onChange={onPhoneNumberChange}
                          type="text"
                          value={attributesData?.phone_number}
                        />
                        {validation.touched.attributes?.phone_number &&
                        validation.errors.attributes?.phone_number ? (
                          <FormFeedback type="invalid">
                            {validation.errors.attributes?.phone_number}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    {/* <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">Company name</Label>
                    <Input
                      name="companyName"
                      // value={name}
                      className="form-control"
                      placeholder="Enter your company name."
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.companyName || ""}
                      invalid={
                        validation.touched.companyName &&
                        validation.errors.companyName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.companyName &&
                    validation.errors.companyName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.companyName}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">Website URL</Label>
                    <Input
                      name="websiteUrl"
                      // value={name}
                      className="form-control"
                      placeholder="Enter website url."
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.websiteUrl || ""}
                      invalid={
                        validation.touched.websiteUrl &&
                        validation.errors.websiteUrl
                          ? true
                          : false
                      }
                    />
                    {validation.touched.websiteUrl &&
                    validation.errors.websiteUrl ? (
                      <FormFeedback type="invalid">
                        {validation.errors.websiteUrl}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">City</Label>
                    <Input
                      name="city"
                      // value={name}
                      className="form-control"
                      placeholder="Please provide a valid city."
                      type="text"
                      onChange={e => {
                        handleChangeAddress(e)
                      }}
                      value={addressData.city}
                    />
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">State</Label>
                    <Input
                      name="state"
                      // value={name}
                      className="form-control"
                      placeholder="Please provide a valid state."
                      type="text"
                      onChange={e => {
                        handleChangeAddress(e)
                      }}
                      value={addressData.state}
                    />
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">Zip</Label>
                    <Input
                      name="zip"
                      // value={name}
                      className="form-control"
                      placeholder="Zip Code"
                      type="text"
                      onChange={e => {
                        handleChangeAddress(e)
                      }}
                      value={addressData.zip}
                    />
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">Country</Label>
                    <Input
                      name="country"
                      // value={name}
                      className="form-control"
                      placeholder="Country"
                      type="text"
                      onChange={e => {
                        handleChangeAddress(e)
                      }}
                      value={addressData.country}
                    />
                  </div>
                </Col> */}
                    <Col xl="6">
                      <div className="form-group mb-3">
                        <Label className="form-label flex justify-between items-center">
                          <span>Theraupatic Approaches</span>
                        </Label>
                        <Creatable
                          isMulti={true}
                          name="colors"
                          value={selectedTA}
                          options={theraupatic_approaches}
                          className="basic-multi-select select2-selection"
                          onChange={v => {
                            handleChangeTheraupatic(v)
                          }}
                          classNamePrefix="select"
                        />
                      </div>
                    </Col>
                    <Col xl="6">
                      <div className="form-group mb-3">
                        <Label className="form-label flex justify-between items-center">
                          <span>Areas of Expertise</span>
                        </Label>
                        <Creatable
                          isMulti={true}
                          name="colors"
                          value={selectedExpertise}
                          options={allExpertise}
                          className="basic-multi-select select2-selection"
                          onChange={v => {
                            handleChangeExpertise(v)
                          }}
                          classNamePrefix="select"
                        />
                      </div>
                    </Col>{" "}
                    <Col xl="6">
                      <label
                        for="formFileSm"
                        class="mb-2 inline-block text-neutral-500 dark:text-neutral-400"
                      >
                        Attach Documents
                      </label>
                      <input
                        class="relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-secondary-500 bg-transparent bg-clip-padding px-3 py-[0.32rem] text-xs font-normal text-surface transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:me-3 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-e file:border-solid file:border-inherit file:bg-transparent file:px-3  file:py-[0.32rem] file:text-surface focus:border-primary focus:text-gray-700 focus:shadow-inset focus:outline-none dark:border-white/70 dark:text-white  file:dark:text-white"
                        id="formFileSm"
                        type="file"
                        multiple
                        onChange={fileSelect}
                      />
                    </Col>
                  </Row>

                  <div className="d-flex justify-content-end align-items-center">
                    <AlertDeleteAccount />
                    <form noValidate onSubmit={validation.handleSubmit}>
                      <Button
                        color="primary"
                        type="submit"
                        className="!bg-[#7a6fbe] text-white !btn !btn-primary !w-md"
                        disabled={loading}
                      >
                        Save
                      </Button>
                    </form>
                  </div>
                </Container>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl="4">
          <NotificationSettings />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
