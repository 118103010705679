import * as actionTypes from "./actionTypes"

// ** Import type

const initialState = {
  end: true,
  loading: false,
  messages: [],
  quickResponses: [],
  answerError: false,
}

const reducer = (prevState = initialState, action) => {
  switch (action.type) {
    // ** When user press enterKey or send message button
    case actionTypes.ADD_MESSAGE:
      return {
        loading: true,
        end: prevState.end,
        messages: [
          {
            sender: "human",
            message: action.payload,
          },
          ...prevState.messages,
        ],
      }

    case actionTypes.ADD_CHAT_MESSAGE:
      return {
        loading: false,
        end: prevState.end,
        messages: [
          {
            sender: "bot",
            id: action.payload?.data.messages[1].id,
            message: action.payload?.data.messages[1].content,
            feedback: action.payload?.data.messages[1].feedback,
            model: action.payload?.data.messages[1]?.llm,
            tokens: action.payload?.data.messages[1]?.tokens,
            refer: JSON.parse(
              action.payload?.data.messages[1].refer
                ? action.payload?.data.messages[1].refer
                : "[]",
            ),
            question: action.payload?.data.messages[1].question,
          },
          ...prevState.messages,
        ],
        quickResponses: action.payload?.data?.questions
          ? action.payload?.data?.questions
          : [],
      }
    // ** After fetch answer
    case actionTypes.GET_ANSWER_REQUEST_SUCCESS:
      let messages = []
      action.payload?.data?.messages.reverse().map(_item => {
        if (_item.role !== "user") {
          messages.push({
            id: _item.id,
            sender: "bot",
            feedback: _item.feedback,
            message: _item.content,
            model: _item?.llm,
            tokens: _item?.tokens,
            refer: JSON.parse(_item.refer ? _item.refer : "[]"),
            question: _item.question,
          })
        }
        return []
      })
      return {
        loading: false,
        end: action.payload?.data?.end,
        messages: [...messages, ...prevState.messages],
        quickResponses: action.payload?.data.questions
          ? action.payload?.data.questions
          : [],
      }

    case actionTypes.GET_ANSWER_REQUEST:
    case actionTypes.GET_ANSWER_AGAIN_REQUEST:
      return {
        ...prevState,
        answerError: false,
      }
    case actionTypes.GET_ANSWER_REQUEST_ERROR:
      return {
        ...prevState,
        loading: false,
        answerError: true,
      }

    case actionTypes.GET_CHAT_HISTORY_REQUEST_SUCCESS:
      let chatHistory = []
      action.payload?.data?.messages.reverse().map(_item => {
        if (_item.role !== "user") {
          chatHistory.push({
            id: _item.id,
            sender: "bot",
            message: _item.content,
            model: _item?.llm,
            tokens: _item?.tokens,
            feedback: _item.feedback,
            question: _item.question,
            refer: JSON.parse(_item.refer ? _item.refer : "[]"),
          })
        } else {
          chatHistory.push({
            id: _item.id,
            sender: "human",
            message: _item.content,
            feedback: _item.feedback,
          })
        }
        return 0
      })
      return {
        end: action.payload?.data?.end,
        quickResponses: action.payload?.data.questions
          ? action.payload?.data.questions
          : [],
        messages: [...prevState.messages, ...chatHistory],
      }

    case actionTypes.ADD_SUGGESTION_QUESTIONS:
      console.log(action.payload?.data)
      return {
        quickResponses: action.payload?.data,
      }

    case actionTypes.DELETE_CHAT_HISTORY_REQUEST_SUCCESS:
      return {
        end: true,
        messages: [],
        quickResponses: [],
      }
    case actionTypes.CLEAR_MESSAGES_HISTORY:
      return {
        end: true,
        messages: [],
        quickResponses: [],
      }
    case actionTypes.EMBEDDING_HISTORY_REQUEST_SUCCESS:
      return {
        ...prevState,
        embeddingMessage: { ...action.payload?.data },
      }
    default:
      return prevState
  }
}

export default reducer
