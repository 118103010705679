import { call, all, put, takeLatest } from "redux-saga/effects"
import * as actionTypes from "./actionTypes"

import apiClient, {
  apiClientForEntheoGpt,
  apiClientForMushGpt,
} from "../../helpers/apiClient"
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"

async function shooterGetAnswer(data) {
  return apiClientForEntheoGpt.post("/chat/message/last", data, {
    headers: { Authorization: getAccessToken() },
  })
}
async function shooterGetChatAnswer(data) {
  return apiClientForEntheoGpt.post("chat/message", data, {
    headers: { Authorization: getAccessToken() },
  })
}

function* getAnswer() {
  return yield takeLatest(actionTypes.GET_ANSWER_REQUEST, function* (action) {
    try {
      yield put({
        type: actionTypes.ADD_MESSAGE,
        payload: action.payload?.finalChat,
      })
      const data = yield call(shooterGetChatAnswer, action.payload)
      yield put({
        type: actionTypes.ADD_CHAT_MESSAGE,
        payload: {
          ...data,
        },
      })

      // yield put({
      // 	type: actionTypes.GET_ANSWER_REQUEST_SUCCESS,
      // 	payload: {
      // 		...data,
      // 	},
      // });
    } catch (error) {
      yield put({
        type: actionTypes.GET_ANSWER_REQUEST_ERROR,
      })
      console.log(error)
    }
  })
}
function* getChatAnswer() {
  return yield takeLatest(
    actionTypes.GET_CHAT_ANSWER_REQUEST,
    function* (action) {
      try {
        yield put({
          type: actionTypes.ADD_MESSAGE,
          payload: action.payload.data.messages[0].content,
        })
        yield put({
          type: actionTypes.ADD_CHAT_MESSAGE,
          payload: action.payload.data.messages[1].content,
        })
        // yield put({
        // 	type: actionTypes.GET_ANSWER_REQUEST_SUCCESS,
        // 	payload: {
        // 		...action.payload,
        // 	},
        // });
      } catch (error) {
        yield put({
          type: actionTypes.GET_ANSWER_REQUEST_ERROR,
        })
        console.log(error)
      }
    },
  )
}

function* getAnswerAgain() {
  return yield takeLatest(
    actionTypes.GET_ANSWER_AGAIN_REQUEST,
    function* (action) {
      try {
        const data = yield call(shooterGetAnswer, action.payload)
        yield put({
          type: actionTypes.GET_ANSWER_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        })
      } catch (error) {
        yield put({
          type: actionTypes.GET_ANSWER_REQUEST_ERROR,
        })
        console.log(error)
      }
    },
  )
}

async function shooterGetChatHistory(chatId) {
  return apiClientForEntheoGpt.get(`chat/message/last`, {
    headers: { Authorization: getAccessToken() },
  })
}

function* getChatHistory() {
  return yield takeLatest(
    actionTypes.GET_CHAT_HISTORY_REQUEST,
    function* (action) {
      try {
        const data = yield call(shooterGetChatHistory, action.payload)
        yield put({
          type: actionTypes.GET_CHAT_HISTORY_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        })
      } catch (error) {
        console.log(error)
      }
    },
  )
}

async function shooterDeleteChatHistory() {
  return apiClientForEntheoGpt.delete(`chat/message/all`, {
    headers: { Authorization: getAccessToken() },
  })
}

function* deleteChatHistory() {
  return yield takeLatest(
    actionTypes.DELETE_CHAT_HISTORY_REQUEST,
    function* (action) {
      try {
        const data = yield call(shooterDeleteChatHistory)
        yield put({
          type: actionTypes.DELETE_CHAT_HISTORY_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        })
        yield put({
          type: actionTypes.GET_CHAT_HISTORY_REQUEST,
          payload: "last",
        })
      } catch (error) {
        console.log(error)
      }
    },
  )
}
async function shooterEmbedding() {
  return apiClient.post(
    `/user/genEmbedding`,
    {},
    {
      headers: { Authorization: getAccessToken() },
    },
  )
}

function* Embedding() {
  return yield takeLatest(
    actionTypes.EMBEDDING_HISTORY_REQUEST,
    function* (action) {
      try {
        const data = yield call(shooterEmbedding)
        yield put({
          type: actionTypes.EMBEDDING_HISTORY_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        })
      } catch (error) {
        console.log(error)
        action.helpers.setStatus({ success: false })
        action.helpers.setErrors({
          submit: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
        })
        action.helpers.setSubmitting(false)
      }
    },
  )
}

function shooterGetMycQuickResponses() {
  return apiClient.get("/user/quick-replies/magic-myc", {
    headers: { Authorization: getAccessToken() },
  })
}

function* getMycQuickResponses() {
  return yield takeLatest(
    actionTypes.GET_MYC_QUICK_RESPONSES_REQUEST,
    function* (action) {
      try {
        const data = yield call(shooterGetMycQuickResponses)
        action.callBack({ status: "success", returnData: data })
      } catch (error) {
        console.log(error)
        action.callBack("error")
      }
    },
  )
}
export default function* runBlockingCallsExample() {
  yield all([
    getAnswer(),
    getChatHistory(),
    deleteChatHistory(),
    getChatAnswer(),
    getAnswerAgain(),
    Embedding(),
    getMycQuickResponses(),
  ])
}
