import React, { useEffect, useState } from "react"
import { Button, Tooltip, IconButton } from "@material-tailwind/react"
import { Input, Label, Modal } from "reactstrap"
import * as messages from "./../../../services/messages"
import { useMutation } from "react-query"
import http from "./../../../services/http-common"
import { FaRegEdit } from "react-icons/fa"
import { setSessionExpired, useMainController } from "context"
import { convertToTitleCase } from "lib/util"
import toast from "react-hot-toast"

function EditFaqs({ id, getFaqs }) {
  const [open, setOpen] = React.useState(false)
  const [dispatch] = useMainController()
  const [allfunctions, setAllfunctions] = React.useState(null)
  const [faqContent, setFaqContent] = useState({
    question: "",
    answer: "",
    function: "",
  })
  const [loading, setLoading] = useState(false)

  const getFaqById = useMutation(
    faqId => {
      return http.post("user/quick-replies/findById", {
        id: faqId,
      })
    },
    {
      onSuccess: ({ data }) => {
        setFaqContent(data?.quickReplies)
        setFaqContent({
          ...faqContent,
          question: data?.quickReplies?.question,
          answer: data?.quickReplies?.answer,
          function: data?.quickReplies?.function,
        })
      },
      onError: error => {
        toast.error(error.response.data.message)
      },
    },
  )
  const getFunctions = useMutation(
    () => {
      return http.get(
        "user/scheduling/fetch-intents",
        null,
        setSessionExpired,
        dispatch,
      )
    },
    {
      onSuccess: ({ data }) => {
        setAllfunctions(data?.intents)
      },
      onError: error => {
        toast.error(error.response.data.message)
      },
    },
  )

  useEffect(() => {
    getFaqById.mutate(id)
    getFunctions.mutate()
    setFaqContent({ ...faqContent, id: id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const errorFunc = error => {
    let message
    switch (error.response.status) {
      case 500: {
        message = messages.SERVER_ERROR
        break
      }
      case 404: {
        message = messages.NOT_FOUND_ERROR
        break
      }
      case 400: {
        message = messages.BAD_REQUEST
        break
      }
      default: {
        message = error.message
      }
    }
    toast.error(message)
  }

  const updateContent = useMutation(
    () => {
      return http.put(
        `user/quick-replies/update`,
        {
          id: id,
          question: faqContent.question,
          answer: faqContent.answer,
          function: faqContent.function,
        },
        null,
      )
    },
    {
      onSuccess: ({ data }) => {
        toast.success("Successfully Updated!")
        getFaqs()
        setFaqContent({
          question: "",
          answer: "",
          function: "",
        })
        handleOpen()
      },
      onError: error => {
        errorFunc(error)
      },
      onMutate: () => {
        setLoading(true)
      },
      onSettled: () => {
        setLoading(false)
      },
    },
  )

  const handleOpen = () => {
    setOpen(!open)
  }

  const onChangeContent = e => {
    if (loading) return false
    setFaqContent({ ...faqContent, [e.target.name]: e.target.value })
  }

  const onSubmit = () => {
    updateContent.mutate()
  }

  return (
    <>
      <Tooltip content="Edit">
        <IconButton variant="text" onClick={handleOpen}>
          <FaRegEdit className="h-5 w-5 text-[#7a6fbe]" />
        </IconButton>
      </Tooltip>
      <Modal
        isOpen={open}
        toggle={() => {
          handleOpen()
        }}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Update Faqs</h5>
          <button
            type="button"
            onClick={() => {
              handleOpen(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form className="mt-2 mb-2  w-auto  max-w-screen-md sm:w-full ">
            <div className="mb-1 flex flex-col gap-3 w-auto ">
              <div className="sm:w-auto md:w-full">
                <Label>Question</Label>
                <Input
                  className="w-full "
                  name="question"
                  type="textarea"
                  rows="1"
                  label="Question"
                  value={faqContent?.question}
                  onChange={onChangeContent}
                />
              </div>
              <div className="sm:w-auto md:w-full">
                <Label>Answer</Label>
                <Input
                  className="w-full "
                  name="answer"
                  rows="3"
                  type="textarea"
                  label="Answer"
                  value={faqContent?.answer}
                  onChange={onChangeContent}
                />
              </div>
              <div className="sm:w-auto md:w-full">
                <Label htmlFor="function" className="col-form-label">
                  Question's Intent
                </Label>
                <select
                  className="form-select validate"
                  name="function"
                  id="function"
                  onChange={onChangeContent}
                  value={faqContent?.function}
                  // onBlur={validation.handleBlur}
                  // value={validation.values.badgeText}
                >
                  {allfunctions &&
                    allfunctions.map(intent => (
                      <option value={intent.tag}>
                        {convertToTitleCase(intent.tag)}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </form>
          <div className="flex justify-end items-center">
            <Button
              size="sm"
              variant="tex"
              onClick={handleOpen}
              className="mr-1 bg-gray-700"
              disabled={loading}
            >
              <span>Close</span>
            </Button>
            <Button
              size="sm"
              className="bg-[#7a6fbe]"
              onClick={onSubmit}
              disabled={loading}
            >
              <span>Update</span>
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default EditFaqs
