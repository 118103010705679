import React, { useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import { getEmbeddingToken, loginByKcId } from "store/actions"

import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import UserService from "KeyCloackServices/UserService"
import { generateSessionId } from "helpers/jwt-token-access/accessToken"
import { getBotDetails } from "store/bot/actions"
const Authmiddleware = props => {
  const { user } = useSelector(state => state.Login)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isAuthenticated } = useSelector(state => state.Login)
  const [didMount, setDidMount] = useState(false)

  // useEffect(() => {
  //   setDidMount(true)
  //   !isAuthenticated && !localStorage.getItem("accessToken") && navigate("/")
  //   if (!isAuthenticated) {
  //     let accessToken = localStorage.getItem("accessToken")
  //     if (accessToken !== "undefined") {
  //       dispatch(getUserInfo(accessToken, navigate))
  //     } else {
  //       navigate("/")
  //     }
  //   }
  // }, [navigate, didMount, isAuthenticated, dispatch])

  useEffect(() => {
    setDidMount(true)
    !isAuthenticated && dispatch(loginByKcId())
    !UserService.isLoggedIn() && navigate("/")
    !localStorage.getItem("botUniqueUserId") &&
      localStorage.setItem("botUniqueUserId", generateSessionId())
    !localStorage.getItem("botAccessTokenEncoded") &&
      dispatch(getEmbeddingToken())
    user?.isBotCreated && dispatch(getBotDetails())
   
  }, [navigate, didMount, isAuthenticated, dispatch])
  if (!user) {
    return <Navigate to={{ pathname: "/", state: { from: props.location } }} />
  }
  return <React.Fragment>{props.children}</React.Fragment>
}

export default Authmiddleware
