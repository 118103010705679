import * as actionTypes from "./actionTypes"

export function getAllSubstances() {
  return {
    type: actionTypes.GET_ALL_SUBSTANCES,
  }
}

export function getAllContraindications(data) {
  return {
    type: actionTypes.GET_ALL_CONTRAINIDATIONS,
    payload: data,
  }
}

export function getAllConditions() {
  return {
    type: actionTypes.GET_ALL_CONDITIONS,
  }
}

export function getAllGroups() {
  return {
    type: actionTypes.GET_ALL_GROUPS,
  }
}
