export function textToHeaderCase(text) {
  let result = "";
  let capitalizeNext = true;

  for (let i = 0; i < text.length; i++) {
    let char = text[i];

    if (capitalizeNext) {
      if (text.slice(i, i + 3).toUpperCase() === "GPT") {
        result += "GPT";
        i += 2; // Skip the next 2 characters since we've already added "GPT"
      } else {
        result += char.toUpperCase();
      }
      capitalizeNext = false;
    } else {
      result += char.toLowerCase();
    }

    if (char === " ") {
      capitalizeNext = true;
    }
  }

  return result;
}
