import React, { useEffect, useState } from "react"

import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import InviteSeeker from "./InviteSeeker"
import toast from "react-hot-toast"
import http from "services/http-common"
import { useMutation } from "react-query"
import { useSelector } from "react-redux"
import { CircularProgress } from "@mui/material"

const Referrals = props => {
  const [allReferrals, setAllReferrals] = useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [selectedId, setSelectedId] = React.useState(null)
  const { user } = useSelector(state => state.Login)
  document.title = "Referrals | Entheo.Pro by The Entheology Project"

  const getReferrals = useMutation(
    () => {
      return user?.isAdmin
        ? http.post("user/referral/find-by-admin", {})
        : http.post("user/referral/find-by-pro", {})
    },
    {
      onSuccess: ({ data }) => {
        setAllReferrals(data.records)
      },
      onError: error => {
        toast.error(error.response.data.message)
      },
    },
  )

  const resendInvitation = useMutation(
    userId => {
      return http.post("user/referral/resend", {
        id: userId,
      })
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message)
        setIsLoading(false)
      },
      onError: error => {
        toast.error(error.response.data.message)
        setIsLoading(false)
      },
    },
  )

  const onResend = id => {
    setSelectedId(id)
    setIsLoading(true)
    resendInvitation.mutate(id)
  }

  useEffect(() => {
    user && getReferrals.mutate()
  }, [user])

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle className="h4">All Referrals </CardTitle>
              <InviteSeeker getReferrals={getReferrals} />
              {allReferrals && (
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Table
                      id="tech-companies-1"
                      className="table table-striped table-bordered"
                    >
                      <Thead>
                        <Tr>
                          {user?.isAdmin && <Th>Pro User's Email</Th>}
                          <Th>Referral Email</Th>
                          <Th data-priority="1">Screened</Th>
                          {!user?.isAdmin && <Th data-priority="3">Actions</Th>}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {allReferrals?.map(referral => (
                          <Tr>
                            {user?.isAdmin && <Th>{referral.proUsername}</Th>}
                            <Th>{referral.referralEmail}</Th>
                            <Td>{referral.hasScreened ? "Yes" : "No"}</Td>
                            {!user?.isAdmin && (
                              <Td>
                                <Button
                                  className="btn bg-[#5c636a]"
                                  onClick={() => {
                                    onResend(referral.id)
                                  }}
                                >
                                  {isLoading && selectedId === referral.id ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : (
                                    "Resend"
                                  )}
                                </Button>
                              </Td>
                            )}
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}
export default Referrals
