import React, { useEffect, useState } from "react"
import { Row, Col } from "reactstrap"
import http from "services/http-common"
import { useMutation } from "react-query"
import { Tooltip, IconButton } from "@material-tailwind/react"
import { FaTrash } from "react-icons/fa"

function Substances({ groupId }) {
  const [substances, setSubstances] = useState(null)

  const getSubstances = useMutation(
    () => {
      return http.post(
        "user/substances/findGroupById",
        {
          id: groupId,
        },
        null,
      )
    },
    {
      onSuccess: ({ data }) => {
        setSubstances(data?.records)
      },
      onError: error => {
        console.log(error)
      },
    },
  )

  const deleteSubstance = useMutation(
    async substanceId => {
      return await http.deleteGroupElement("user/substances/grouping", {
        userId: {
          id: substanceId,
        },
      })
    },
    {
      onSuccess: () => {
        getSubstances.mutate()
      },
      onError: error => {
        console.log(error)
      },
    },
  )

  const onDeleteSubstance = id => {
    deleteSubstance.mutate(id)
  }
  useEffect(() => {
    getSubstances.mutate()
  })

  return (
    <div>
      <Row>
        {substances?.substances.length > 0 && (
          <Col xl="6">
            <h4 className="card-title mb-3">Substances</h4>

            <div className="inbox-wid">
              {substances?.substances?.map(substance => (
                <div className="text-dark">
                  <div className="inbox-item">
                    <h6 className="inbox-item-author mb-1 font-size-16">
                      {substance?.substanceName}
                    </h6>

                    <p className="inbox-item-date text-muted">
                      <Tooltip content="Delete">
                        <IconButton
                          variant="text"
                          onClick={() => {
                            onDeleteSubstance(substance?.mappingId)
                          }}
                        >
                          <FaTrash color="#A02e31" className="h-4 w-4" />
                        </IconButton>
                      </Tooltip>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </Col>
        )}

        {substances?.conditions.length > 0 && (
          <Col xl="6">
            <h4 className="card-title mb-3">Conditions</h4>

            <div className="inbox-wid">
              {substances?.conditions?.map(condition => (
                <div className="text-dark">
                  <div className="inbox-item">
                    <h6 className="inbox-item-author mb-1 font-size-16">
                      {condition?.condition}
                    </h6>

                    <p className="inbox-item-date text-muted">
                      <Tooltip content="Delete">
                        <IconButton
                          variant="text"
                          onClick={() => {
                            onDeleteSubstance(condition?.mappingId)
                          }}
                        >
                          <FaTrash color="#A02e31" className="h-4 w-4" />
                        </IconButton>
                      </Tooltip>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </Col>
        )}
      </Row>
    </div>
  )
}

export default Substances
