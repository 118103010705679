import React, { useState } from "react"
import { Input } from "reactstrap"

import * as messages from "services/messages"
import { useMutation } from "react-query"
import http from "services/http-common"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import { getAllGroups } from "store/actions"

function AddGroup() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [substanceContent, setSubstanceContent] = useState({
    groupName: "",
  })

  const errorFunc = error => {
    let message
    switch (error.response.status) {
      case 500: {
        message = messages.SERVER_ERROR
        break
      }
      case 404: {
        message = messages.NOT_FOUND_ERROR
        break
      }
      case 400: {
        message = messages.BAD_REQUEST
        break
      }
      default: {
        message = error.message
      }
    }
    toast.error(message)
  }

  const onSubmit = () => {
    setLoading(true)
    createSubsctance.mutate()
  }

  const createSubsctance = useMutation(
    () => {
      return http.post(
        `user/substances/createGroup`,
        {
          groupName: [substanceContent.groupName],
        },
        null,
      )
    },
    {
      onSuccess: ({ data }) => {
        // toast.success("Successfully Added!")
        dispatch(getAllGroups())
        setSubstanceContent({
          groupName: "",
        })
        setLoading(false)
      },
      onError: error => {
        errorFunc(error)
        setLoading(false)
      },
      onMutate: () => {
        setLoading(true)
      },
      onSettled: () => {
        setLoading(false)
      },
    },
  )

  const onChangeContent = e => {
    if (loading) return false
    setSubstanceContent({
      ...substanceContent,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <>
      <div className="flex justify-end  items-center gap-2 me-2 mb-3">
        <Input
          type="text"
          name="groupName"
          className="w-full form-control"
          placeholder="Substance Group Name"
          value={substanceContent?.groupName}
          onChange={onChangeContent}
        />
        <div className="btn-toolbar form-group mb-0">
          <button
            className="btn btn-primary !w-max waves-effect waves-light"
            onClick={onSubmit}
            disabled={loading || !substanceContent?.groupName.length}
          >
            <span>Add Group</span>
          </button>
        </div>
      </div>
    </>
  )
}

export default AddGroup
