export const GET_ALL_SUBSTANCES = "GET_ALL_SUBSTANCES"
export const GET_ALL_SUBSTANCES_SUCCESS = "GET_ALL_SUBSTANCES_SUCCESS"
export const GET_ALL_CONDITIONS = "GET_ALL_CONDITIONS"
export const GET_ALL_CONDITIONS_SUCCESS = "GET_ALL_CONDITIONS_SUCCESS"
export const GET_ALL_GROUPS = "GET_ALL_GROUPS"
export const GET_ALL_GROUPS_SUCCESS = "GET_ALL_GROUPS_SUCCESS"
export const GET_ALL_CONTRAINIDATIONS = "GET_ALL_CONTRAINIDATIONS"
export const GET_ALL_CONTRAINIDATIONS_SUCCESS =
  "GET_ALL_CONTRAINIDATIONS_SUCCESS"

// Delete SUBSTANCE Component from store
export const DELETE_ALL_SUBSTANCES = "DELETE_ALL_SUBSTANCES"
export const DELETE_ALL_CONTRAINIDATIONS = "DELETE_ALL_CONTRAINIDATIONS"
