import { useEffect, useState } from "react"
import { Button } from "@material-tailwind/react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"
import { Container, Row, Col, Label, Input } from "reactstrap"
import * as messages from "services/messages"
import { useMutation } from "react-query"
import http from "services/http-common"
import { toast } from "react-hot-toast"
import { LoadingText } from "pages/Loading/Loading"

function EditMetaContent(props) {
  const { open, toogleOpenEditor, id } = props
  const [scrapedContent, setScrapedContent] = useState(null)
  const [loading, setLoading] = useState(false)

  const errorFunc = error => {
    let message
    switch (error.response.status) {
      case 500: {
        message = messages.SERVER_ERROR
        break
      }
      case 404: {
        message = messages.NOT_FOUND_ERROR
        break
      }
      case 400: {
        message = messages.BAD_REQUEST
        break
      }
      default: {
        message = error.message
      }
    }
    toast.error(message)
  }

  const saveContent = useMutation(
    id => {
      return http.put(`train/book/mine/update`, scrapedContent, null)
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message)
        toogleOpen()
      },
      onError: error => {
        errorFunc(error)
      },
      onMutate: () => {
        setLoading(true)
      },
      onSettled: () => {
        setLoading(false)
      },
    },
  )

  const toogleOpen = () => {
    if (loading) return false
    if (open) {
      setScrapedContent("")
    }
    toogleOpenEditor()
  }

  const onChangeContent = e => {
    if (loading) return false
    setScrapedContent({ ...scrapedContent, [e.target.name]: e.target.value })
  }

  const toggleDisplay = e => {
    if (loading) return false
    setScrapedContent({
      ...scrapedContent,
      [e.target.name]: !scrapedContent.private,
    })
  }

  const onSubmit = () => {
    saveContent.mutate(id)
  }

  const getDocDetails = useMutation(
    docId => {
      return http.post(`train/book/find-by-id`, { id: docId })
    },
    {
      onSuccess: ({ data }) => {
        setScrapedContent(data.doc)
      },
      onError: error => {
        errorFunc(error)
      },
      onSettled: () => {
        setLoading(false)
      },
      onMutate: () => {
        setLoading(true)
      },
    },
  )

  const getDocDetailFunc = () => {
    if (id === null) return false
    getDocDetails.mutate(id)
  }

  useEffect(() => {
    getDocDetailFunc()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      onClose={toogleOpen}
      scroll={"paper"}
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0.9, y: -100 },
      }}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Edit Document MetaData</DialogTitle>
      <DialogContent dividers={"paper"}>
        {scrapedContent ? (
          <Container>
            <Row className="justify-content-center">
              <div className="w-full flex-col justify-start items-center">
                <span className="font-bold ">FileName: </span>{" "}
                {scrapedContent?.name}
                {/* <Button
            variant="gradient"
            className="flex items-center py-2 px-3"
            type="button"
            onClick={onScrapeData}
            disabled={loading}
          >
            Scrape Now
          </Button> */}
              </div>
              <form className="mt-4 mb-2  w-auto  max-w-screen-md sm:w-96 ">
                <Col>
                  <div className="mb-3">
                    <Label htmlFor="title">Document title</Label>
                    <Input
                      type="text"
                      name="title"
                      label="Document title"
                      value={scrapedContent?.title}
                      onChange={onChangeContent}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3">
                    <Label htmlFor="author">Author(s)</Label>
                    <Input
                      type="text"
                      name="author"
                      label=" Author(s)"
                      value={scrapedContent?.author}
                      onChange={onChangeContent}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3">
                    <Label htmlFor="publication_date">Publication date</Label>
                    <Input
                      name="publication_date"
                      type="text"
                      label="Publication date"
                      value={scrapedContent?.publication_date}
                      onChange={onChangeContent}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3">
                    <Label htmlFor="doi">
                      DOI. (Digital Object Identifier)
                    </Label>
                    <Input
                      name="doi"
                      type="text"
                      label="DOI. (Digital Object Identifier)"
                      value={scrapedContent?.doi}
                      onChange={onChangeContent}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3">
                    <Label htmlFor="journal_name">Journal name</Label>
                    <Input
                      name="journal_name"
                      type="text"
                      label="Journal name"
                      value={scrapedContent?.journal_name}
                      onChange={onChangeContent}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3">
                    <Label htmlFor="abstract">Abstract</Label>
                    <Input
                      type="textarea"
                      name="abstract"
                      rows="3"
                      label="Abstract"
                      value={scrapedContent?.abstract}
                      onChange={onChangeContent}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3">
                    <Label htmlFor="notes">Notes</Label>
                    <Input
                      name="notes"
                      type="text"
                      label="Notes"
                      value={scrapedContent?.notes}
                      onChange={onChangeContent}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mb-3 sm:w-auto md:w-[35rem] flex sm:flex-row flex-col sm:gap-10 items-center">
                    <Label>Can we display this publicly?</Label>
                    <div className="flex gap-3 ">
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="private"
                          id="private"
                          onChange={toggleDisplay}
                          checked={scrapedContent?.private}
                        />
                        <label className="form-check-label" htmlFor="private">
                          Private
                        </label>
                      </div>
                      <div className="form-check ">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="private"
                          id="public"
                          onChange={toggleDisplay}
                          checked={!scrapedContent?.private}
                        />
                        <label className="form-check-label" htmlFor="public">
                          Public
                        </label>
                      </div>
                    </div>
                  </div>
                </Col>
              </form>
            </Row>
          </Container>
        ) : (
          <LoadingText count={5} />
        )}
      </DialogContent>
      <DialogActions className="space-x-2">
        <Button
          variant="text"
          size="sm"
          color="gray"
          className="mr-1 rounded-md waves-effect waves-light"
          onClick={toogleOpen}
          disabled={loading}
        >
          <span>Close</span>
        </Button>
        <Button
          size="sm"
          className="rounded-md waves-effect waves-light !bg-[#7a6fbe]"
          onClick={onSubmit}
          disabled={loading}
        >
          <span>Update</span>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditMetaContent
