import { useCallback, useEffect, useState } from "react"
import http from "../../../services/http-common"
import { setSessionExpired, useMainController } from "../../../context"
import { useMutation } from "react-query"
import { toast } from "react-hot-toast"
import { Typography, Tooltip, IconButton } from "@material-tailwind/react"
import { FaTrashAlt } from "react-icons/fa"
import EditFaqs from "./EditFaqs"
import AddBulkFaqs from "./AddBulkFaqs"
import { Divider } from "@mui/material"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import AddQuestionManually from "./AddQuestionManually"
import templateFile from "./../../../assets/faq_templates.csv"

document.title = "Faqs | Entheo.Pro by The Entheology Project"

const BotSuggestions = props => {
  const [dispatch] = useMainController()
  const [faqs, setFaqs] = useState(null)

  const getPrompt = useMutation(
    () => {
      return http.get(
        "user/quick-replies/custom-bot",
        null,
        setSessionExpired,
        dispatch,
      )
    },
    {
      onSuccess: ({ data }) => {
        setFaqs(data.quickReplies)
      },
      onError: error => {
        toast.error(error.response.data.message)
      },
    },
  )

  const getFaqs = useCallback(() => {
    getPrompt.mutate()
  }, [])

  const deleteFaq = useMutation(
    async faqId => {
      return await http.deleteFaqs("user/quick-replies/delete", {
        userId: {
          id: faqId,
        },
      })
    },
    {
      onSuccess: () => {
        toast.success("Successfully Deleted!")
        getFaqs()
      },
      onError: error => {
        toast.error(error.response.data.message)
      },
    },
  )

  const onDeleteFaq = id => {
    deleteFaq.mutate(id)
  }
  useEffect(() => {
    getFaqs()
  }, [])

  return (
    <>
      <div className="mt-4 mb-8 flex flex-col gap-12">
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <CardTitle className="h4 mb-4 flex justify-between">
                  Add Bulk Faqs (.csv file)
                  <a
                    href={templateFile}
                    className="items-center gap-3 sm:w-auto w-full text-center justify-center btn border-2 !border-[#7a6fbe] hover:border-2 hover:border-[#7a6fbe]"
                    download
                  >
                    <i className="mdi mdi-download me-1"></i>
                    Download Template
                  </a>
                </CardTitle>

                <AddBulkFaqs getFaqs={getFaqs} />
                <Divider>OR</Divider>
                <AddQuestionManually getFaqs={getFaqs} />
              </CardBody>
            </Card>
          </Col>

          {faqs && (
            <>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">All FAQs</CardTitle>
                    <table className="mt-4 w-full min-w-max table-auto text-left">
                      <tbody>
                        {faqs?.map((item, index) => {
                          return (
                            <tr key={item.id}>
                              <td
                                className={
                                  "!py-1 !px-2 border-b border-t border-blue-gray-50"
                                }
                              >
                                <div className="flex items-center gap-3">
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      <span className="font-bold me-1">
                                        Question:{" "}
                                      </span>
                                      {item.question}
                                    </Typography>
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      <span className="font-bold me-1">
                                        Answer:{" "}
                                      </span>
                                      {item.answer}
                                    </Typography>
                                  </div>
                                </div>
                              </td>
                              <td
                                className={
                                  "flex justify-end items-center !p-1 border-b border-t border-blue-gray-50 "
                                }
                              >
                                <EditFaqs id={item.id} getFaqs={getFaqs} />
                                <Tooltip content="Delete">
                                  <IconButton
                                    variant="text"
                                    onClick={() => onDeleteFaq(item.id)}
                                  >
                                    <FaTrashAlt className="h-5 w-5 text-[#ec4561]" />
                                  </IconButton>
                                </Tooltip>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              </Col>
            </>
          )}
        </Row>
      </div>
    </>
  )
}

export default BotSuggestions
