import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { getBotDetails } from "store/bot/actions"
import { useDispatch } from "react-redux"
import UserService from "KeyCloackServices/UserService"
import { TbTransform } from "react-icons/tb"

const SidebarContent = props => {
  const dispatch = useDispatch()
  const { botProfile } = useSelector(state => state.bot)
  const { user } = useSelector(state => state.Login)
  const { isCreated } = useSelector(state => state.bot)
  const ref = useRef()
  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])
  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const activeMenu = useCallback(() => {
    const pathName = process.env.PUBLIC_URL + props.router.location.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [props.router.location.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  useEffect(() => {
    user?.isBotCreated && dispatch(getBotDetails())
  }, [])

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title text-xs">{props.t("Main")} </li>
            <li>
              <Link to="/profile">
                <i className="mdi mdi-account-circle-outline !text-xl"></i>
                <span>{props.t("Profile")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-calendar-month !text-xl"></i>
                <span>{props.t("Calendar")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/working-hours">
                    <i className="mdi mdi-calendar !text-xl"></i>
                    {props.t("Working Hours")}{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/appointments">
                    <i className="mdi mdi-calendar-clock !text-xl"></i>
                    {props.t("Appointments")}
                  </Link>
                </li>
                {/* <li>
                  <Link to="/events">
                    <i className="mdi mdi-calendar !text-xl"></i>
                    {props.t("Events")}{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/media">
                    <i className="mdi mdi-calendar-check !text-xl"></i>
                    {props.t("Tasks")}{" "}
                  </Link>
                </li> */}
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-chat-processing-outline !text-xl"></i>
                <span>{props.t("My Assistant")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/my-bot">
                    <i className="mdi mdi-chat-processing-outline !text-xl"></i>
                    {props.t(
                      user?.isBotCreated ? botProfile?.botName : "Register Bot",
                    )}{" "}
                  </Link>
                </li>
                {user?.isBotCreated && (
                  <li>
                    <Link to="/personality">
                      <i className="mdi mdi-chat-plus-outline !text-xl"></i>
                      {props.t("Personality")}
                    </Link>
                  </li>
                )}

                {(user?.isBotCreated || user.role === "admin" || isCreated) && (
                  <>
                    <li>
                      <Link to="/#" className="has-arrow waves-effect">
                        <i className="mdi mdi-database-cog !text-xl"></i>
                        <span>{props.t("Training")}</span>
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to="/bot-faqs">
                            <i className="mdi mdi-chat-alert-outline !text-xl"></i>
                            {props.t("Faqs")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/documents">
                            <i className="mdi mdi-file-document-outline !text-xl"></i>
                            {props.t("My Docs")}{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to="/websites">
                            <i className="mdi mdi-web !text-xl"></i>
                            {props.t("My Websites")}
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </>
                )}
                {user?.isBotCreated && (
                  <li>
                    <Link to="/integration">
                      <i className="mdi mdi-code-tags !text-xl"></i>
                      {props.t("Integrations")}{" "}
                    </Link>
                  </li>
                )}
              </ul>
            </li>
            {/* <li>
              <Link to="/chat" className=" waves-effect ">
                <i className="typcn typcn-contacts !text-xl"></i>
                <span>{props.t("Contacts")}</span>
              </Link>
            </li> */}
            {UserService.getRole()?.roles?.includes("admin") && (
              <>
                <li>
                  <Link to="/#" className="has-arrow waves-effect ">
                    <i className="ion ion-md-settings !text-xl"></i>
                    <span>{props.t("Settings")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/system-settings">
                        <i className="mdi mdi-message-cog-outline !text-xl"></i>
                        {props.t("System Settings")}
                      </Link>
                    </li>
                    {/* <li>
                  <Link to="/intake-form">
                    <i className="mdi mdi-clipboard-flow-outline !text-xl"></i>
                    {props.t("My Process")}
                  </Link>
                </li>
                <li>
                  <Link to="/intake-form">
                    <i className="mdi mdi-form-select !text-xl"></i>
                    {props.t("My Form")}
                  </Link>
                </li>
                <li>
                  <Link to="/schedule">
                    <i className="mdi mdi-timetable !text-xl"></i>
                    {props.t("Working Days/Hours")}
                  </Link>
                </li>
                <li>
                  <Link to="/sync-calender">
                    <i className="mdi mdi-calendar-edit !text-xl"></i>
                    {props.t("Calender Sync")}
                  </Link>
                </li> */}
                  </ul>
                </li>
              </>
            )}
            <li className="menu-title text-xs">{props.t("EntheoGPT")}</li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="mdi mdi-buffer !text-xl"></i>
                <span>{props.t("EntheoGPT")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/magic-myc">
                    <i className="mdi mdi-message-processing-outline !text-xl"></i>
                    {props.t("Magic Myc")}
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-calendar-check !text-xl"></i>
                    <span>{props.t("Training")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/all-documents">
                        <i className="mdi mdi-file-document-outline !text-xl"></i>
                        {props.t("All Docs")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/all-websites">
                        <i className="mdi mdi-web !text-xl"></i>
                        {props.t("All Websites")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-file-check-outline !text-xl"></i>
                    <span>{props.t("My Research")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/my-documents">
                        <i className="mdi mdi-file-document-outline !text-xl"></i>
                        {props.t("My Docs")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/my-websites">
                        <i className="mdi mdi-web !text-xl"></i>
                        {props.t("My Websites")}
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <li>
                  <Link to="/submit-research">
                    <i className="mdi mdi-file-check-outline !text-xl"></i>
                    {props.t("Submit Research")}
                  </Link>
                </li> */}
              </ul>
            </li>
            {UserService.getRole()?.roles?.includes("admin") && (
              <li>
                <Link to="/llms" className=" waves-effect">
                  <i className="!text-xl">
                    <TbTransform className="!text-xl" />
                  </i>

                  <span>{props.t("All LLMs")}</span>
                </Link>
              </li>
            )}
            {/* {UserService.getRole()?.roles?.includes("admin") && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="dripicons-medical !text-xl"></i>
                  <span>{props.t("Contraindications")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/groups">
                      <i className="fab fa-medrt !text-xl"></i>
                      {props.t("All Groups")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/substances">
                      <i className="mdi mdi-heart-pulse !text-xl"></i>
                      {props.t("All Substances")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/conditions">
                      <i className="mdi mdi-list-status !text-xl"></i>
                      {props.t("Conditions")}
                    </Link>
                  </li>
                </ul>
              </li>
            )} */}
            {UserService.getRole()?.roles?.includes("admin") && (
              <>
                <li>
                  <Link to="/ad-manager" className="waves-effect">
                    <i className="mdi mdi-chart-line !text-xl"></i>
                    <span>{props.t("Ad Manager")}</span>
                  </Link>
                </li>
              </>
            )}{" "}
            {UserService.getRole()?.roles?.includes("admin") && (
              <li>
                <Link to="/users" className="waves-effect">
                  <i className="mdi mdi-chart-line !text-xl"></i>
                  <span>{props.t("KC Users")}</span>
                </Link>
              </li>
            )}
            <li>
              <Link to="/referrals" className="waves-effect">
                <i className=" ion ion-md-contacts !text-xl"></i>
                <span>{props.t("Referrals")}</span>
              </Link>
            </li>
            <li>
              <Link to="/pricing" className="waves-effect">
                <i className="pricion ion-md-pricetag !text-xl"></i>
                <span>{props.t("Subscription Plan")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
