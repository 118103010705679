export const GET_USER_INFO_REQUEST = "GET_USER_INFO_REQUEST"
export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGIN_BY_KC = "LOGIN_BY_KC"
export const LOGIN_BY_KC_SUCCESS = "LOGIN_BY_KC_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"
export const PROFILE_SUCCESS = "PROFILE_SUCCESS"

export const POST_GOOGLE_SIGNIN_REQUEST = "POST_GOOGLE_SIGNIN_REQUEST"
export const POST_GOOGLE_SIGNIN_REQUEST_SUCCESS =
  "POST_GOOGLE_SIGNIN_REQUEST_SUCCESS"

export const POST_RESET_PASSWORD_REQUEST = "POST_RESET_PASSWORD_REQUEST"
export const POST_RESET_PASSWORD_REQUEST_SUCCESS =
  "POST_RESET_PASSWORD_REQUEST_SUCCESS"

export const GET_EMBEDDED_TOKEN = "GET_EMBEDDED_TOKEN"
