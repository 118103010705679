import * as React from "react"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { Input, Button, Alert } from "reactstrap"
import { MdAppRegistration } from "react-icons/md"
import { registerContibuteWebsiteUrl } from "store/actions"
import CircularProgress from "@mui/material/CircularProgress"
import { XMarkIcon } from "@heroicons/react/20/solid"

export default function ContributeWebsite() {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertContent, setAlertContent] = useState("")
  const [alertStatus, setAlertStatus] = useState("success")
  const [websiteUrl, setWebsiteUrl] = useState("")

  const onRegisterCallback = ({ status, returnData }) => {
    setAlertStatus(status)
    if (status === "success") {
      // setAlertContent(returnData.data.message);
      setAlertContent(
        `Thank you very much for your document submission! We typically take 24-48 hours to review your submission before we train it. To access the Training Docs and view your submission’s status, please apply for a PRO account at https://entheo.pro. For assistance, please email admin@mushgpt.com or just ask Magic Myc!`,
      )
      setWebsiteUrl("")
    } else {
      setAlertContent(returnData.message)
    }
    setAlertOpen(true)
    setIsLoading(false)
  }

  const onSubmit = () => {
    setIsLoading(true)
    if (websiteUrl !== "") {
      dispatch(registerContibuteWebsiteUrl(websiteUrl, onRegisterCallback))
    } else {
      return false
    }
  }

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setAlertOpen(false)
  }

  const onChangeWebsiteUrl = e => {
    setWebsiteUrl(e.target.value)
  }
  return (
    <>
      <div className="w-full mb-2">
        {alertOpen && (
          <Alert
            color={alertStatus === "error" ? "danger" : "success"}
            style={{ marginTop: "13px" }}
            isOpen={alertOpen}
            dismissible
            className="flex justify-between items-center"
          >
            <div className="flex flex-col justify-start items-start">
              <h5 className="font-bold">
                {alertStatus &&
                  alertStatus[0].toUpperCase() + alertStatus.slice(1)}
              </h5>
              <p>{alertContent}</p>
            </div>
            <Button
              color="inherit"
              onClick={handleAlertClose}
              className="cursor-pointer"
            >
              <XMarkIcon width={"20px"} />
            </Button>
          </Alert>
        )}
        <div className="flex py-3 flex-col items-start">
          <div className="w-full flex sm:flex-row flex-col items-center gap-3">
            <Input
              fullWidth
              onChange={onChangeWebsiteUrl}
              value={websiteUrl}
              placeholder="Please input full URL"
            />
            <Button
              className="flex items-center gap-3 sm:w-auto w-full text-center justify-center btn !bg-[#7a6fbe]"
              disabled={
                isLoading ? true : websiteUrl?.length > 0 ? false : true
              }
              onClick={onSubmit}
            >
              {isLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <>
                  <MdAppRegistration className="text-xl" />
                  Register
                </>
              )}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
