export const GET_ANSWER_REQUEST = "GET_ANSWER_REQUEST"
export const GET_ANSWER_AGAIN_REQUEST = "GET_ANSWER_AGAIN_REQUEST"
export const GET_CHAT_HISTORY_REQUEST = "GET_CHAT_HISTORY_REQUEST"
export const DELETE_CHAT_HISTORY_REQUEST = "DELETE_CHAT_HISTORY_REQUEST"

/* MESSAGES */
export const EMBEDDING_HISTORY_REQUEST = "EMBEDDING_HISTORY_REQUEST"
export const EMBEDDING_HISTORY_REQUEST_SUCCESS =
  "EMBEDDING_HISTORY_REQUEST_SUCCESS"
export const CLEAR_MESSAGES_HISTORY = "CLEAR_MESSAGES_HISTORY"
export const GET_MYC_QUICK_RESPONSES_REQUEST = "GET_MYC_QUICK_RESPONSES_REQUEST"
export const ADD_MESSAGE = "ADD_MESSAGE"
export const ADD_CHAT_MESSAGE = "ADD_CHAT_MESSAGE"
export const POST_ADD_MESSAGE_FAIL = "POST_ADD_MESSAGE_FAIL"
export const GET_ANSWER_REQUEST_SUCCESS = "GET_ANSWER_REQUEST_SUCCESS"
export const GET_ANSWER_REQUEST_ERROR = "GET_ANSWER_REQUEST_ERROR"
export const GET_CHAT_HISTORY_REQUEST_SUCCESS =
  "GET_CHAT_HISTORY_REQUEST_SUCCESS"
export const GET_CHAT_ANSWER_REQUEST = "GET_CHAT_ANSWER_REQUEST"

// UPLOADER
export const ADD_SUGGESTION_QUESTIONS = "ADD_SUGGESTION_QUESTIONS"
export const DELETE_CHAT_HISTORY_REQUEST_SUCCESS =
  "DELETE_CHAT_HISTORY_REQUEST_SUCCESS"

// Magic Myc Bot
export const GET_CHAT_Magic_HISTORY_REQUEST = "GET_CHAT_Magic_HISTORY_REQUEST"
export const GET_CHAT_Magic_HISTORY_REQUEST_SUCCESS =
  "GET_CHAT_Magic_HISTORY_REQUEST_SUCCESS"
