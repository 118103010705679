import * as actionTypes from "./actionTypes"

export const getMessages = () => ({
  type: actionTypes.GET_MESSAGES,
})

export const getMessagesSuccess = data => ({
  type: actionTypes.GET_MESSAGES_SUCCESS,
  payload: data,
})

export const getMessagesFail = error => ({
  type: actionTypes.GET_MESSAGES_FAIL,
  payload: error,
})

export const getChatsSuccess = chats => ({
  type: actionTypes.GET_BOT_DETAILS_SUCCESS,
  payload: chats,
})

export const getChatsFail = error => ({
  type: actionTypes.GET_CHATS_FAIL,
  payload: error,
})

export const addMessage = message => ({
  type: actionTypes.POST_ADD_MESSAGE,
  message,
})

export const addMessageSuccess = message => ({
  type: actionTypes.POST_ADD_MESSAGE_SUCCESS,
  payload: message,
})

export const addMessageFail = error => ({
  type: actionTypes.POST_ADD_MESSAGE_FAIL,
  payload: error,
})

export const deleteMessage = data => ({
  type: actionTypes.DELETE_MESSAGE,
  payload: data,
})

export const deleteMessageSuccess = data => ({
  type: actionTypes.DELETE_MESSAGE_SUCCESS,
  payload: data,
})

export const deleteMessageFail = error => ({
  type: actionTypes.DELETE_MESSAGE_FAIL,
  payload: error,
})

export const uploadLogoSucess = data => ({
  type: actionTypes.UPLOAD_IMAGE_SUCCESS,
  payload: data,
})
