import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
//chat
import chat from "./chat/reducer"
import BotReducer from "./bot/reducer"
import msgReducer from "./messages/reducer"
import dataReducer from "./data/reducer"
import Contraindication from "./contraindications/reducer"

//Calendar
import calendar from "./calendar/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  //Breadcrumb items
  Login,
  Account,
  ForgetPassword,
  Profile,
  calendar,
  chat,
  data: dataReducer,
  msg: msgReducer,
  bot: BotReducer,
  Contraindication,
})

export default rootReducer
