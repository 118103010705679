import { takeEvery, put, call } from "redux-saga/effects"

// Chat Redux States
import { DELETE_MESSAGE, GET_MESSAGES, POST_ADD_MESSAGE } from "./actionTypes"
import {
  getMessagesSuccess,
  getMessagesFail,
  addMessageSuccess,
  addMessageFail,
  deleteMessageSuccess,
  deleteMessageFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getMessages,
  addMessage,
  deleteMessage,
} from "../../helpers/backend_helper"
import { toast } from "react-hot-toast"

function* onGetMessages({ uniqueId }) {
  try {
    const response = yield call(getMessages, uniqueId)
    yield put(getMessagesSuccess(response))
  } catch (error) {
    yield put(getMessagesFail(error))
  }
}

function* onAddMessage({ message }) {
  try {
    const response = yield call(addMessage, message)
    yield put(addMessageSuccess(response))
  } catch (error) {
    yield put(addMessageFail(error))
  }
}

function* OnDeleteMessage({ payload: data }) {
  try {
    const response = yield call(deleteMessage, data)
    yield put(deleteMessageSuccess(response))
    toast.success("Message Deleted Successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteMessageFail(error))
    toast.error("Message Deleted Failed", { autoClose: 2000 })
  }
}

function* chatSaga() {
  yield takeEvery(GET_MESSAGES, onGetMessages)
  yield takeEvery(POST_ADD_MESSAGE, onAddMessage)
  yield takeEvery(DELETE_MESSAGE, OnDeleteMessage)
  // yield takeLatest(UPLOAD_IMAGE_REQUEST, onUploadImage)
}

export default chatSaga
