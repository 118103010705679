import React from "react"
import "./style.css"

import thumnail from "../../assets/images/hero-thumb-1.png"

function HeroSection() {
  return (
    <>
      <section
        className={`appie-hero-area !pt-[100px] !bg-[#eef1f6] `}
        id="services"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="appie-hero-content">
                <span>Welcome To Mush.Pro</span>
                <h3 className="appie-title font-semibold">
                  Supercharge Your Coaching Practice with AI. Meet Mush.Pro!
                </h3>
                <p>
                  Mush.Pro empowers coaches to streamline administrative tasks,
                  provide ongoing client support, and gain deeper insights – all
                  while saving you precious time.
                </p>
                <ul>
                  <li>
                    <a href="#">
                      <i className="fab fa-apple" /> Download for iOS
                    </a>
                  </li>
                  <li>
                    <a className="item-2" href="#">
                      <i className="fab fa-google-play" /> Download for Android
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 sm:block hidden ">
              <div className="appie-hero-thumb flex justify-center">
                <div
                  className="thumb wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <img src={thumnail} alt="" />
                </div>
                <div
                  className="thumb-2 wow animated fadeInRight"
                  data-wow-duration="2000ms"
                  data-wow-delay="600ms"
                >
                  <img src="/assets/images/hero-thumb-2.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hero-shape-1">
          <img src="/assets/images/shape/shape-2.png" alt="" />
        </div>
        <div className="hero-shape-2">
          <img src="/assets/images/shape/shape-3.png" alt="" />
        </div>
        <div className="hero-shape-3">
          <img src="/assets/images/shape/shape-4.png" alt="" />
        </div>
      </section>
    </>
  )
}

export default HeroSection
