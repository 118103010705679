import React from "react"
import { Button } from "@material-tailwind/react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { deleteProfile } from "store/actions"
import { useNavigate } from "react-router-dom"

const AlertDeleteAccount = () => {
  const { user } = useSelector(state => state.Login)
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const navigate = useNavigate()

  const handleOpen = () => setOpen(!open)
  const handleDelete = () => {
    dispatch(deleteProfile(user.id,navigate))
  }

  return (
    <>
      <Link className="delete-password" to="#">
        <Typography
          className={"text-red-500 font-bold me-3"}
          onClick={handleOpen}
        >
          Delete Account
        </Typography>
      </Link>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleOpen}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Typography variant="h6" color="blue-gray">
            Your Attention is Required!
          </Typography>
        </DialogTitle>
        <DialogContent dividers={"paper"}>
          <DialogContentText
            className={
              "relative flex flex-col justify-center items-center gap-4 !bg-white"
            }
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="h-16 w-16 text-red-500"
            >
              <path
                fillRule="evenodd"
                d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z"
                clipRule="evenodd"
              />
            </svg>
            <Typography color="red" variant="h4">
              Warning!
            </Typography>
            <Typography className="text-center font-normal">
              Are you sure you want to permanently delete your user data?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="space-x-2">
          <Button
            size="sm"
            variant="text"
            color="blue-gray"
            onClick={handleOpen}
          >
            CANCEL
          </Button>
          <Button size="sm" className="rounded-md" onClick={handleDelete}>
            Delete Now
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AlertDeleteAccount
