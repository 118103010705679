import * as React from "react"
import ContributeDocs from "./ContributeDocs"
import ContributeWebsite from "./ContributeWebsite"
import { Col, Row, Card, CardBody, CardTitle } from "reactstrap"



const SubmitResearch = () => {
  document.title = "SubmitResearch | Entheo.Pro by The Entheology Project"

  return (
    <Row>
      <Col xl={12}>
        <Card>
          <CardBody>
            <CardTitle className="h4">Upload document(s)</CardTitle>
            <ContributeDocs />
          </CardBody>
        </Card>
      </Col>

      <Col xl={12}>
        <Card>
          <CardBody>
            <CardTitle className="h4">Register Web URLs</CardTitle>
            <ContributeWebsite />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default SubmitResearch
