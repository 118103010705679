import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react"
import { Dialog, DialogContent } from "@mui/material"
import http from "services/http-common"
import { useMutation } from "react-query"
import toast from "react-hot-toast"
import { Button, Input, Label } from "reactstrap"
import { setSessionExpired, useMainController } from "context"
import WebsiteEmbedding from "./WebsiteEmbedding"
import { Link } from "react-router-dom"
import SimpleBar from "simplebar-react"

const EmbeddingWebsite = () => {
  document.title = "Integration | Entheo.Pro by The Entheology Project"
  const [dispatch] = useMainController()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [allWebsites, setAllWebsites] = useState(null)
  const [selectedWebsite, setSelectedWebsite] = useState(null)

  const getAllWebsites = useMutation(
    () => {
      return http.get(
        "embedding/getAll/?cp=1&ps=900",
        null,
        setSessionExpired,
        dispatch,
      )
    },
    {
      onSuccess: ({ data }) => {
        setAllWebsites(data?.records)
        setSelectedWebsite(data?.records[0])
      },
      onError: error => {
        toast.error(error?.response?.data?.message)
      },
    },
  )

  const handleOpen = () => setOpen(cur => !cur)
  const [content, setContent] = useState({
    webName: "",
    webUrl: "",
  })

  const addWebsite = useMutation(
    () => {
      return http.post(
        `embedding/create`,
        content,
        null,
        setSessionExpired,
        dispatch,
      )
    },
    {
      onSuccess: ({ data }) => {
        toast.success("Successfully Added!")
        setContent({
          webName: "",
          webUrl: "",
        })
        getAllWebsites.mutate()
        handleOpen()
      },
      onError: error => {
        toast.error(error?.response?.data?.message)
        setContent({
          webName: "",
          webUrl: "",
        })
        handleOpen()
      },
      onMutate: () => {
        setLoading(true)
      },
      onSettled: () => {
        setLoading(false)
      },
    },
  )

  const handleChange = e => {
    setContent({ ...content, [e.target.name]: e.target.value })
  }

  const onSubmit = () => {
    addWebsite.mutate()
  }
  useEffect(() => {
    getAllWebsites.mutate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <Button
        size="sm"
        color="primary"
        className="!w-auto rounded-md btn-block !bg-[#7a6fbe]"
        onClick={handleOpen}
      >
        <i className="mdi mdi-plus-circle-outline" /> Add Website
      </Button>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={"xs"}
        onClose={handleOpen}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent dividers={true} className="!p-0">
          <Card className="mx-auto w-full shadow-none ">
            <CardBody className="flex flex-col gap-3">
              <Typography variant="h6" color="blue-gray">
                Add New Website
              </Typography>
              <hr />
              <div className=" position-relative">
                <Label htmlFor="siteName">Site name</Label>
                <Input
                  type="text"
                  name="webName"
                  className="form-control"
                  id="siteName"
                  value={content.webName}
                  placeholder="Website Name"
                  onChange={e => {
                    handleChange(e)
                  }}
                />
              </div>
              <div className="position-relative">
                <Label htmlFor="domain">Domain</Label>
                <Input
                  type="text"
                  name="webUrl"
                  className="form-control"
                  id="domain"
                  placeholder="https://example.com/"
                  value={content.webUrl}
                  onChange={e => {
                    handleChange(e)
                  }}
                />
              </div>
            </CardBody>
            <CardFooter className="pt-0">
              <Button
                size="sm"
                color="primary"
                className="!w-full rounded-md btn-block !bg-[#7a6fbe]"
                onClick={() => {
                  onSubmit()
                }}
                fullWidth
                disabled={loading}
              >
                Add Website
              </Button>
            </CardFooter>
          </Card>
        </DialogContent>
      </Dialog>

      <div className="d-lg-flex my-3">
        <Card className="chat-leftsidebar !min-w-[450px]">
          <SimpleBar>
            <div className="pt-3">
              <ul className="list-unstyled chat-list p-3">
                <SimpleBar>
                  {allWebsites &&
                    allWebsites?.map((website, idx) => (
                      <li
                        key={idx}
                        className={`${
                          website.id === selectedWebsite?.id
                            ? "active !bg-[#a8a1d5] !rounded-md"
                            : " !bg-[#f5f5f5] !rounded-md"
                        } my-2`}
                        onClick={() => setSelectedWebsite(website)}
                      >
                        <Link to="#">
                          <div className="d-flex align-items-center">
                            <div className="flex-grow-1 overflow-hidden">
                              <h5 className="text-truncate font-bold font-size-15 mb-0">
                                {website.webUrl}
                              </h5>
                              <p className="text-muted mb-0 mt-1 !normal-case text-gray">
                                Api Key: {website.apiKey}
                              </p>
                            </div>
                          </div>
                        </Link>
                      </li>
                    ))}
                </SimpleBar>
              </ul>
            </div>
          </SimpleBar>
        </Card>

        <WebsiteEmbedding websiteId={selectedWebsite?.id} />
      </div>
    </>
  )
}

export default EmbeddingWebsite
