/* CHATS */
export const REGISTER_BOT_REQUEST = "REGISTER_BOT_REQUEST"
export const GET_BOT_DETAILS = "GET_BOT_DETAILS"
export const GET_BOT_DETAILS_SUCCESS = "GET_BOT_DETAILS_SUCCESS"
export const UPDATE_BOT_REQUEST = "UPDATE_BOT_REQUEST"
export const UPDATE_BOT_REQUEST_SUCCESS = "UPDATE_BOT_REQUEST_SUCCESS"
export const UPDATE_BOT_MYC_REQUEST = "UPDATE_BOT_MYC_REQUEST"
export const UPDATE_BOT_MYC_REQUEST_SUCCESS = "UPDATE_BOT_MYC_REQUEST_SUCCESS"
export const GET_BOT_NEW_CHAT_HISTORY_REQUEST =
  "GET_BOT_NEW_CHAT_HISTORY_REQUEST"
export const GET_BOT_NEW_CHAT_HISTORY_SUCCESS =
  "GET_BOT_NEW_CHAT_HISTORY_SUCCESS"
export const GET_BOT_CHAT_HISTORY_REQUEST = "GET_BOT_CHAT_HISTORY_REQUEST"
export const GET_BOT_DATA_REQUEST = "GET_BOT_DATA_REQUEST"
export const GET_BOT_DETAILS_REQUEST = "GET_BOT_DETAILS_REQUEST"
export const GET_BOT_DATA_MYC_REQUEST = "GET_BOT_DATA_MYC_REQUEST"
export const GET_BOT_DATA_MYC_REQUEST_SUCCESS =
  "GET_BOT_DATA_MYC_REQUEST_SUCCESS"
export const GET_BOT_ANSWER_REQUEST = "GET_BOT_ANSWER_REQUEST"
export const GET_BOT_ANSWER_AGAIN_REQUEST = "GET_BOT_ANSWER_AGAIN_REQUEST"
export const GET_ANSWER_REQUEST_ERROR = "GET_ANSWER_REQUEST_ERROR"
export const DELETE_BOT_CHAT_HISTORY_REQUEST = "DELETE_BOT_CHAT_HISTORY_REQUEST"
export const CLEAR_BOT_MESSAGES_HISTORY = "CLEAR_BOT_MESSAGES_HISTORY"
export const GET_BOT_QUICK_RESPONSES_REQUEST = "GET_BOT_QUICK_RESPONSES_REQUEST"
export const UPGRADE_TO_PRO_REQUEST = "UPGRADE_TO_PRO_REQUEST"
export const UPLOAD_IMAGE_REQUEST = "UPLOAD_IMAGE_REQUEST"

export const EMBEDDING_HISTORY_REQUEST = "EMBEDDING_HISTORY_REQUEST"
export const EMBEDDING_HISTORY_REQUEST_SUCCESS =
  "EMBEDDING_HISTORY_REQUEST_SUCCESS"

export const ADD_CHAT_BOT_MESSAGE = "ADD_CHAT_BOT_MESSAGE"
export const REGISTER_BOT_REQUEST_SUCCESS = "REGISTER_BOT_REQUEST_SUCCESS"
export const GET_BOT_DATA_REQUEST_SUCCESS = "GET_BOT_DATA_REQUEST_SUCCESS"
export const GET_BOT_DETAILS_REQUEST_SUCCESS = "GET_BOT_DETAILS_REQUEST_SUCCESS"
export const ADD_BOT_MESSAGE = "ADD_BOT_MESSAGE"
export const GET_BOT_DATA_MYC_SUCCESS = "GET_BOT_DATA_MYC_SUCCESS"
export const GET_BOT_CHAT_HISTORY_REQUEST_SUCCESS =
  "GET_BOT_CHAT_HISTORY_REQUEST_SUCCESS"
export const GET_BOT_ANSWER_REQUEST_SUCCESS = "GET_BOT_ANSWER_REQUEST_SUCCESS"
export const GET_ANSWER_AGAIN_REQUEST = "GET_ANSWER_AGAIN_REQUEST"
export const GET_BOT_ANSWER_REQUEST_ERROR = "GET_BOT_ANSWER_REQUEST_ERROR"
export const DELETE_BOT_CHAT_HISTORY_REQUEST_SUCCESS =
  "DELETE_BOT_CHAT_HISTORY_REQUEST_SUCCESS"
