import React, { useCallback, useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  CardTitle,
} from "reactstrap"
import { Button } from "@mui/material"
import withRouter from "components/Common/withRouter"
import http from "services/http-common"
import { setSessionExpired, useMainController } from "context"
import toast from "react-hot-toast"
import { useMutation } from "react-query"

const NotificationSettings = () => {
  document.title = "Profile | Entheo.Pro by The Entheology Project"
  const [dispatch] = useMainController()
  const [loading, setLoading] = useState(false)
  const [settings, setSettings] = useState({
    emailNotification: null,
    smsNotification: null,
    proId: "",
  })

  const getPrompt = useMutation(
    () => {
      return http.get(
        "user/notifications/setting/fetch",
        null,
        setSessionExpired,
        dispatch,
      )
    },
    {
      onSuccess: ({ data }) => {
        setSettings(prev => ({
          ...prev,
          emailNotification: data.records.emailNotification,
          smsNotification: data.records.smsNotification,
          proId: data.records.proId,
        }))
      },
      onError: error => {
        toast.error(error.response.data.message)
      },
    },
  )

  const getFaqs = useCallback(() => {
    getPrompt.mutate()
  }, [])

  const saveSettings = useMutation(
    async () => {
      return await http.put("user/notifications/setting/update", {
        emailNotification: settings.emailNotification,
        smsNotification: settings.smsNotification,
        proId: settings.proId,
      })
    },
    {
      onSuccess: () => {
        toast.success("Successfully Saved!")
        getFaqs()
      },
      onError: error => {
        toast.error(error)
      },
    },
  )

  const handleChange = (e, toggle) => {
    console.log(e.target.name, toggle)
    setSettings(prev => ({
      ...prev,
      [e.target.name]: toggle,
    }))
  }

  const handleSubmit = () => {
    saveSettings.mutate()
  }

  useEffect(() => {
    getFaqs()
  }, [])
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="h4 mb-4">
            Notification Settings
            <hr className="mt-2" />
          </CardTitle>
          <Row>
            <Col xl="12">
              <div className="form-check form-switch form-switch-lg mb-3 !pl-0 flex justify-between items-center  ">
                <Label className="form-label" htmlFor="customSwitchsizelg1">
                  Email notifications
                </Label>
                <input
                  name="emailNotification"
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizelg1"
                  onChange={e => {
                    handleChange(e, !settings.emailNotification)
                  }}
                  defaultChecked={settings.emailNotification}
                  value={settings.emailNotification}
                />
              </div>
            </Col>
            <Col xl="12">
              <div className="form-check form-switch form-switch-lg mb-3 !pl-0 flex justify-between items-center  ">
                <Label className="form-label" htmlFor="customSwitchsizelg2">
                  Sms notifications
                </Label>
                <input
                  name="smsNotification"
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchsizelg2"
                  onChange={e => {
                    handleChange(e, !settings.smsNotification)
                  }}
                  defaultChecked={settings.smsNotification}
                  value={settings.smsNotification}
                />
              </div>
            </Col>
          </Row>
          <div className="page-content p-0">
            <Container fluid>
              <div className="d-flex justify-content-end align-items-center">
                <Button
                  onClick={handleSubmit}
                  color="primary"
                  type="submit"
                  className="!bg-[#7a6fbe] text-white !normal-case !btn !btn-primary !w-md"
                  disabled={loading}
                >
                  Save Changings
                </Button>
              </div>
            </Container>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default withRouter(NotificationSettings)
