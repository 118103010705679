import {
  Button,
  Checkbox,
  IconButton,
  Input,
  Popover,
  PopoverHandler,
  PopoverContent,
  Radio,
  Typography,
} from "@material-tailwind/react"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import SimplePagiNation from "../pagination/simplePagination"
import _ from "lodash"
import {
  FolderMinusIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline"
import { FaFilter, FaSort, FaSortDown, FaSortUp } from "react-icons/fa"

import { useForm } from "react-hook-form"

function Table(props) {
  const {
    tableHeaders,
    data,
    headerPosition,
    bodyPosition,
    loading,
    pagination,
    paginationPosition,
    checkable,
    actions,
    onChangeCheckValues,
    checkData,
    onChangePagination,
    onChangeFilter,
    filters,
    onChangeSorter,
    sorters,
  } = props
  const { handleSubmit, register, resetField, setValue } = useForm({
    mode: "onSubmit",
  })

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(
    pagination.pageSizeKind && pagination.pageSizeKind.length > 0
      ? pagination.pageSizeKind[0].value
      : 10,
  )

  const [totalPage, setTotalPage] = useState(10000)
  const [checkValues, setCheckValues] = useState(checkData)
  const [allCheck, setAllCheck] = useState(false)
  const [filterValues, setFilterValues] = useState(filters || {})
  const [sorterValues, setSorterValues] = useState(sorters || {})

  useEffect(() => {
    tableHeaders.forEach(({ key, filter }) => {
      filters[key] && filter.type === "search" && setValue(key, filters[key])
    })
  }, []) // eslint-disable-next-line react-hooks/exhaustive-deps
  const tableData = useMemo(() => {
    if (onChangePagination) return data
    return pagination !== false
      ? data.slice(pageSize * (currentPage - 1), pageSize * currentPage)
      : data
  }, [data, currentPage, pageSize]) // eslint-disable-next-line react-hooks/exhaustive-deps

  const onChangePage = (cp, ps) => {
    if (cp !== currentPage) {
      setCurrentPage(cp)
    }
    if (ps !== pageSize) {
      setPageSize(ps)
    }
    onChangePagination && onChangePagination(cp, ps)
  }

  useEffect(() => {
    setTotalPage(Math.ceil(data.length / pageSize))
  }, [data, pageSize]) // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentPage > Math.ceil(data.length / pageSize)) {
      setCurrentPage(Math.max(1, Math.ceil(data.length / pageSize)))
    }
  }, [data]) // eslint-disable-next-line react-hooks/exhaustive-deps

  const paginationProps = useMemo(() => {
    if (typeof pagination === "boolean") {
      return {}
    } else {
      return {
        ...pagination,
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(pagination)])

  const onRowClick = () => {}

  const onChangeCheckButton = e => {
    if (e.target.checked) {
      setCheckValues([...checkValues, e.target.value])
    } else {
      setCheckValues(checkValues.filter(el => el !== e.target.value))
    }
  }

  const onChangeAllCheck = e => {
    if (tableData.length === 0) return false
    setAllCheck(e.target.checked)
    if (e.target.checked) {
      const data = tableData.map(el => el.key.toString())
      setCheckValues(_.uniq([...checkValues, ...data]))
    } else {
      setCheckValues(
        _.remove(checkValues, function (val) {
          return tableData.findIndex(el => el.key.toString() === val) === -1
        }),
      )
    }
  }

  const onRestCheck = () => {
    setCheckValues(
      _.remove(checkValues, function (val) {
        return tableData.findIndex(el => el.key.toString() === val) === -1
      }),
    )
  }

  useEffect(() => {
    if (tableData.length !== 0 && checkValues) {
      setAllCheck(true)
      for (let i = 0; i < tableData.length; i++) {
        if (
          checkValues.findIndex(el => el === tableData[i].key.toString()) === -1
        ) {
          setAllCheck(false)
          break
        }
      }
      onChangeCheckValues && onChangeCheckValues(checkValues)
    } else {
      setAllCheck(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkValues, tableData])

  useEffect(() => {
    setCheckValues(checkData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkData])

  const pageCheckCount = useMemo(() => {
    return tableData.filter(el => {
      return _.includes(checkValues, el.key.toString())
    }).length
  }, [tableData, checkValues])

  const onChangeFilterFunc = e => {
    let filterData = filterValues
    switch (e.target.type) {
      case "checkbox": {
        let checkVals = filterValues[e.target.name] || []
        if (e.target.checked) {
          checkVals.push(e.target.value)
        } else {
          // const evens = _.remove(checkVals, function (n) {
          //   return n === e.target.value;
          // });
        }
        filterData[e.target.name] = checkVals
        break
      }
      case "radio":
      default: {
        filterData[e.target.name] = e.target.value
        break
      }
    }
    setFilterValues({ ...filterData })
  }

  const onResetFilter = name => {
    let filterData = filterValues
    delete filterData[name]
    setFilterValues({ ...filterData })
  }

  useEffect(() => {
    onChangeFilter && onChangeFilter(filterValues)
  }, [JSON.stringify(filterValues)]) // eslint-disable-next-line react-hooks/exhaustive-deps

  const onSubmit = data => {
    setFilterValues(val => {
      return { ...val, ...data }
    })
  }

  const filterComponent = useCallback(
    (key, filter) => {
      if (filter.type) {
        switch (filter.type) {
          case "radio": {
            return (
              <>
                {filter.kinds.map((kind, index) => {
                  return (
                    <div key={`${kind.nam}-${index}`} className="p-0">
                      <label
                        htmlFor={kind.name}
                        className="flex cursor-pointer items-center gap-4 p-2"
                      >
                        <Radio
                          id={kind.name}
                          value={kind.value}
                          name={key}
                          onChange={onChangeFilterFunc}
                          checked={filterValues[key] === kind.value.toString()}
                          containerProps={{ className: "p-0" }}
                          className="hover:before:content-none"
                        />
                        <Typography className="">{kind.name}</Typography>
                      </label>
                    </div>
                  )
                })}
                <div>
                  <Button
                    className="w-full"
                    onClick={() => {
                      onResetFilter(key)
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </>
            )
          }
          case "check": {
            return (
              <>
                {filter.kinds.map((kind, index) => {
                  return (
                    <div key={`${kind.nam}-${index}`} className="p-0">
                      <label
                        htmlFor={kind.name}
                        className="flex cursor-pointer items-center gap-4 p-2"
                      >
                        <Checkbox
                          id={kind.name}
                          value={kind.value}
                          name={key}
                          onChange={onChangeFilterFunc}
                          checked={
                            filterValues[key]
                              ? filterValues[key].includes(
                                  kind.value.toString(),
                                )
                              : false
                          }
                          containerProps={{ className: "p-0" }}
                          className="hover:before:content-none"
                        />
                        <Typography className="">{kind.name}</Typography>
                      </label>
                    </div>
                  )
                })}
                <div>
                  <Button
                    className="w-full"
                    onClick={() => {
                      onResetFilter(key)
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </>
            )
          }
          case "search": {
            return (
              <>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="relative flex"
                >
                  <IconButton
                    size="md"
                    className="rounded-r-none"
                    onClick={() => {
                      onResetFilter(key)
                      resetField(key)
                    }}
                  >
                    <i className="fas fa-refresh" />
                  </IconButton>
                  <Input
                    className="rounded-l-none rounded-r-none !border-t-blue-gray-200 focus:border-r-0 focus:!border-t-blue-500"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    containerProps={{
                      className: "min-w-0",
                    }}
                    placeholder="Search"
                    {...register(key)}
                  />
                  <IconButton
                    type="submit"
                    variant="filled"
                    className="rounded-l-none"
                  >
                    <MagnifyingGlassIcon className="h-4 w-4" />
                  </IconButton>
                </form>
              </>
            )
          }
          default: {
          }
        }
      } else {
        return filter
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [filterValues],
  )

  const onChangeSorterFunc = key => {
    let sortersData = sorterValues
    if (sortersData[key]) {
      if (sortersData[key].desc === true) {
        sortersData[key].desc = false
      } else {
        delete sortersData[key]
      }
    } else {
      sortersData[key] = { desc: true }
    }
    console.log(sortersData)
    setSorterValues({ ...sortersData })
  }

  useEffect(() => {
    onChangeSorter && onChangeSorter(sorterValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorterValues])

  return (
    <div className="relative w-full">
      {pagination &&
      (paginationPosition === "top" || paginationPosition === "both") ? (
        <div className="flex w-full justify-end p-5 text-center">
          <SimplePagiNation
            onChangePage={onChangePage}
            totalPage={totalPage}
            currentPage={currentPage}
            pageSize={pageSize}
            {...paginationProps}
          />
        </div>
      ) : null}
      {checkable ? (
        <div className="flex flex-col justify-between px-4 md:flex-row md:items-center md:px-7">
          <div className="flex items-center">
            {!allCheck && pageCheckCount > 0 ? (
              <IconButton
                onClick={onRestCheck}
                variant="text"
                className="rounded-full"
              >
                <FolderMinusIcon strokeWidth={2} className="h-5 w-5" />
              </IconButton>
            ) : (
              <Checkbox
                checked={allCheck}
                onChange={onChangeAllCheck}
                id="allSelect"
                name="allSelect"
              />
            )}
            {checkValues.length > 0 ? actions : null}
          </div>
          <div className="pt-2 md:pt-0">
            {checkValues.length} items selected
          </div>
        </div>
      ) : null}
      <div className="overflow-auto">
        <form>
          <table className="mb-5 w-full min-w-[1080px] table-auto">
            <colgroup>
              {checkable ? <col></col> : null}
              {tableHeaders.map(({ width, key, colClassName }) => {
                return (
                  <col
                    width={width}
                    key={key}
                    className={colClassName ? colClassName : ""}
                  ></col>
                )
              })}
            </colgroup>
            <thead>
              <tr>
                {checkable ? (
                  <th
                    className={`text border-b border-blue-gray-50 py-3  ${headerPosition}`}
                  ></th>
                ) : null}
                {tableHeaders.map(({ name, filter, key, sorter }) => (
                  <th
                    key={key}
                    className={`relative border-b border-blue-gray-50 py-3  ${
                      "text-" + headerPosition
                    }`}
                  >
                    <Typography
                      variant="small"
                      className="text-[11px] font-bold uppercase text-blue-gray-400"
                    >
                      {name}
                    </Typography>
                    <div className="!absolute right-0 top-3 flex gap-1">
                      {filter && (
                        <Popover
                          placement={"bottom-end"}
                          animate={{
                            mount: { y: 0 },
                            unmount: { y: 25 },
                          }}
                          dismiss={{
                            itemPress: false,
                          }}
                        >
                          <PopoverHandler className="h-4 w-4">
                            <IconButton
                              variant="text"
                              className="rounded-full"
                              color="blue-gray"
                            >
                              <FaFilter className="h-3 w-3" />
                            </IconButton>
                          </PopoverHandler>
                          <PopoverContent className="z-[9999] flex flex-col gap-1">
                            {filterComponent(key, filter)}
                          </PopoverContent>
                        </Popover>
                      )}
                      {sorter && (
                        <IconButton
                          variant="text"
                          className="h-4 w-4 rounded-full"
                          color="blue-gray"
                          onClick={() => onChangeSorterFunc(key)}
                        >
                          {sorterValues && sorterValues[key] ? (
                            sorterValues[key].desc === true ? (
                              <FaSortUp width={"20px"} />
                            ) : (
                              <FaSortDown width={"20px"} />
                            )
                          ) : (
                            <FaSort width={"20px"} />
                          )}
                        </IconButton>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => {
                const className = `p-3 ${
                  index === data.length - 1
                    ? ""
                    : "border-b border-blue-gray-50"
                }`

                return (
                  <tr
                    key={index}
                    onClick={onRowClick}
                    className={"text-" + bodyPosition}
                  >
                    {checkable ? (
                      <td className={className}>
                        <Checkbox
                          name="checkButton"
                          id={`checkButton_${item.key}`}
                          value={item.key}
                          onChange={onChangeCheckButton}
                          checked={
                            checkValues.findIndex(
                              el => el === item.key.toString(),
                            ) !== -1
                          }
                        />
                      </td>
                    ) : null}
                    {tableHeaders.map(td => {
                      return (
                        <td key={td.key} className={className}>
                          {td.default ? td.default(item.key) : item[td.key]}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </form>
      </div>
      {data.length === 0 ? (
        <div className="w-full p-5 text-center">
          <Typography
            variant="small"
            className="text-[20px] font-bold uppercase text-blue-gray-400"
          >
            No data
          </Typography>
        </div>
      ) : null}
      {pagination &&
      (paginationPosition === "bottom" || paginationPosition === "both") ? (
        <div className="flex w-full justify-end p-5 text-center">
          <SimplePagiNation
            onChangePage={onChangePage}
            totalPage={totalPage}
            currentPage={currentPage}
            pageSize={pageSize}
            {...paginationProps}
          />
        </div>
      ) : null}
      {loading ? (
        <div className="absolute left-0 top-0 h-full w-full bg-[#0b0c0c5e]">
          <img
            className="absolute left-[calc(50%-15px)] top-[calc(50%-15px)] h-[30px] w-[30px]"
            src="/img/loading.gif"
            alt={"Loading"}
          />
        </div>
      ) : null}
    </div>
  )
}

Table.defaultProps = {
  tableHeaders: [],
  data: [],
  headerPosition: "center",
  bodyPosition: "center",
  loading: false,
  pagination: true,
  paginationPosition: "bottom",
  checkable: false,
  filters: {},
  sorters: {},
}

export default Table
