import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Chip,
  Button,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Input,
  Tooltip,
  IconButton,
  Progress,
} from "@material-tailwind/react"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-hot-toast"
import http from "../../../services/http-common"
import {
  CloudArrowUpIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline"
import { useMainController, setSessionExpired } from "../../../context"
import { formatDate } from "../../../lib/util"
import Table from "../../widgets/table"
import * as messages from "../../../services/messages"
import { FaCheckDouble, FaLock, FaUnlock, FaTrashAlt } from "react-icons/fa"
import EditMetaContent from "./EditMetaContent"

const MyAllDocs = () => {
  document.title = "My Documents | Entheo.Pro by The Entheology Project"
  const [dispatch] = useMainController()
  const queryClient = useQueryClient()
  const [contentId, setContentId] = useState(null)
  const [editorOpen, setEditorOpen] = useState(false)

  const cp = queryClient.getQueryData("books-cp")
  const ps = queryClient.getQueryData("books-ps")
  const filters = queryClient.getQueryData("books-filters")
  const sorters = queryClient.getQueryData("books-sorters")

  const { register, handleSubmit, reset } = useForm({
    mode: "onSubmit",
  })
  const [books, setBooks] = useState([])
  const [checkValues, setCheckValues] = useState([])
  const [currentPage, setCurrentPage] = useState(cp || 1)
  const [pageSize, setPageSize] = useState(ps || 50)
  const [uploadPercentage, setUploadPercentage] = useState(0)
  const [totalPage, setTotalPage] = useState(10000)
  const [filterValues, setFilterValues] = useState(filters || {})
  const [sorterValues, setSorterValues] = useState(
    sorters || { updated_at: { desc: true } },
  )

  useQuery("books-cp", () => currentPage)
  useQuery("books-ps", () => pageSize)
  useQuery("books-filters", () => filterValues)
  useQuery("books-sorters", () => sorterValues)

  const tableHeaders = [
    {
      key: "name",
      name: "name",
      // filter: {
      //   type: "search",
      // },
      sorter: true,
    },
    {
      key: "author",
      name: " Author(s)",
      width: "30%",
      // filter: {
      //   type: "search",
      // },
      sorter: true,
    },
    {
      key: "status",
      name: "status",
      width: "10%",
      filter: {
        type: "check",
        kinds: [
          { name: "ready", value: "ready" },
          { name: "pending", value: "pending" },
          { name: "failed", value: "failed" },
          { name: "done", value: "done" },
        ],
      },
      sorter: true,
    },
    {
      key: "private",
      name: "private",
      width: "10%",
      // filter: {
      //   type: "radio",
      //   kinds: [
      //     { name: "private", value: 1 },
      //     { name: "public", value: 0 },
      //   ],
      // },
      sorter: true,
    },
    { key: "updated_at", name: "update at", sorter: true },
    {
      key: "actions",
      name: "",
      default: id => (
        <Menu
          animate={{
            mount: { y: 0 },
            unmount: { y: 25 },
          }}
        >
          <MenuHandler>
            <Button size="sm" className="bg-[#7a6fbe] rounded-md">
              Action
            </Button>
          </MenuHandler>
          <MenuList>
            <MenuItem
              onClick={() => {
                handleTrain(id)
              }}
            >
              Train
            </MenuItem>
            <MenuItem
              onClick={() => {
                updatePrivate(id)
              }}
            >
              {/*{books.find((book) => book.id === id).private === true*/}
              {/* eslint eqeqeq: 0 */}
              {books.find(book => book.id === id).private?.props?.value ==
              "private"
                ? "Make public"
                : "Make private"}
            </MenuItem>
            <MenuItem
              onClick={() => {
                deleteFunc(id)
              }}
            >
              Delete
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleEdit(id)
              }}
            >
              Edit Meta Data
            </MenuItem>
          </MenuList>
        </Menu>
      ),
    },
  ]

  const errorFunc = error => {
    let message
    switch (error.response.status) {
      case 500: {
        message = error.response.data.message || messages.SERVER_ERROR
        break
      }
      case 404: {
        message = messages.NOT_FOUND_ERROR
        break
      }
      case 400: {
        message = messages.BAD_REQUEST
        break
      }
      default: {
        message = error.message
      }
    }
    toast.error(message)
  }

  const { isError, error, data } = useQuery(
    ["books", currentPage, pageSize, filterValues, sorterValues],
    () => {
      return http.get(
        `train/book/mine?cp=${currentPage}&ps=${pageSize}${
          JSON.stringify(filterValues) !== "{}"
            ? `&filters=${JSON.stringify(filterValues)}`
            : ""
        }${
          JSON.stringify(sorterValues) !== "{}"
            ? `&sorters=${JSON.stringify(sorterValues)}`
            : ""
        }`,
        null,
        setSessionExpired,
        dispatch,
      )
    },
    {
      keepPreviousData: true,
      refetchInterval: 5000,
      queryKey: ["books", currentPage, pageSize], // Unique key for the query
      staleTime: 30000, // Keep data in cache for 30 seconds
      retryOnMount: true,
    },
  )

  useEffect(() => {
    if (isError) {
      console.log(error)
      toast.error("Fetch data failed")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  useEffect(() => {
    if (data && data.data) {
      const { docs, pageSize, total } = data.data
      const newDocs = docs.map(doc => {
        return {
          ...doc,
          key: doc.id,
          name: (
            <Tooltip
              content={doc.name}
              animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0, y: 25 },
              }}
            >
              <a href={doc.url} target="_blank" rel="noreferrer">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-semibold"
                >
                  {doc.name.length > 39
                    ? doc.name.slice(0, 39) + "..."
                    : doc.name}
                </Typography>
              </a>
            </Tooltip>
          ),
          author: (
            <Tooltip
              content={doc?.author}
              animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0, y: 25 },
              }}
            >
              <Typography
                variant="small"
                color="blue-gray"
                className="font-semibold"
              >
                {doc?.author.split(",").length > 2
                  ? doc?.author.slice(0, 30) + "..."
                  : doc?.author}
              </Typography>
            </Tooltip>
          ),
          updated_at: (
            <Typography
              variant="small"
              color="blue-gray"
              className="font-semibold"
            >
              {formatDate(doc.updated_at)}
            </Typography>
          ),
          status: (
            <Chip
              variant="gradient"
              color={status(doc.status)}
              value={doc.status}
              className="py-0.5 px-2 text-[11px] font-medium"
            />
          ),
          private: (
            <Chip
              variant="gradient"
              color={doc.private ? "red" : "blue"}
              value={doc.private ? "private" : "public"}
              className="py-0.5 px-2 text-[11px] font-medium"
            />
          ),
        }
      })
      setBooks(newDocs)

      setTotalPage(Math.ceil(total / pageSize))
    }
  }, [data])

  const options = {
    onUploadProgress: progressEvent => {
      const { loaded, total } = progressEvent
      let percent = Math.floor((loaded * 100) / total)
      // console.log(`${loaded}kb of ${total}kb | ${percent}%`)

      if (percent < 100) {
        setUploadPercentage(percent)
      } else {
        setUploadPercentage(0)
      }
    },
  }

  const fileUpload = useMutation(
    async file => {
      return http.filepost(
        `train/book`,
        file,
        options,
        setSessionExpired,
        dispatch,
      )
    },
    {
      onSuccess: ({ data }) => {
        data.message === "Only 0 files uploaded"
          ? toast.error("This file is already uploaded.")
          : toast.success(data.message)

        reset()
      },
      onError: error => {
        errorFunc(error)
      },
    },
  )

  const onSubmit = data => {
    if (data.books.length !== 0) {
      const formData = new FormData()
      for (let i = 0; i < data.books.length; i++) {
        formData.append("books", data.books[i])
      }
      fileUpload.mutate(formData)
    } else {
      toast.error("Please select any file")
    }
  }

  const toogleOpenEditor = () => {
    if (editorOpen === true) {
      setContentId(null)
    }
    setEditorOpen(!editorOpen)
  }

  const handleEdit = id => {
    setContentId(id)
    toogleOpenEditor()
  }

  const startTrain = useMutation(
    id => {
      return http.post(
        `train/book/${id}/train`,
        {},
        null,
        setSessionExpired,
        dispatch,
      )
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message)
      },
      onError: error => {
        errorFunc(error)
      },
    },
  )

  const handleTrain = id => {
    const val = data.data.docs.find(book => book.id === id)
    if (val.status == "done") {
      toast.success("Already trained")
      return
    } else if (val.status == "pending") {
      toast.success("Training Now")
      return
    }
    startTrain.mutate(val.id)
  }

  const changePrivate = useMutation(
    val => {
      return http.put(
        `train/book/${val.id}/private`,
        { private: val.private === true ? "public" : "private" },
        null,
      )
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message)
      },
      onError: error => {
        errorFunc(error)
      },
    },
  )

  const updatePrivate = id => {
    const val = data.data.docs.find(book => book.id === id)
    changePrivate.mutate(val)
  }
  const deleteData = useMutation(
    id => {
      return http.delete(`train/book/${id}`, null)
    },
    {
      onSuccess: () => {
        toast.success("Successfully Deleted")
      },
      onError: error => {
        errorFunc(error)
      },
    },
  )

  const deleteFunc = id => {
    deleteData.mutate(id)
  }

  const status = value => {
    switch (value) {
      case "ready": {
        return "blue"
      }
      case "pending": {
        return "purple"
      }
      case "done": {
        return "green"
      }
      case "failed": {
        return "red"
      }
      default: {
      }
    }
  }

  const bulkTrainRequest = useMutation(
    () => {
      return http.post(
        "train/book/bulk/train",
        { ids: checkValues },
        null,
        setSessionExpired,
        dispatch,
      )
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message)
        setCheckValues([])
      },
      onError: error => {
        errorFunc(error)
      },
    },
  )
  const bulkTrain = () => {
    bulkTrainRequest.mutate()
  }

  const bulkPrivateRequest = useMutation(
    val => {
      return http.put(
        "train/book/bulk/private",
        { private: val, ids: checkValues },
        null,
      )
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message)
        setCheckValues([])
      },
      onError: error => {
        errorFunc(error)
      },
    },
  )
  const bulkPrivate = () => {
    bulkPrivateRequest.mutate("private")
  }
  const bulkPublic = () => {
    bulkPrivateRequest.mutate("public")
  }
  const bulkDeleteRequst = useMutation(
    () => {
      return http.post(
        "train/book/bulk/delete",
        { ids: checkValues },
        null,
        setSessionExpired,
        dispatch,
      )
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message)
        setCheckValues([])
      },
      onError: error => {
        errorFunc(error)
      },
    },
  )
  const bulkDelete = () => {
    bulkDeleteRequst.mutate()
  }

  const actions = (
    <>
      <Tooltip content="train">
        <IconButton
          variant="text"
          className="rounded-full"
          color="blue"
          onClick={bulkTrain}
        >
          <FaCheckDouble />
        </IconButton>
      </Tooltip>
      <Tooltip content="private">
        <IconButton
          variant="text"
          className="rounded-full"
          color="blue"
          onClick={bulkPrivate}
        >
          <FaLock />
        </IconButton>
      </Tooltip>
      <Tooltip content="public">
        <IconButton
          variant="text"
          className="rounded-full"
          color="blue"
          onClick={bulkPublic}
        >
          <FaUnlock />
        </IconButton>
      </Tooltip>
      <Tooltip content="delete">
        <IconButton
          variant="text"
          className="rounded-full"
          color="red"
          onClick={bulkDelete}
        >
          <FaTrashAlt />
        </IconButton>
      </Tooltip>
    </>
  )

  const onChangeCheckValues = values => {
    setCheckValues(values)
  }

  const onChangePagination = (cp, ps) => {
    setCurrentPage(cp)
    setPageSize(ps)

    queryClient.setQueryData("books-cp", cp)
    queryClient.setQueryData("books-ps", ps)
  }

  const onChangeFilter = filterValues => {
    setFilterValues(filterValues)
    queryClient.setQueryData("books-filters", { ...filterValues })
  }

  const onChangeSorter = sorterValues => {
    setSorterValues({ ...sorterValues })
    queryClient.setQueryData("books-sorters", { ...sorterValues })
  }

  const onSearchFileName = data => {
    setFilterValues(vals => {
      return {
        ...vals,
        name: data.name,
      }
    })
  }

  return (
    <>
      <div className="mt-4 mb-8 flex flex-col gap-12">
        <Card>
          <CardHeader variant="gradient" className="mb-8 p-6 bg-[#887bd9]">
            <Typography variant="h6" color="white">
              Train Docs
            </Typography>
          </CardHeader>

          <CardBody className="overflow-x-auto px-2 pt-0 pb-2">
            <form onSubmit={handleSubmit(onSubmit)} className=" p-4">
              <Typography color="gray" variant="h6">
                Upload Documents
              </Typography>
              <div className="flex h-full w-full flex-row items-center gap-6 ">
                <input
                  className="border-neutral-300 text-neutral-700 file:bg-neutral-100 file:text-neutral-700 hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary relative m-0 block w-full min-w-0 flex-auto rounded border border-solid bg-clip-padding px-3 py-[0.32rem] text-base font-normal transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:px-3 file:py-[0.32rem] file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] focus:outline-none"
                  type="file"
                  id="formFile"
                  name="books"
                  {...register("books")}
                  multiple
                />
                <Button
                  className="flex items-center gap-3 py-2 px-3 bg-[#7a6fbe]"
                  type="submit"
                  disabled={fileUpload.isLoading}
                >
                  <CloudArrowUpIcon strokeWidth={2} className="h-5 w-5" />{" "}
                  Upload
                </Button>
              </div>
              {uploadPercentage > 0 && uploadPercentage < 100 && (
                <Progress
                  value={uploadPercentage}
                  active
                  label={`Completed`}
                  className="my-3"
                />
              )}
            </form>
            <div className="flex w-full justify-end py-1">
              <form onSubmit={handleSubmit(onSearchFileName)}>
                <div className="flex w-full justify-end py-1 pe-3">
                  <div className="flex w-72">
                    <Input
                      icon={<MagnifyingGlassIcon className="h-4 w-4" />}
                      className=" !border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/10 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      containerProps={{
                        className: "min-w-0",
                      }}
                      placeholder="Search"
                      {...register("name")}
                    />
                  </div>
                </div>
              </form>
            </div>
            <Table
              tableHeaders={tableHeaders}
              data={books}
              paginationPosition="both"
              pagination={{
                pageSizeKind: [
                  { name: "50 items per page", value: 50 },
                  { name: "100 items per page", value: 100 },
                  { name: "150 items per page", value: 150 },
                  { name: "200 items per page", value: 200 },
                ],
                totalPage: totalPage,
                currentPage: currentPage,
                pageSize: pageSize,
              }}
              checkable
              actions={actions}
              onChangeCheckValues={onChangeCheckValues}
              checkData={checkValues}
              onChangePagination={onChangePagination}
              onChangeFilter={onChangeFilter}
              filters={filterValues}
              onChangeSorter={onChangeSorter}
              sorters={sorterValues}
            />
          </CardBody>
        </Card>
      </div>

      <EditMetaContent
        toogleOpenEditor={toogleOpenEditor}
        id={contentId}
        open={editorOpen}
      />
    </>
  )
}
export default MyAllDocs
