import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Logout from "../pages/Authentication/Logout"
// import Register from "../pages/Authentication/Register"
// import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Inner Authentication
// import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
// import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"

// Dashboard
import MyAllDocs from "../pages/Pro/myDcuments/my-all-docs"
import TrainWebsites from "../pages/Pro/Train Websites/train-websites"
import BotSuggestions from "../pages/Pro/Suggestions/BotSuggestions"
import PagesUnderMaintenance from "../pages/ExtraPages/pages-under-maintenance"
import ProChat from "../pages/PersonalityChat/ProChat"
import TrainWebContent from "pages/train/trainWebContent"
import { TrainBook, TrainBookPro } from "pages/train"
import EmbeddingWebsite from "pages/Integration/EmbeddingWebsite"
import Calendar from "pages/Calendar"
import TrainWebContentPro from "pages/train/trainWebContentPro"
import SubmitResearch from "pages/SubmitResearch/SubmitResearch"
// import ResetPassword from "pages/Authentication/ResetPassword"
import MagicMycChat from "../pages/MagicMycChat/MagicMycChat"
import AllContraindication from "pages/All Contraindications/Contraindication/AllContraindication"
import AllGroups from "pages/All Contraindications/Groups/AllGroups"
import AllConditions from "pages/All Contraindications/Conditions/AllConditions"
// import Welcome from "pages/Welcome"
import BotUpdates from "pages/PersonalityChat/BotUpdates"
import GreetingMessageEditor from "pages/SystemGreeting/greetingEditor"
import Home from "pages/Home/Home"
import SubscriptionPlan from "pages/Subscription Plan/SubscriptionPlan"
import AllLLMs from "pages/AllLLMs/AllLLMs"
import Referrals from "pages/Referals/Referrals"
import Availability from "pages/Calendar/Availability"
import Alerts from "pages/Alerts/Alerts"
import { MyDocs } from "pages/SubmitResearch/My Reseach"
import MyWebsites from "pages/SubmitResearch/My Reseach/MyWebsites"

const userRoutes = [
  // { path: "/dashboard", component: <Dashboard /> },
  { path: "/my-bot", component: <ProChat /> },
  { path: "/personality", component: <BotUpdates /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/documents", component: <MyAllDocs /> },
  { path: "/websites", component: <TrainWebsites /> },
  { path: "/bot-faqs", component: <BotSuggestions /> },
  { path: "/integration", component: <EmbeddingWebsite /> },
  { path: "/appointments", component: <Calendar /> },
  { path: "/events", component: <PagesUnderMaintenance /> },
  { path: "/working-hours", component: <Availability /> },
  { path: "/submit-research", component: <SubmitResearch /> },
  { path: "/magic-myc", component: <MagicMycChat /> },
  { path: "/pricing", component: <SubscriptionPlan /> },
  { path: "/system-settings", component: <GreetingMessageEditor /> },
  // { path: "/chat", component: <Chat /> },
  { path: "/contact", component: <PagesUnderMaintenance /> },
  { path: "/media", component: <PagesUnderMaintenance /> },
  { path: "/all-documents", component: <TrainBook /> },
  { path: "/all-websites", component: <TrainWebContent /> },
  // LLMs
  { path: "/llms", component: <AllLLMs /> },

  { path: "/intake-form", component: <PagesUnderMaintenance /> },
  { path: "/groups", component: <AllGroups /> },
  { path: "/substances", component: <AllContraindication /> },
  { path: "/conditions", component: <AllConditions /> },
  { path: "/ad-manager", component: <PagesUnderMaintenance /> },
  { path: "/referrals", component: <Referrals /> },
  { path: "/alerts", component: <Alerts /> },
  { path: "*", component: <h2>404 - Page Not Found.</h2> },
  // this route should be at the end of all other routes
  {
    path: "/dashboard",
    exact: true,
    component: <Navigate to="/magic-myc" />,
  },
]
const proUserRoutes = [
  // { path: "/dashboard", component: <Dashboard /> },
  { path: "/my-bot", component: <ProChat /> },
  { path: "/personality", component: <BotUpdates /> },
  { path: "/profile", component: <UserProfile /> },
  // Pro Users Routes
  { path: "/documents", component: <MyAllDocs /> },
  { path: "/websites", component: <TrainWebsites /> },
  { path: "/bot-faqs", component: <BotSuggestions /> },
  { path: "/integration", component: <EmbeddingWebsite /> },
  { path: "/appointments", component: <Calendar /> },
  { path: "/working-hours", component: <Availability /> },
  { path: "/events", component: <PagesUnderMaintenance /> },
  { path: "/submit-research", component: <SubmitResearch /> },
  { path: "/my-documents", component: <MyDocs /> },
  { path: "/my-websites", component: <MyWebsites /> },
  { path: "/magic-myc", component: <MagicMycChat /> },
  { path: "/pricing", component: <SubscriptionPlan /> },
  // { path: "/chat", component: <Chat /> },
  { path: "/contact", component: <PagesUnderMaintenance /> },
  { path: "/media", component: <PagesUnderMaintenance /> },
  { path: "/all-documents", component: <TrainBookPro /> },
  { path: "/all-websites", component: <TrainWebContentPro /> },

  { path: "/intake-form", component: <PagesUnderMaintenance /> },
  { path: "/ad-manager", component: <PagesUnderMaintenance /> },
  { path: "/referrals", component: <Referrals /> },
  { path: "/alerts", component: <Alerts /> },
  { path: "*", component: <h2>404 - Page Not Found.</h2> },
  // this route should be at the end of all other routes
  {
    path: "/dashboard",
    exact: true,
    component: <Navigate to="/magic-myc" />,
  },
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/", component: <Home /> },
  // { path: "/login", component: <Welcome /> },
  // { path: "/login", component: <Login /> },
  // { path: "/forgot-password", component: <ForgetPwd /> },
  // { path: "/reset-password", component: <ResetPassword /> },
  // { path: "/signup", component: <Register /> },

  // Authentication Inner
  // { path: "/page-recoverpw", component: <Recoverpw /> },
  // { path: "/auth-lock-screen", component: <LockScreen /> },
]

export { userRoutes, authRoutes, proUserRoutes }
