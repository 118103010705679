import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import { useDispatch } from "react-redux"
import {
  Card,
  CardBody,
  Input,
  Row,
  Alert,
  CardImg,
  Col,
  Label,
  FormFeedback,
} from "reactstrap"
import { XMarkIcon } from "@heroicons/react/20/solid"
import CircularProgress from "@mui/material/CircularProgress"
import seekerLogo from "../../assets/images/seeker.png"
import { Button, Box } from "@mui/material"
import { useSelector } from "react-redux"
import { getBotDetails, handleUploadLogo, updateBot } from "store/bot/actions"
import { createSelector } from "reselect"
import ReactQuill from "react-quill"
import { CiEdit } from "react-icons/ci"
import Chip from "@mui/material/Chip"
import Creatable from "react-select/creatable"
import { allExpertise, theraupatic_approaches } from "common/data/profileData"

const optionGroup = [
  { label: "Friendly", value: "friendly" },
  { label: "Formal", value: "formal" },
  { label: "Casual", value: "casual" },
  { label: "Witty", value: "witty" },
  { label: "Compassionate", value: "compassionate" },
]
document.title = "Pesonality | Entheo.Pro by The Entheology Project"

const BotUpdates = () => {
  const dispatch = useDispatch()
  const { botProfile } = useSelector(state => state.bot)
  const [fileName, setFileName] = useState("Change Logo")
  const [isUploading, setIsUploading] = useState(false)
  const [editTitles, setEditTitles] = useState(false)
  const [editExpertise, setEditExpertise] = useState(false)
  const [previewAvatar, setPreviewAvatar] = useState(
    botProfile?.iconUrl ? botProfile?.iconUrl : seekerLogo,
  )
  const [visible, setVisible] = useState(true)

  const onDismiss = () => setVisible(false)

  useEffect(() => {
    dispatch(getBotDetails())
  }, [])

  const selectLoginState = state => state.bot
  const BotProperties = createSelector(selectLoginState, bot => ({
    error: bot.error,
    success: bot.success,
  }))

  const { error, success } = useSelector(BotProperties)

  useEffect(() => {
    botProfile?.iconUrl &&
      setPreviewAvatar(botProfile?.iconUrl ? botProfile?.iconUrl : seekerLogo)
  }, [botProfile])
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: botProfile?.id || "",
      footerNote: botProfile?.footerNote || "",
      botName: botProfile?.botName,
      primaryDomain: botProfile?.primaryDomain,
      iconUrl: botProfile?.iconUrl,
      greeting: botProfile?.greeting,
      tone: botProfile?.tone || "",
      additionalCertification: botProfile?.additionalCertification || "",
      specialInstruction: botProfile?.specialInstruction || "",
      theraupatic_approaches: [botProfile.theraupatic_approaches] || "",
      expertise: [botProfile.expertise] || "",
      userId: botProfile?.userId || "",
      submit: null,
    },
    onSubmit: async (values, helpers) => {
      dispatch(updateBot(values, helpers))
      setFileName("Upload Logo")
    },
  })

  const onChangePrompt = html => {
    validation.setFieldTouched("footerNote", true)
    validation.setFieldValue("footerNote", html)
  }

  const onUploadCallBack = ({ status, returnData }) => {
    if (status === "success") {
      setIsUploading(false)
      validation.setFieldTouched("iconUrl", true)
      validation.setFieldValue("iconUrl", returnData.data.imageUrl)
      setFileName("Upload Successfully")
    } else {
      setIsUploading(false)
      console.log(returnData.message)
    }
  }
  const imageSelect = e => {
    setIsUploading(true)
    const formData = new FormData()
    formData.append("file", e.currentTarget.files[0])
    dispatch(handleUploadLogo(formData, onUploadCallBack))
    e?.currentTarget?.files[0] &&
      setPreviewAvatar(URL.createObjectURL(e.currentTarget.files[0]))
  }

  function handleChangeTheraupatic(values) {
    let newArr = values?.map(obj => obj.value)
    validation.setFieldTouched("theraupatic_approaches", true)
    validation.setFieldValue("theraupatic_approaches", newArr)
  }

  function handleChangeExpertise(values) {
    let newArr = values?.map(obj => obj.value)
    validation.setFieldTouched("expertise", true)
    validation.setFieldValue("expertise", newArr)
  }

  const handleToggle = value => {
    value === "title" && setEditTitles(!editTitles)
    value === "expertise" && setEditExpertise(!editExpertise)
  }

  return (
    <>
      <div className="flex-flex-col">
        <div className="w-full user-chat mt-4 mt-sm-0 ">
          {error && error ? (
            <Alert
              color="danger"
              style={{ marginTop: "13px" }}
              isOpen={visible}
              dismissible
              className="flex justify-between items-center"
            >
              {error}
              <Button
                color="inherit"
                onClick={onDismiss}
                className="cursor-pointer"
              >
                <XMarkIcon width={"20px"} />
              </Button>
            </Alert>
          ) : null}
          {success ? (
            <Alert
              color="success"
              style={{ marginTop: "13px" }}
              isOpen={visible}
              dismissible
              className="flex justify-between items-center"
            >
              {success}
              <Button
                color="inherit"
                onClick={onDismiss}
                className="cursor-pointer"
              >
                <XMarkIcon width={"20px"} />
              </Button>
            </Alert>
          ) : null}
          <Card>
            <CardBody>
              <div className="flex justify-start items-center mb-3">
                <div className="d-flex">
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      mt: "20px",
                      mb: "10px",
                    }}
                  >
                    <CardImg
                      src={previewAvatar}
                      alt="Profile Pic"
                      className="rounded-circle avatar-md max-w-[118px] max-h-[118px] sm:me-0 me-3"
                    />
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        size="small"
                        color="primary"
                        variant="outlined"
                        component="label"
                        className="rounded-sm"
                      >
                        {isUploading ? <CircularProgress /> : fileName}
                        <input
                          type="file"
                          name="avatar"
                          hidden
                          onChange={imageSelect}
                        />
                      </Button>
                    </Box>
                  </Box>
                </div>
              </div>
              <Row>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">Assistant’s Name</Label>
                    <Input
                      name="botName"
                      // value={name}
                      className="form-control "
                      placeholder="Your bot’s name"
                      type="textarea"
                      row="1"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.botName || ""}
                      invalid={
                        validation.touched.botName && validation.errors.botName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.botName && validation.errors.botName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.botName}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">About the Coach</Label>
                    <Input
                      name="primaryDomain"
                      // value={name}
                      className="form-control "
                      placeholder="Expert in mushroom microdosing, plant medicines, kambo, somatic therapy, and ibogaine."
                      type="textarea"
                      row="1"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.primaryDomain || ""}
                      invalid={
                        validation.touched.primaryDomain &&
                        validation.errors.primaryDomain
                          ? true
                          : false
                      }
                    />
                    {validation.touched.primaryDomain &&
                    validation.errors.primaryDomain ? (
                      <FormFeedback type="invalid">
                        {validation.errors.primaryDomain}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">List all credentials</Label>
                    <Input
                      name="additionalCertification"
                      // value={name}
                      className="form-control "
                      placeholder="Tripsitter, Trauma informed therapist, MFT."
                      type="textarea"
                      row="1"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.additionalCertification || ""}
                      invalid={
                        validation.touched.additionalCertification &&
                        validation.errors.additionalCertification
                          ? true
                          : false
                      }
                    />
                    {validation.touched.additionalCertification &&
                    validation.errors.additionalCertification ? (
                      <FormFeedback type="invalid">
                        {validation.errors.additionalCertification}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">Instructions</Label>
                    <Input
                      name="specialInstruction"
                      // value={name}
                      className="form-control "
                      placeholder="Please do not share private information. For contact info or appointments, just ask!"
                      type="textarea"
                      row="1"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.specialInstruction || ""}
                      invalid={
                        validation.touched.specialInstruction &&
                        validation.errors.specialInstruction
                          ? true
                          : false
                      }
                    />
                    {validation.touched.specialInstruction &&
                    validation.errors.specialInstruction ? (
                      <FormFeedback type="invalid">
                        {validation.errors.specialInstruction}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">Tone/Personality</Label>
                    <Input
                      classNames={"max-h-[100px]"}
                      id="tone"
                      name="tone"
                      type="textarea"
                      placeholder="You are a warm, witty and friendly educator who is succinct and compassionate."
                      row="1"
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      value={validation.values.tone}
                      error={Boolean(
                        validation.touched.tone && validation.errors.tone,
                      )}
                      invalid={
                        validation.touched.tone && validation.errors.tone
                          ? true
                          : false
                      }
                      className="w-full"
                    />
                    {validation.touched.tone && validation.errors.tone ? (
                      <FormFeedback type="invalid">
                        {validation.errors.tone}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">Greeting</Label>
                    <Input
                      name="greeting"
                      // value={name}
                      className="form-control "
                      placeholder="How can I help you, Seeker?"
                      type="textarea"
                      row="1"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.greeting || ""}
                      invalid={
                        validation.touched.greeting &&
                        validation.errors.greeting
                          ? true
                          : false
                      }
                    />
                    {validation.touched.greeting &&
                    validation.errors.greeting ? (
                      <FormFeedback type="invalid">
                        {validation.errors.greeting}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label flex justify-between items-center">
                      <span>Theraupatic Approaches</span>
                      <CiEdit
                        className="text-xl cursor-pointer"
                        onClick={() => {
                          handleToggle("title")
                        }}
                      />
                    </Label>
                    {editTitles ? (
                      <Creatable
                        isMulti={true}
                        name="colors"
                        options={theraupatic_approaches}
                        className="basic-multi-select select2-selection"
                        onChange={v => {
                          handleChangeTheraupatic(v)
                        }}
                        classNamePrefix="select"
                      />
                    ) : (
                      <div>
                        {botProfile?.theraupatic_approaches
                          ?.split(", ")
                          .map(title => (
                            <Chip
                              label={title}
                              size="small"
                              variant="outlined"
                              className="mx-1 r"
                              sx={{
                                borderRadius: "5px",
                              }}
                            />
                          ))}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label flex justify-between items-center">
                      <span>Areas of Expertise</span>
                      <CiEdit
                        className="text-xl cursor-pointer"
                        onClick={() => {
                          handleToggle("expertise")
                        }}
                      />
                    </Label>
                    {editExpertise ? (
                      <Creatable
                        isMulti={true}
                        name="colors"
                        options={allExpertise}
                        className="basic-multi-select select2-selection"
                        onChange={v => {
                          handleChangeExpertise(v)
                        }}
                        classNamePrefix="select"
                      />
                    ) : (
                      <div>
                        {botProfile?.expertise?.split(",").map(title => (
                          <Chip
                            label={title}
                            size="small"
                            variant="outlined"
                            className="mx-1"
                            sx={{
                              borderRadius: "5px",
                            }}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </Col>{" "}
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">Footer Note</Label>
                    <ReactQuill
                      theme="snow"
                      className={`mb-5 w-auto max-h-[300px] text-black `}
                      value={validation.values.footerNote || ""}
                      onChange={onChangePrompt}
                    />
                    {validation.touched.greeting &&
                    validation.errors.greeting ? (
                      <FormFeedback type="invalid">
                        {validation.errors.greeting}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <div className="d-flex justify-content-end align-items-center">
                <form noValidate onSubmit={validation.handleSubmit}>
                  <Button
                    size="small"
                    color="primary"
                    type="submit"
                    className="!bg-[#7a6fbe] text-white !btn !btn-primary !w-md"
                  >
                    Save
                  </Button>
                </form>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  )
}

export default BotUpdates
