import { useEffect, useMemo, useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import parse from "html-react-parser"
import { Row, Col } from "reactstrap"
import { Avatar } from "@material-tailwind/react"
import { IconButton, Tooltip } from "@mui/material"
import MagicMycIcon from "../../assets/images/chatIcon.png"
import ThinkingIcon from "../../assets/images/THINKING-animated.gif"

import seekerIcon from "../../assets/images/seeker.png"
// ** Import MUI
import { Box, Chip } from "@mui/material"
import { FaRegCopy } from "react-icons/fa6"
import { useSelector } from "react-redux"
import ShowSources from "./showSources"
import ResponseFeedback from "./ResponseFeedback"
import FormattedResponse from "lib/FormattedResponse"
import { textToHeaderCase } from "lib/TextToHeaderCase"

let isBotResponse = false

export const HumanMessage = ({ message }) => {
  const { user } = useSelector(state => state.Login)
  return (
    <>
      <Row className="flex justify-end my-[13px ] sm:pe-10px me-1 conversation-list my-2 ">
        <Col
          xs={12}
          className="flex sm:flex-row flex-col justify-end sm:items-start items-end  sm:w-auto w-[94%] "
        >
          <div className=" sm:w-auto sm:me-3 mt-2 me-0 w-full  ">
            <div className=" py-[10px] px-[10px] rounded-[8px_8px_0px_8px] break-words text-white text-start bg-[#7a6fbe] ">
              <div class="ctext-wrap">
                <div class="ctext-wrap-content">
                  <p class="mb-0 text-base text-white">{parse(message)}</p>
                </div>
              </div>
            </div>
            <div className="triangle-down-human"></div>
          </div>
          <Avatar
            alt="Seeker"
            src={user.avatar ? user.avatar : seekerIcon}
            className="rounded-sm my-2 sm:me-0 me-[-18px] sm:ms-0 ms-2"
            sx={{ width: 62, height: 62 }}
          />
        </Col>
      </Row>
    </>
  )
}

export const BotMessage = ({
  showSuggestions,
  questionId,
  feedback,
  message,
  model,
  tokens,
  refer,
  question,
  messageIndex,
}) => {
  const [clipboardText, setClipboardText] = useState("Click to copy")
  const [references] = useState("")
  const [currentWord, setCurrentWord] = useState(0)
  const [isComplete, setIsComplete] = useState(false)
  const { user } = useSelector(state => state.Login)

  const HandleTooltipClose = () => {
    setTimeout(() => {
      setClipboardText("Click to copy")
    }, 200)
  }
  useEffect(() => {
    if (currentWord >= message.length) {
      setIsComplete(true)
      return
    }
    let currentIndex = 0
    const interval = setInterval(() => {
      setCurrentWord(
        prevWord =>
          prevWord +
          message.split(" ")[currentIndex].length +
          message.split(" ")[currentIndex - 1].length,
      )
      currentIndex++
    }, 50)

    return () => clearInterval(interval)
  }, [currentWord, message])

  const getReferences = () => {
    let refers = ""
    refer &&
      refer.length > 0 &&
      refer.map(_item => {
        return (refers = refers + _item.url + "\n")
      })

    return refer?.length > 0 ? `References : \n${refers}` : ""
  }
  const copy = async () => {
    await navigator.clipboard.writeText(message + "\n" + getReferences())
    // alert('Text copied');
  }

  return (
    <Row className="flex justify-start my-2 sm:pl-10px me-1 conversation-list ">
      <Col
        xs={12}
        className="flex sm:flex-row flex-col-reverse justify-start sm:items-end items-start sm:w-auto w-full    "
      >
        <Avatar
          size="lg"
          alt="Magic Myc"
          src={MagicMycIcon}
          className="max-w-[50px] !rounded-none max-h-[50px] sm:mt-2  me-[-17px] "
        />
        <div className=" sm:ml-6 ml-3 w-full ">
          <div className="text-start  px-4 py-2.5 rounded-[8px_8px_8px_0px] bg-[#f2f2f7] ">
            <div class="ctext-wrap">
              <div class="ctext-wrap-content">
                <p class="mb-0 text-base">
                  {
                    isBotResponse && messageIndex == 0 && !showSuggestions ? (
                      <FormattedResponse
                        response={message.substring(0, currentWord)}
                      />
                    ) : (
                      //  parse(
                      //     message
                      //       .substring(0, currentWord)
                      //       .replace(/\n/g, "<br>"),
                      //   )
                      <FormattedResponse response={message} />
                    )
                    // parse(message.replace(/\n/g, "<br>"))
                  }
                  {isBotResponse && messageIndex == 0 && !isComplete && (
                    <span
                      style={{
                        marginLeft: "5px",
                      }}
                    >
                      {/* ... */}
                    </span>
                  )}
                </p>
              </div>
            </div>

            {/* eslint eqeqeq: 0 */}

            {refer && refer.length > 0 ? (
              <div className="flex justify-start items-center gap-1">
                <ShowSources
                  isMagicMyc={true}
                  questionId={questionId}
                  question={question}
                  message={message}
                  refer={refer}
                />
                {model && (
                  <Chip
                    label={textToHeaderCase(model)}
                    variant="outlined"
                    color="primary"
                    size="small"
                  />
                )}
                {user.isAdmin && tokens && (
                  <Chip
                    label={`Tokens: ${tokens}`}
                    variant="filled"
                    color="info"
                    size="small"
                  />
                )}
              </div>
            ) : null}
          </div>
          <Box className="triangle-down-bot"></Box>
        </div>
        <Box
          className="self-baseline"
          sx={{
            position: "relative",
            display: "flex",
          }}
        >
          <CopyToClipboard
            text={
              message.replace(/<[^>]+>/g, "") +
              `${refer.length > 0 && `References : ${references}`}`
            }
            onCopy={() => {
              // alert("copied");
              setClipboardText("Copied")
            }}
          >
            <Box>
              <Tooltip
                title={clipboardText}
                placement="top"
                onClose={HandleTooltipClose}
              >
                <IconButton aria-label="copy" onClick={() => copy()}>
                  <FaRegCopy className="text-lg" />
                </IconButton>
              </Tooltip>
            </Box>
          </CopyToClipboard>
          <ResponseFeedback questionId={questionId} feedback={feedback} />
        </Box>
      </Col>
    </Row>
  )
}

export const BotThinking = () => {
  const [loadingTime, setLoadingTime] = useState(0)
  let timer
  useEffect(() => {
    isBotResponse = true
    timer = setInterval(() => {
      setLoadingTime(value => value + 1)
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  })

  const thinkingContent = useMemo(() => {
    if (loadingTime === 0) {
      return "Sending your request to the neural net. Please stand by!"
    } else if (loadingTime > 0 && loadingTime < 3) {
      return "Thank you for your question! Stand by while I look for relevant keywords to upsert your request!"
    } else if (loadingTime >= 3 && loadingTime < 6) {
      return "Relevant text located! Sending upserted request to neural net…"
    } else {
      clearInterval(timer)
      return "Waiting for response from neural net…"
    }
  }, [loadingTime])

  return (
    <>
      <Row className="flex justify-start my-2 sm:pl-10px me-1 conversation-list ">
        <Col
          xs={12}
          className="flex sm:flex-row flex-col-reverse justify-start sm:items-end items-start sm:w-auto w-full col-12"
        >
          <Avatar
            size="lg"
            alt="Magic Myc"
            src={ThinkingIcon}
            className="max-w-[50px] !rounded-none max-h-[50px] sm:mt-2  me-[-20px] "
          />
          <div className="sm:ml-6 ml-3 w-full">
            <div className="text-start px-4 py-2.5 rounded-[8px_8px_8px_0px] bg-[#f2f2f7] !whitespace-break-spaces">
              <div class="ctext-wrap">
                <div class="ctext-wrap-content">
                  <p class="mb-0 text-base">{thinkingContent}</p>
                </div>
              </div>
            </div>
            <Box className="triangle-down-bot"></Box>
          </div>
        </Col>
      </Row>
    </>
  )
}
