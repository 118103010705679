import {
  GET_CHATS_SUCCESS,
  GET_CHATS_FAIL,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAIL,
  POST_ADD_MESSAGE_SUCCESS,
  POST_ADD_MESSAGE_FAIL,
  DELETE_MESSAGE_FAIL,
  DELETE_MESSAGE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  botProfile: {},
  chats: [],
  groups: [],
  contacts: [],
  botMessages: [],
  error: {},
  isCreated: false,
  end: true,
  loading: false,
  answerError: false,
  botDetails: [],
  customBot: {},
  botUniqueId: "",
  BotSuggestions: [],
}

const Chat = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CHATS_SUCCESS:
      return {
        ...state,
        chats: action.payload,
        loading: true,
      }

    case GET_CHATS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        end: true,
        botMessages: action.payload.messages,
        loading: true,
      }

    case GET_MESSAGES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case POST_ADD_MESSAGE_SUCCESS:
      return {
        ...state,
        botMessages: state.botMessages.map(item => ({
          ...item,
          usermessages: [...item.usermessages, action.payload],
        })),
      }
    case POST_ADD_MESSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        botMessages: state.botMessages.map(item => ({
          ...item,
          usermessages: item.usermessages.filter(
            data => data.id !== action.payload,
          ),
        })),
      }
    case DELETE_MESSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Chat
