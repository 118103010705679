import React, { useEffect } from "react"
import StickyMenu from "./lib/StickyMenu"
import { Link } from "react-router-dom"
import logo from "../../assets/images/ep_logo_trippy.png"
import { FaRegUser } from "react-icons/fa"
import UserService from "KeyCloackServices/UserService"

function Header({ action }) {
  useEffect(() => {
    StickyMenu()
  })
  const handleServicesClick = text => {
    const servicesSection = document.getElementById(text)
    if (servicesSection) {
      servicesSection.scrollIntoView({ behavior: "smooth" })
    }
  }
  return (
    <header className={`appie-header-area !relative !bg-[#eef1f6] `}>
      <div className="container">
        <div className="header-nav-box">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-4 col-sm-5 col-6 order-1 order-sm-1">
              <div className="appie-logo-box">
                <Link to="/">
                  <img src={logo} alt="Mush Pro" height="70px" width="70px" />
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-1 col-sm-1 order-3 order-sm-2 d-lg-flex justify-content-lg-center">
              <div className="appie-header-main-menu">
                <ul>
                  <li>
                    <Link
                      to="#"
                      onClick={() => handleServicesClick("services-section")}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to=""
                      onClick={() => handleServicesClick("services-section")}
                    >
                      Services
                    </Link>
                  </li>

                  <li>
                    <Link
                      href="#"
                      onClick={() => {
                        handleServicesClick("footer-section")
                      }}
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3  col-md-7 col-sm-6 col-6 order-2 order-sm-3">
              <div className="appie-btn-box text-right d-lg-flex justify-content-lg-end">
                <div className="flex flex-row gap-2">
                  <span
                    className="login-btn border-1 text-[15px] leading-[45px] ml-[-30px] px-[30px] py-0 rounded-md border-solid border-[#2b70fa] flex flex-row items-center sm:!opacity-0 opacity-100 "
                    onClick={() =>
                      UserService.doLogin({
                        redirectUri: window.location.origin + "/",
                      })
                    }
                  >
                    Login
                  </span>
                  <span
                    onClick={() =>
                      UserService.doLogin({
                        redirectUri: window.location.origin + "/",
                      })
                    }
                    className="main-btn ml-30 flex justify-start gap-2 items-center sm:opacity-0 opacity-75"
                  >
                    <FaRegUser className="me-1" />
                    Login with EntheoID
                  </span>
                </div>
                <div
                  onClick={e => action(e)}
                  className="toggle-btn ms-2 canvas_open d-lg-none d-block"
                >
                  <i className="fa fa-bars" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
