import * as actionTypes from "./actionTypes"

export function getPDFs(filter) {
  return {
    type: actionTypes.GET_PDFS_REQUEST,
    payload: filter,
  }
}

export function getMyDocs(filter) {
  return {
    type: actionTypes.GET_MYDOCS_REQUEST,
    payload: filter,
  }
}

export function handleUploadFiles(files, callBack) {
  return {
    type: actionTypes.UPLOAD_FILES_REQUEST,
    payload: files,
    callBack,
  }
}

export function handleUploadContributeFiles(files, callBack) {
  return {
    type: actionTypes.UPLOAD_CONTRIBUTE_FILES_REQUEST,
    payload: files,
    callBack,
  }
}

export function convertDocsPrivate(items, convertPrivate, callBack) {
  return {
    type: actionTypes.CONVERT_DOCUMENTS_PRIVATE_REQUEST,
    payload: { items, convertPrivate },
    callBack,
  }
}

export function deleteDocs(items, callBack) {
  return {
    type: actionTypes.DELETE_DOCUMENTS_REQUEST,
    payload: { items },
    callBack,
  }
}

export function getWebsites(filter) {
  return {
    type: actionTypes.GET_WEBSITES_REQUEST,
    payload: filter,
  }
}

export function getMyWebsites(filter) {
  return {
    type: actionTypes.GET_MYWEBSITES_REQUEST,
    payload: filter,
  }
}

export function registerWebsiteUrl(url, callBack) {
  return {
    type: actionTypes.REGSITER_URL_REQUEST,
    payload: url,
    callBack,
  }
}

export function registerContibuteWebsiteUrl(url, callBack) {
  return {
    type: actionTypes.REGSITER_CONTRIBUTE_URL_REQUEST,
    payload: url,
    callBack,
  }
}

export function deleteWebsites(items, callBack) {
  return {
    type: actionTypes.DELETE_WEBSITES_REQUEST,
    payload: { items },
    callBack,
  }
}
