import * as React from "react"
import { useState } from "react"
import { Input, Button } from "reactstrap"

import CircularProgress from "@mui/material/CircularProgress"
import { FaCloudUploadAlt } from "react-icons/fa"
import { Typography } from "@material-tailwind/react"
import { useMutation } from "react-query"
import { setSessionExpired, useMainController } from "context"
import { toast } from "react-hot-toast"
import http from "services/http-common"

export default function AddBulkFaqs({ getFaqs }) {
  const [dispatch] = useMainController()
  const [isLoading, setIsLoading] = useState(false)
  const [csvFile, setCsvFile] = useState()

  const onChangeUploadFiles = e => {
    setCsvFile(e.target.files[0])
  }

  const fileUpload = useMutation(
    async file => {
      console.log(file)
      return http.filepost(
        `train/book/upload-csv`,
        file,
        null,
        setSessionExpired,
        dispatch,
      )
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message)
        setIsLoading(false)
        getFaqs()
      },
      onError: error => {
        toast.error(error.response.data.status)
        setIsLoading(false)
      },
    },
  )

  const onSubmit = () => {
    setIsLoading(true)
    if (csvFile) {
      const data = new FormData()
      data.append("books", csvFile)
      fileUpload.mutate(data)
    } else {
      return false
    }
  }

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-col gap-0 px-4">
        <div>
          <Typography color="gray" variant="span">
            *Column Names (Question,Answer and Function):
          </Typography>
        </div>
        <div sx={{ width: "100%", mb: 2, boxShadow: "none" }}>
          <div className="flex flex-col items-start py-3">
            <div className="w-full flex sm:flex-row flex-col items-center gap-3 ">
              <Input
                multiple
                sx={{ display: "none" }}
                id="documents-upload"
                inputProps={{ multiple: false, accept: ".csv" }}
                type="file"
                onChange={onChangeUploadFiles}
              />
              <Button
                className="flex items-center gap-3 sm:w-auto w-full text-center justify-center btn !bg-[#7a6fbe]"
                disabled={isLoading ? true : csvFile ? false : true}
                onClick={onSubmit}
              >
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <>
                    <FaCloudUploadAlt className="text-xl" />
                    Upload
                  </>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
