import React from "react"
import Bot from "./EmbededBot/Bot"

const PersonalityChat = props => {
  document.title = "PersonalityChat | MushPro by The Entheology Project"
  return (
    <>
      <Bot />
    </>
  )
}

export default PersonalityChat
