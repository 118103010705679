import { useRef, useEffect, useState, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"

// ** Import Child Components
import { HumanMessage, BotMessage, BotThinking } from "./message"
import { getChatHistory, getAnswerAgain } from "../../store/messages/actions"
import { Box, IconButton } from "@mui/material"
// import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded"

document.title = "Magic Myc | MushPro by The Entheology Project"

const ContentContainer = ({ showSuggestions, setShowSuggestions }) => {
  const dispatch = useDispatch()
  const messagesEndRef = useRef(null)
  const { loading, end, messages, answerError } = useSelector(
    state => state.msg,
  )
  const [isMounted, setIsMounted] = useState(false)

  const Messages = useMemo(
    () =>
      messages.map((_item, index) => {
        if (_item.sender === "human") {
          return <HumanMessage key={index} message={_item.message} />
        } else {
          return (
            <BotMessage
              showSuggestions={showSuggestions}
              questionId={_item?.id && _item?.id}
              key={index}
              question={_item?.question ? _item?.question : ""}
              messageIndex={index}
              message={_item.message}
              tokens={_item.tokens}
              model={_item?.model ? _item?.model : null}
              feedback={_item.feedback}
              refer={_item.refer}
            />
          )
        }
      }),
    [messages],
  )

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    setIsMounted(true)
    !messages.length && dispatch(getChatHistory())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }, [isMounted, loading])

  const answerAgainHandler = () => {
    dispatch(
      getAnswerAgain({
        finalChat: messages[0].message,
        llm: localStorage.getItem("model")?.toLocaleLowerCase(),
      }),
    )
  }

  return (
    <InfiniteScroll
      dataLength={messages.length}
      next={() => {
        !end && dispatch(getChatHistory(messages[messages.length - 1].id))
      }}
      style={{ display: "flex", flexDirection: "column-reverse" }}
      inverse={true}
      hasMore={true}
      loader={!end && <h4 style={{ textAlign: "center" }}>Loading...</h4>}
      scrollableTarget="scrollableDiv"
    >
      <div ref={messagesEndRef}></div>
      {answerError && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            aria-label="Refresh"
            sx={{ border: "2px solid #A2A5C6", mr: "20px" }}
            onClick={answerAgainHandler}
          >
            {/* <RefreshRoundedIcon /> */}
            RA
          </IconButton>
          Please request again.
        </Box>
      )}
      {loading && <BotThinking />}
      {Messages}
    </InfiniteScroll>
  )
}

export default ContentContainer
