import React from "react"

function ServicesHomeTwo({ className }) {
  return (
    <>
      <section
        className={`appie-services-2-area  py-[100px] !bg-[#ffffff]`}
        id="services-section"
      >
        <div className="container ">
          <div className="row align-items-end">
            <div className="col-lg-6 col-md-8">
              <div className="appie-section-title">
                <h3 className="appie-title">Services</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div
                className="appie-single-service-2 mt-30 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <div className="icon">
                  <i className="fas fa-bolt" />
                </div>
                <h4 className="title">Customizable AI Chatbot Assistant</h4>
                <p>
                  Automate client interactions, answer FAQs, and provide
                  personalized support between sessions, freeing up your
                  valuable time.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="600ms"
              >
                <div className="icon">
                  <i className="fas fa-link" />
                </div>
                <h4 className="title">Secure Document & Resource Hub</h4>
                <p>
                  Effortlessly share materials, track client access, and reduce
                  the administrative burden of managing resources.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <div className="icon">
                  <i className="fas fa-bell" />
                </div>
                <h4 className="title">Client Intake & Progress Tracking</h4>
                <p>
                  treamline the intake process and monitor client progress with
                  intuitive, time-saving digital tools.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <div className="icon">
                  <i className="fas fa-cog" />
                </div>
                <h4 className="title">Coach Training & Support</h4>
                <p>
                  Access our knowledge base and expert community to maximize the
                  potential of our AI tools and elevate your coaching practice.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <div className="icon">
                  <i className="fas fa-home" />
                </div>
                <h4 className="title">AI-Driven Insights</h4>
                <p>
                  Gain a deeper understanding of client progress and
                  communication patterns, allowing you to focus on high-impact
                  coaching interventions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ServicesHomeTwo
