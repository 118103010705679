import UserService from "KeyCloackServices/UserService"

export const getAccessToken = () => {
  // return localStorage.getItem("accessToken")
  // return UserService.getToken()

  return `Bearer ${UserService.getToken()}`
}

export const getBotAccessToken = () => {
  return localStorage.getItem("botAccessTokenEncoded")
}

export const getBotUniqueUserId = () => {
  return localStorage.getItem("botUniqueUserId")
}

export const generateSessionId = () => {
  const newSessionId = Date.now()
  return newSessionId
}
