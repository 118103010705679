import { call, all, put, takeLatest } from "redux-saga/effects"
import * as actionTypes from "./actionTypes"
import { getAccessToken } from "helpers/jwt-token-access/accessToken"
import apiClient, {
  apiClientForEntheoGpt,
  apiClientForMushGpt,
} from "helpers/apiClient"

async function shooterhandleUploadFiles(files) {
  return apiClient.post("/train/book", files, {
    headers: {
      Authorization: getAccessToken(),
      "Content-type": "multipart/form-data",
    },
  })
}

function* handleUploadFiles() {
  return yield takeLatest(actionTypes.UPLOAD_FILES_REQUEST, function* (action) {
    try {
      const data = yield call(shooterhandleUploadFiles, action.payload)
      action.callBack({ status: "success", returnData: data })
    } catch (error) {
      action.callBack({
        status: "error",
        returnData: error?.response?.data?.message
          ? error?.response?.data
          : error,
      })
    }
  })
}

async function shooterhandleUploadContributeFiles(files) {
  return apiClientForEntheoGpt.post("/train/book/contribute", files, {
    headers: {
      Authorization: getAccessToken(),
      "Content-type": "multipart/form-data",
    },
  })
}

function* handleUploadContributeFiles() {
  return yield takeLatest(
    actionTypes.UPLOAD_CONTRIBUTE_FILES_REQUEST,
    function* (action) {
      try {
        const data = yield call(
          shooterhandleUploadContributeFiles,
          action.payload,
        )
        action.callBack({ status: "success", returnData: data })
      } catch (error) {
        action.callBack({
          status: "error",
          returnData: error?.response?.data?.message
            ? error?.response?.data
            : error,
        })
      }
    },
  )
}

async function shootergetPDFs(filter) {
  let status = []

  filter.filters.done && status.push("done")
  filter.filters.failed && status.push("failed")
  filter.filters.pending && status.push("pending")
  filter.filters.ready && status.push("ready")
  if (status.length === 0) {
    status = ["done", "failed", "pending", "ready"]
  }

  const [key, sort] = filter.sort.split("|")
  let sorters = {}

  sorters[key] = {
    desc: sort === "desc",
  }
  // let sorters = JSON.stringify({
  // 	updated_at: { desc: filter.sort === 'updatedAt|desc' },
  // 	name: { desc: filter.sort === 'name|desc' },
  // });

  let url = `/train/book?cp=${filter.page + 1}&ps=${
    filter.rowsPerPage
  }&filters=${JSON.stringify({
    name: filter?.filters.query,
    status: status,
  })}&sorters=${JSON.stringify(sorters)}`

  return apiClient.get(url, {
    headers: { Authorization: getAccessToken() },
  })
}

function* getPDFs() {
  return yield takeLatest(actionTypes.GET_PDFS_REQUEST, function* (action) {
    try {
      const data = yield call(shootergetPDFs, action.payload)
      yield put({
        type: actionTypes.GET_PDFS_REQUEST_SUCCESS,
        payload: {
          ...data,
        },
      })
    } catch (error) {
      console.log(error)
    }
  })
}

async function shootergetMyDocs(filter) {
  let status = []

  if (filter.filter.statusFilter === "all") {
    status = ["done", "failed", "pending", "ready"]
  } else {
    status.push(filter.filter.statusFilter)
  }

  let sorters = {}
  if (filter.orderBy === "privateStatus") {
    sorters["private"] = {
      desc: filter.order === "desc",
    }
  } else {
    sorters[filter.orderBy] = {
      desc: filter.order === "desc",
    }
  }

  let url = `/train/book/mine?cp=${filter.page + 1}&ps=${
    filter.rowsPerPage
  }&filters=${JSON.stringify({
    name: filter?.filter.query,
    status: status,
  })}&sorters=${JSON.stringify(sorters)}`

  return apiClient.get(url, {
    headers: { Authorization: getAccessToken() },
  })
}

function* getMyDocs() {
  return yield takeLatest(actionTypes.GET_MYDOCS_REQUEST, function* (action) {
    try {
      const data = yield call(shootergetMyDocs, action.payload)
      yield put({
        type: actionTypes.GET_MYDOCS_REQUEST_SUCCESS,
        payload: {
          ...data,
        },
      })
    } catch (error) {
      console.log(error)
    }
  })
}

async function shooterConvertDocsPrivate(data) {
  return apiClient.put("/train/book/mine/bulk/private", data, {
    headers: { Authorization: getAccessToken() },
  })
}

function* convertDocsPrivate() {
  return yield takeLatest(
    actionTypes.CONVERT_DOCUMENTS_PRIVATE_REQUEST,
    function* (action) {
      try {
        const data = yield call(shooterConvertDocsPrivate, action.payload)
        action.callBack({ status: "success", returnData: data })
      } catch (error) {
        action.callBack({
          status: "error",
          returnData: error?.response?.data?.message
            ? error?.response?.data
            : error,
        })
      }
    },
  )
}

async function shooterDeleteDocs(data) {
  return apiClient.post("/train/book/mine/bulk/delete", data, {
    headers: { Authorization: getAccessToken() },
  })
}

function* deleteDocs() {
  return yield takeLatest(
    actionTypes.DELETE_DOCUMENTS_REQUEST,
    function* (action) {
      try {
        const data = yield call(shooterDeleteDocs, action.payload)
        action.callBack({ status: "success", returnData: data })
      } catch (error) {
        action.callBack({
          status: "error",
          returnData: error?.response?.data?.message
            ? error?.response?.data
            : error,
        })
      }
    },
  )
}

async function shooterregisterWebsiteUrl(url) {
  return apiClient.post(
    "/train/website",
    { url },
    {
      headers: {
        Authorization: getAccessToken(),
      },
    },
  )
}

function* registerWebsiteUrl() {
  return yield takeLatest(actionTypes.REGSITER_URL_REQUEST, function* (action) {
    try {
      const data = yield call(shooterregisterWebsiteUrl, action.payload)
      action.callBack({ status: "success", returnData: data })
    } catch (error) {
      action.callBack({
        status: "error",
        returnData: error?.response?.data?.message
          ? error?.response?.data
          : error,
      })
    }
  })
}

async function shooterRegisterContributeWebsiteUrl(url) {
  return apiClientForEntheoGpt.post(
    "/train/website/contribute",
    { url },
    {
      headers: {
        Authorization: getAccessToken(),
      },
    },
  )
}

function* registerCOntributeWebsiteUrl() {
  return yield takeLatest(
    actionTypes.REGSITER_CONTRIBUTE_URL_REQUEST,
    function* (action) {
      try {
        const data = yield call(
          shooterRegisterContributeWebsiteUrl,
          action.payload,
        )
        action.callBack({ status: "success", returnData: data })
      } catch (error) {
        action.callBack({
          status: "error",
          returnData: error?.response?.data?.message
            ? error?.response?.data
            : error,
        })
      }
    },
  )
}
async function shootergetWebsites(filter) {
  let status = []

  if (filter.filters.status === "all") {
    status = [
      "ready",
      "scraping",
      "scraping failed",
      "scraped",
      "training",
      "training failed",
      "trained",
    ]
  } else {
    status.push(filter.filters.status)
  }

  const [key, sort] = filter.sort.split("|")
  let sorters = {}
  sorters[key] = {
    desc: sort === "desc",
  }
  // let sorters = JSON.stringify({
  // 	updated_at: { desc: filter.sort === 'updatedAt|desc' },
  // 	name: { desc: filter.sort === 'name|desc' },
  // });

  let url = `/train/website?cp=${filter.page + 1}&ps=${
    filter.rowsPerPage
  }&filters=${JSON.stringify({
    url: filter?.filters.query,
    status: status,
  })}&sorters=${JSON.stringify(sorters)}`

  return apiClient.get(url, {
    headers: { Authorization: getAccessToken() },
  })
}

function* getWebsites() {
  return yield takeLatest(actionTypes.GET_WEBSITES_REQUEST, function* (action) {
    try {
      const data = yield call(shootergetWebsites, action.payload)
      yield put({
        type: actionTypes.GET_WEBSITES_REQUEST_SUCCESS,
        payload: {
          ...data,
        },
      })
    } catch (error) {
      console.log(error)
    }
  })
}

async function shootergetMyWebsites(filter) {
  let status = []

  if (filter.filter.statusFilter === "all") {
    status = undefined
  } else {
    status.push(filter.filter.statusFilter)
  }

  let sorters = {}
  if (filter.orderBy === "privateStatus") {
    sorters["private"] = {
      desc: filter.order === "desc",
    }
  } else {
    sorters[filter.orderBy] = {
      desc: filter.order === "desc",
    }
  }

  let url = `/train/website/mine?cp=${filter.page + 1}&ps=${
    filter.rowsPerPage
  }&filters=${JSON.stringify({
    url: filter?.filter.query,
    status: status,
  })}&sorters=${JSON.stringify(sorters)}`

  return apiClient.get(url, {
    headers: { Authorization: getAccessToken() },
  })
}

function* getMyWebsites() {
  return yield takeLatest(
    actionTypes.GET_MYWEBSITES_REQUEST,
    function* (action) {
      try {
        const data = yield call(shootergetMyWebsites, action.payload)
        yield put({
          type: actionTypes.GET_MYWEBSITES_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        })
      } catch (error) {
        console.log(error)
      }
    },
  )
}

async function shooterDeleteWebsites(data) {
  return apiClient.post("/train/website/mine/bulk/delete", data, {
    headers: { Authorization: getAccessToken() },
  })
}

function* deleteWebsites() {
  return yield takeLatest(
    actionTypes.DELETE_WEBSITES_REQUEST,
    function* (action) {
      try {
        const data = yield call(shooterDeleteWebsites, action.payload)
        action.callBack({ status: "success", returnData: data })
      } catch (error) {
        action.callBack({
          status: "error",
          returnData: error?.response?.data?.message
            ? error?.response?.data
            : error,
        })
      }
    },
  )
}

export default function* runBlockingCallsExample() {
  yield all([
    getPDFs(),
    handleUploadFiles(),
    handleUploadContributeFiles(),
    getMyDocs(),
    convertDocsPrivate(),
    deleteDocs(),
    registerWebsiteUrl(),
    getWebsites(),
    getMyWebsites(),
    deleteWebsites(),
    registerCOntributeWebsiteUrl(),
  ])
}
