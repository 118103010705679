export function formatDate(timestamp) {
  const date = new Date(timestamp)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const day = String(date.getDate()).padStart(2, "0")
  return `${year}/${month}/${day}`
}

export const RE_DIGIT = new RegExp(/^\d+$/)

export function convertToTitleCase(sentence) {
  // Replace underscore with space and split into words
  let words = sentence.replace(/_/g, " ").split(" ")

  // Capitalize each word
  let capitalizedWords = words.map(
    word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
  )

  // Join words back into a sentence
  let titleCaseSentence = capitalizedWords.join(" ")

  return titleCaseSentence
}

export function restructureEvents(events) {
  return events.map(event => ({
    ...event,
    isGoogleCalender: false,
    start: event.startDate.replace(" UTC", ""),
    startDate: event.startDate.replace(" UTC", ""),
    end: event.endDate.replace(" UTC", ""),
    endDate: event.endDate.replace(" UTC", ""),
    // start: new Date(
    //   new Date(event.startDate).getFullYear(),
    //   new Date(event.startDate).getMonth(),
    //   new Date(event.startDate).getDate(),
    // )
    //   .toString()
    //   .replace(" UTC", ""),
    // end: new Date(
    //   new Date(event.endDate).getFullYear(),
    //   new Date(event.endDate).getMonth(),
    //   new Date(event.endDate).getDate() + 1,
    // )
    //   .toString()
    //   .replace(" UTC", ""),
    url: "#",
    allDay: true,
    className: event.isAppointment ? "bg-info text-white" : "text-white",
  }))
}

export const transformArray = originalArray => {
  return originalArray.map(item => ({
    value: item.email,
    label: item.email,
  }))
}

export function convertCalendarEvents(event) {
  return {
    isGoogleCalender: true,
    id: new Date().valueOf(),
    startDate: new Date(event.start.dateTime).toISOString(),
    endDate: new Date(event.end.dateTime).toISOString(),
    title: event.summary,
    location: event.location,
    meetingLink: event.location,
    description: event.description,
    isAppointment: true,
    created_at: new Date(event.created).toISOString(),
    updated_at: new Date(event.updated).toISOString(),
    attendees: event.attendees.map(attendee => ({
      email: attendee.email,
      confirmed: attendee.responseStatus === "accepted",
    })),
  }
}

export function htmlToText(html) {
  // Replace <br> tags with new lines
  let text = html.replace(/<br\s*\/?>/gi, "\n")
  // Remove other HTML tags
  text = text.replace(/<\/?[^>]+(>|$)/g, "")
  // Trim leading and trailing white space
  text = text.trim()
  return text
}

export function timeAgo(postedTime) {
  const now = new Date()
  const postedDate = new Date(postedTime)
  const differenceInSeconds = Math.floor((now - postedDate) / 1000)

  const units = [
    { name: "year", seconds: 31536000 },
    { name: "month", seconds: 2592000 },
    { name: "week", seconds: 604800 },
    { name: "day", seconds: 86400 },
    { name: "hour", seconds: 3600 },
    { name: "minute", seconds: 60 },
    { name: "second", seconds: 1 },
  ]

  for (const unit of units) {
    const quotient = Math.floor(differenceInSeconds / unit.seconds)
    if (quotient > 0) {
      return quotient === 1
        ? `1 ${unit.name} ago`
        : `${quotient} ${unit.name}s ago`
    }
  }

  return "just now"
}
