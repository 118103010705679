import * as actionTypes from "./actionTypes"

const initialState = {
  isCreated: false,
  end: true,
  loading: false,
  botMessages: [],
  answerError: false,
  botProfile: {},
  botUniqueId: "",
  BotSuggestions: [],
  embeddingMessage: {},
  error: "",
  success: "",
}

const BotReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_BOT_MESSAGE:
      return {
        ...prevState,
        loading: true,
        end: prevState.end,
        botMessages: [
          {
            sender: "human",
            message: action.payload,
          },
          ...prevState.botMessages,
        ],
      }

    case actionTypes.ADD_CHAT_BOT_MESSAGE:
      return {
        ...prevState,
        loading: false,
        end: prevState.end,
        botMessages: [
          {
            sender: "bot",
            message: action.payload?.data.messages[1]
              ? action.payload?.data.messages[1].content
              : action.payload?.data.messages[0].content,
          },
          ...prevState.botMessages,
        ],
      }
    case actionTypes.REGISTER_BOT_REQUEST_SUCCESS:
      return {
        ...prevState,
        isCreated: true,
        success: "Registered successfully.",
        botProfile: action.payload?.data.formData,
      }
    case actionTypes.GET_BOT_DETAILS_REQUEST_SUCCESS:
      return {
        ...prevState,
        success: "",
        error: "",
        botProfile: action.payload?.data?.bot,
      }

    case actionTypes.GET_BOT_DATA_MYC_SUCCESS:
      return {
        ...prevState,
        isCreated: true,
        botProfile: action.payload?.data.bot,
      }

    case actionTypes.UPDATE_BOT_REQUEST_SUCCESS:
      return {
        ...prevState,
        success: "Updated Successfully",
        botProfile: action.payload?.data.bot,
      }
    case actionTypes.UPDATE_BOT_MYC_REQUEST_SUCCESS:
      return {
        ...prevState,
        success: "Updated Successfully",
        botProfile: action.payload?.data.bot,
      }
    case actionTypes.GET_BOT_CHAT_HISTORY_REQUEST_SUCCESS:
      let botChatHistory = []
      action.payload?.data?.messages.reverse().map(_item => {
        if (_item.role !== "user") {
          botChatHistory.push({
            id: _item.id,
            sender: "bot",
            message: _item.content,
            refer: JSON.parse(_item.refer ? _item.refer : "[]"),
          })
        } else {
          botChatHistory.push({
            id: _item.id,
            sender: "human",
            message: _item.content,
          })
        }
        return 0
      })
      return {
        ...prevState,
        loading: false,
        end: action.payload?.data?.end,
        botMessages: [...prevState.botMessages, ...botChatHistory],
        BotSuggestions: action.payload?.data?.questions
          ? action.payload?.data?.questions
          : [],
      }

    // ** After fetch answer
    case actionTypes.GET_BOT_ANSWER_REQUEST_SUCCESS:
      let messages = []
      action.payload?.data?.messages.reverse().map(_item => {
        if (_item.role !== "user") {
          messages.push({
            id: _item.id,
            sender: "bot",
            message: _item.content,
            refer: JSON.parse(_item.refer ? _item.refer : "[]"),
          })
        }
        return []
      })
      return {
        ...prevState,
        loading: false,
        end: action.payload?.data?.end,
        botMessages: [...messages, ...prevState.botMessages],
        BotSuggestions: action.payload?.data?.questions
          ? action.payload?.data?.questions
          : [],
      }

    case actionTypes.GET_BOT_ANSWER_REQUEST:
    case actionTypes.GET_ANSWER_AGAIN_REQUEST:
      return {
        ...prevState,
        answerError: false,
      }
    case actionTypes.GET_BOT_ANSWER_REQUEST_ERROR:
      return {
        ...prevState,
        loading: false,
        answerError: true,
      }

    case actionTypes.DELETE_BOT_CHAT_HISTORY_REQUEST_SUCCESS:
      return {
        ...prevState,
        loading: false,
        botMessages: [],
      }
    case actionTypes.CLEAR_BOT_MESSAGES_HISTORY:
      return {
        end: true,
        botMessages: [],
        BotSuggestions: [],
      }
    case actionTypes.EMBEDDING_HISTORY_REQUEST_SUCCESS:
      return {
        ...prevState,
        embeddingMessage: { ...action.payload?.data },
      }
    default:
      return prevState
  }
}

export default BotReducer
