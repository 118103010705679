import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getAnswer, getMycQuickResponses } from "../../store/messages/actions"

const MagicMycQResponse = () => {
  const [randomSuggestion, setRandomSuggestion] = useState([])
  const { quickResponses } = useSelector(state => state.msg)
  const dispatch = useDispatch()

  useEffect(() => {
    quickResponses?.length > 0 && setRandomSuggestion(quickResponses)
  }, [quickResponses])

  useEffect(() => {
    dispatch(getMycQuickResponses(quickResponseCallBack))
  }, [])

  const quickResponseCallBack = data => {
    if (data.status === "success") {
      let rand3Question = data.returnData.data.quickReplies
        .slice()
        .sort(() => Math.random() - 0.5)
      setRandomSuggestion(rand3Question.slice(0, 3))
    } else {
      console.log("One bug happens on server side. Please try again later.")
    }
  }

  const onSubmit = query => {
    if (query !== "") {
      dispatch(
        getAnswer({
          finalChat: query,
          llm: localStorage.getItem("model")?.toLocaleLowerCase(),
        }),
      )
    }
  }

  const firstFiveWords = text => {
    let words = text.split(" ")
    return words.slice(0, 5).join(" ")
  }
  const lastWords = text => {
    let words = text.split(" ")
    return words.slice(5, words.length).join(" ")
  }
  return (
    <>
      <div className="flex items-center relative gap-2 py-2 w-full overflow-x-auto  whitespace-nowrap ">
        {randomSuggestion?.map((item, idx) => {
          return (
            <>
              <span
                onClick={() => onSubmit(item.question ? item.question : item)}
                key={idx}
              >
                <button
                  className=" truncate h-auto p-[10px] opacity-70  text-start text-ellipsis overflow-hidden bg-[#f2f2f7] inline-flex items-center justify-center text-sm transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-80 bg-zinc-900 text-zinc-50 hover:bg-zinc-800/90 dark:bg-zinc-50 dark:text-zinc-900 dark:hover:bg-zinc-50/90 rounded-md px-3 whitespace-nowrap font-normal shadow-none group-[.cb-dark]:bg-zinc-800 group-[.cb-dark]:text-white group-[.cb-dark]:hover:bg-zinc-700 group-[.cb-light]:bg-zinc-200/50 group-[.cb-light]:text-black group-[.cb-light]:hover:bg-zinc-200"
                  type="button"
                >
                  <p className="truncate sm:w-auto w-72">
                    <strong>
                      {item.question
                        ? firstFiveWords(item.question)
                        : firstFiveWords(item)}
                    </strong>
                    <br />
                    <p className="truncate sm:w-auto w-72">
                      {item.question
                        ? lastWords(item.question)
                        : lastWords(item)}
                    </p>
                    {/* {item.question ? item.question : item} */}
                  </p>
                </button>
              </span>
            </>
          )
        })}
      </div>
    </>
  )
}

export default MagicMycQResponse
