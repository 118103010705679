import { Button, Box } from "@mui/material"
import {
  Card,
  CardBody,
  Input,
  Row,
  Alert,
  CardImg,
  Col,
  Label,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import CircularProgress from "@mui/material/CircularProgress"
import mushIcon from "../../assets/images/Main Heading.png"
import { botRegister, handleUploadLogo } from "store/bot/actions"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import ReactQuill from "react-quill"

const initialValues = {
  botName: "Your bot’s name",
  iconUrl: "",
  footerNote: "",
  primaryDomain:
    "Expert in mushroom microdosing, plant medicines, kambo, somatic therapy, and ibogaine.",
  greeting: "Hi I’m {bot_name}! How can I help you, Seeker?",
  tone: "You are a warm, witty and friendly educator who is succinct and compassionate.",
  additionalCertification: "Tripsitter, Trauma informed therapist, MFT",
  specialInstruction:
    "Please do not share private information. For contact info or appointments, just ask!",
}

const validationSchema = Yup.object({
  botName: Yup.string()
    .min(1, "Must be at least 1 character")
    .max(20)
    .required("Required"),
  primaryDomain: Yup.string()
    .min(1, "Must be a at least 1 character")
    .max(255)
    .required("Required"),
  greeting: Yup.string()
    .min(2, "Must be at least 2 characters")
    .max(255)
    .required("Required"),
  tone: Yup.string().required("Required"),
  additionalCertification: Yup.string().max(100).required("Required"),
  specialInstruction: Yup.string().max(200).required("Required"),
})

const RegisterBot = () => {
  document.title = "Chat | MushPro by The Entheology Project"
  const dispatch = useDispatch()
  const [fileName, setFileName] = useState("Upload Logo")
  const navigate = useNavigate()
  const [isUploading, setIsUploading] = useState(false)
  const [previewAvatar, setPreviewAvatar] = useState(mushIcon)
  const [visible, setVisible] = useState(true)

  const onDismiss = () => setVisible(false)

  const onUploadCallBack = ({ status, returnData }) => {
    if (status === "success") {
      setIsUploading(false)
      validation.setFieldTouched("iconUrl", true)
      validation.setFieldValue("iconUrl", returnData.data.imageUrl)
      setFileName("Upload Successfully")
    } else {
      setIsUploading(false)
      console.log(returnData.message)
    }
  }
  const imageSelect = e => {
    setIsUploading(true)
    const formData = new FormData()
    formData.append("file", e.currentTarget.files[0])
    dispatch(handleUploadLogo(formData, onUploadCallBack))
    e?.currentTarget?.files[0] &&
      setPreviewAvatar(URL.createObjectURL(e.currentTarget.files[0]))
  }

  const validation = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      dispatch(botRegister(values, navigate, helpers))
    },
  })

  const selectLoginState = state => state.bot
  const BotProperties = createSelector(selectLoginState, bot => ({
    error: bot.error,
    success: bot.success,
  }))

  const { error, success } = useSelector(BotProperties)

  useEffect(() => {
    if (validation.values.botName !== "Your bot’s name") {
      validation.setFieldValue(
        "greeting",
        `Hi I’m ${validation.values.botName}! How can I help you, Seeker?`,
      )
    }
  }, [validation.values.botName])

  const onChangePrompt = html => {
    validation.setFieldTouched("footerNote", true)
    validation.setFieldValue("footerNote", html)
  }

  return (
    <>
      <div className="w-100 user-chat mt-4 mt-sm-0 ">
        {error && error ? (
          <Alert
            color="danger"
            style={{ marginTop: "13px" }}
            isOpen={visible}
            dismissible
            className="flex justify-between items-center"
          >
            {error}
            <Button
              color="inherit"
              onClick={onDismiss}
              className="cursor-pointer"
            ></Button>
          </Alert>
        ) : null}
        {success ? (
          <Alert
            color="success"
            style={{ marginTop: "13px" }}
            isOpen={visible}
            dismissible
            className="flex justify-between items-center"
          >
            {success}
            <Button
              color="inherit"
              onClick={onDismiss}
              className="cursor-pointer"
            ></Button>
          </Alert>
        ) : null}
        <Card>
          <CardBody>
            <form noValidate onSubmit={validation.handleSubmit}>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  mt: "20px",
                  mb: "10px",
                }}
              >
                <CardImg
                  src={previewAvatar}
                  alt="Profile Pic"
                  className="rounded-circle avatar-md max-w-[118px] max-h-[118px] sm:me-0 me-3"
                />
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    component="label"
                    className="rounded-sm"
                  >
                    {isUploading ? <CircularProgress /> : fileName}
                    <input
                      type="file"
                      name="avatar"
                      hidden
                      onChange={imageSelect}
                    />
                  </Button>
                </Box>
              </Box>
              <Row>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">Assistant’s Name</Label>
                    <Input
                      name="botName"
                      // value={name}
                      className="form-control"
                      placeholder="Your bot’s name"
                      type="textarea"
                      row="1"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.botName || ""}
                      invalid={
                        validation.touched.botName && validation.errors.botName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.botName && validation.errors.botName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.botName}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">About the Coach</Label>
                    <Input
                      name="primaryDomain"
                      // value={name}
                      className="form-control"
                      placeholder="Expert in mushroom microdosing, plant medicines, kambo, somatic therapy, and ibogaine."
                      type="textarea"
                      row="1"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.primaryDomain || ""}
                      invalid={
                        validation.touched.primaryDomain &&
                        validation.errors.primaryDomain
                          ? true
                          : false
                      }
                    />
                    {validation.touched.primaryDomain &&
                    validation.errors.primaryDomain ? (
                      <FormFeedback type="invalid">
                        {validation.errors.primaryDomain}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">List all credentials</Label>
                    <Input
                      name="additionalCertification"
                      // value={name}
                      className="form-control"
                      placeholder="Tripsitter, Trauma informed therapist, MFT."
                      type="textarea"
                      row="1"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.additionalCertification || ""}
                      invalid={
                        validation.touched.additionalCertification &&
                        validation.errors.additionalCertification
                          ? true
                          : false
                      }
                    />
                    {validation.touched.additionalCertification &&
                    validation.errors.additionalCertification ? (
                      <FormFeedback type="invalid">
                        {validation.errors.additionalCertification}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">Instructions</Label>
                    <Input
                      name="specialInstruction"
                      // value={name}
                      className="form-control"
                      placeholder="Please do not share private information. For contact info or appointments, just ask!"
                      type="textarea"
                      row="1"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.specialInstruction || ""}
                      invalid={
                        validation.touched.specialInstruction &&
                        validation.errors.specialInstruction
                          ? true
                          : false
                      }
                    />
                    {validation.touched.specialInstruction &&
                    validation.errors.specialInstruction ? (
                      <FormFeedback type="invalid">
                        {validation.errors.specialInstruction}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">Tone/Personality</Label>
                    <Input
                      id="tone"
                      type="textarea"
                      row="1"
                      placeholder="You are a warm, witty and friendly educator who is succinct and compassionate."
                      name="tone"
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      value={validation.values.tone || ""}
                      error={Boolean(
                        validation.touched.tone && validation.errors.tone,
                      )}
                      invalid={
                        validation.touched.tone && validation.errors.tone
                          ? true
                          : false
                      }
                      className="w-full"
                    />
                    {validation.touched.tone && validation.errors.tone ? (
                      <FormFeedback type="invalid">
                        {validation.errors.tone}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">Greeting</Label>
                    <Input
                      name="greeting"
                      // value={name}
                      className="form-control"
                      placeholder="How can I help you, Seeker?"
                      type="textarea"
                      row="1"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.greeting || ``}
                      invalid={
                        validation.touched.greeting &&
                        validation.errors.greeting
                          ? true
                          : false
                      }
                    />
                    {validation.touched.greeting &&
                    validation.errors.greeting ? (
                      <FormFeedback type="invalid">
                        {validation.errors.greeting}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>{" "}
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">Footer Note</Label>
                    <ReactQuill
                      theme="snow"
                      className={`mb-5 w-auto max-h-[300px] text-black `}
                      value={validation.values.footerNote || ""}
                      onChange={onChangePrompt}
                    />
                    {validation.touched.greeting &&
                    validation.errors.greeting ? (
                      <FormFeedback type="invalid">
                        {validation.errors.greeting}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <div className="d-flex justify-content-end align-items-center">
                <Button
                  type="submit"
                  className="!bg-[#7a6fbe] text-white !btn !btn-primary !w-md"
                >
                  Create Bot
                </Button>
              </div>
            </form>
          </CardBody>
        </Card>
      </div>
    </>
  )
}

export default RegisterBot
