export const theraupatic_approaches = [
  {
    value: "Mindfulness-Based Practices (e.g., MBSR, MBCT)",
    label: "Mindfulness-Based Practices (e.g., MBSR, MBCT)",
  },
  {
    value: "Somatic/Body-Centered Therapy",
    label: "Somatic/Body-Centered Therapy",
  },
  {
    value: "Internal Family Systems (IFS)",
    label: "Internal Family Systems (IFS)",
  },
  {
    value: "Cognitive Behavioral Therapy (CBT)",
    label: "Cognitive Behavioral Therapy (CBT)",
  },
  {
    value: "Acceptance and Commitment Therapy (ACT)",
    label: "Acceptance and Commitment Therapy (ACT)",
  },
  { value: "Psychodynamic Therapy", label: "Psychodynamic Therapy" },
  { value: "Gestalt Therapy", label: "Gestalt Therapy" },
  { value: "Art Therapy", label: "Art Therapy" },
  { value: "Music Therapy", label: "Music Therapy" },
  { value: "Dance Therapy", label: "Dance Therapy" },
]

export const allExpertise = [
  { value: "Anxiety Disorders", label: "Anxiety Disorders" },
  { value: "Depression", label: "Depression" },
  { value: "Trauma / PTSD", label: "Trauma / PTSD" },
  { value: "Grief and Loss", label: "Grief and Loss" },
  { value: "Addiction", label: "Addiction" },
  { value: "Relationship Issues", label: "Relationship Issues" },
  { value: "End-of-Life Preparation", label: "End-of-Life Preparation" },
  { value: "Spiritual Exploration", label: "Spiritual Exploration" },
  { value: "Personal Growth", label: "Personal Growth" },
]
