import React from "react"
import logo from "../../../assets/images/ep_logo_trippy.png"
import { Link } from "react-router-dom"

function Drawer({ drawer, action }) {
  return (
    <>
      <div
        onClick={e => action(e)}
        className={`off_canvars_overlay ${drawer ? "active" : ""}`}
      ></div>
      <div className="offcanvas_menu">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className={`offcanvas_menu_wrapper ${drawer ? "active" : ""}`}
              >
                <div className="canvas_close">
                  <Link to="#" onClick={e => action(e)}>
                    <i className="fa fa-times"></i>
                  </Link>
                </div>
                <div className="offcanvas-brand text-center mb-2 flex justify-center items-center">
                  <img src={logo} alt="Mush Pro" height="80px" width="80px" />
                </div>
                <div id="menu" className="text-left ">
                  <ul className="offcanvas_main_menu">
                    <li id="home" className="menu-item-has-children active">
                      <Link to="#">Home</Link>
                    </li>
                    <li id="service" className="menu-item-has-children active">
                      <Link to="#">Service</Link>
                    </li>
                  </ul>
                </div>
                <div className="offcanvas-social">
                  <ul className="text-center">
                    <li>
                      <Link to="$">
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="$">
                        <i className="fab fa-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="$">
                        <i className="fab fa-instagram"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="$">
                        <i className="fab fa-dribbble"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Drawer
