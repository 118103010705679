import PropTypes from "prop-types"
import React from "react"
import { Route, Routes } from "react-router-dom"
import { connect } from "react-redux"
import { useRef } from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { Toaster } from "react-hot-toast"
import UserService from "KeyCloackServices/UserService"
// Import Routes all
import { userRoutes, authRoutes, proUserRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

import "react-toastify/dist/ReactToastify.css"
import "react-quill/dist/quill.snow.css"
import "react-quill/dist/quill.bubble.css"
import "react-phone-input-2/lib/style.css"

const App = props => {
  const Layout = VerticalLayout
  const queryClient = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnMount: false,
          refetchOnReconnect: false,
          refetchOnWindowFocus: false,
        },
      },
    }),
  )
  return (
    <React.Fragment>
      <QueryClientProvider client={queryClient.current}>
        <GoogleOAuthProvider clientId="392129545115-gujqk1tj92oer5in52ofk2n8ul6jn8vb.apps.googleusercontent.com">
          <Toaster />

          <Routes>
            {/* Non-authenticated routes */}
            {authRoutes.map((route, idx) => (
              <Route
                key={idx}
                path={route.path}
                element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              />
            ))}
            {/* Authenticated routes */}
            {UserService.getRole()?.roles?.includes("admin")
              ? userRoutes.map((route, idx) => (
                  <Route
                    key={idx}
                    path={route.path}
                    element={
                      <Authmiddleware>
                        <Layout>{route.component}</Layout>
                      </Authmiddleware>
                    }
                  />
                ))
              : proUserRoutes.map((route, idx) => (
                  <Route
                    key={idx}
                    path={route.path}
                    element={
                      <Authmiddleware>
                        <Layout>{route.component}</Layout>
                      </Authmiddleware>
                    }
                  />
                ))}
          </Routes>
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
