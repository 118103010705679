import { call, all, put, takeLatest } from "redux-saga/effects"
import * as actionTypes from "./actionTypes"
import { toast } from "react-hot-toast"
import apiClient from "helpers/apiClient"
import {
  getAccessToken,
  getBotAccessToken,
} from "helpers/jwt-token-access/accessToken"
import { LOGIN_BY_KC } from "store/auth/login/actionTypes"

async function shooterRegisterBot(userInfo) {
  return apiClient.post("/user/form-data", userInfo, {
    headers: { Authorization: getAccessToken() },
  })
}

function* registerBot() {
  return yield takeLatest(actionTypes.REGISTER_BOT_REQUEST, function* (action) {
    try {
      const data = yield call(shooterRegisterBot, action.payload)
      yield put({
        type: actionTypes.REGISTER_BOT_REQUEST_SUCCESS,
        payload: {
          ...data,
        },
      })
      yield put({
        type: LOGIN_BY_KC,
        payload: {
          ...data,
        },
      })
      toast.success("Your Bot Created Successfully.")
      // window.location.reload()
      let botUserId = localStorage.getItem("botUniqueUserId")
      yield put({
        type: actionTypes.GET_BOT_CHAT_HISTORY_REQUEST,
        payload: botUserId,
      })
    } catch (error) {
      action.helpers.setStatus({ success: false })
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data.message
          : error.message,
      )
      action.helpers.setErrors({
        submit: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
      })
      action.helpers.setSubmitting(false)
    }
  })
}
async function shooterUpdateBot(userInfo) {
  return apiClient.put("/user/update-bot", userInfo, {
    headers: { Authorization: getAccessToken() },
  })
}

function* updateBot() {
  return yield takeLatest(actionTypes.UPDATE_BOT_REQUEST, function* (action) {
    try {
      const data = yield call(shooterUpdateBot, action.payload)
      yield put({
        type: actionTypes.UPDATE_BOT_REQUEST_SUCCESS,
        payload: {
          ...data,
        },
      })
      yield put({
        type: actionTypes.GET_BOT_DETAILS_REQUEST,
      })
    } catch (error) {
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data.message
          : error.message,
      )
      action.helpers.setErrors({
        submit: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
      })
      action.helpers.setSubmitting(false)
    }
  })
}

async function shooterUpdateMycBot(userInfo) {
  return apiClient.put("/user/magic-myc", userInfo, {
    headers: { Authorization: getAccessToken() },
  })
}

function* updateBotMyc() {
  return yield takeLatest(
    actionTypes.UPDATE_BOT_MYC_REQUEST,
    function* (action) {
      try {
        const data = yield call(shooterUpdateMycBot, action.payload)
        yield put({
          type: actionTypes.UPDATE_BOT_MYC_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        })
        toast.success("Your Bot updated Successfully.")
      } catch (error) {
        action.helpers.setStatus({ success: false })
        toast.error(
          error?.response?.data?.message
            ? error?.response?.data.message
            : error.message,
        )
        action.helpers.setErrors({
          submit: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
        })
        action.helpers.setSubmitting(false)
      }
    },
  )
}

async function shooterGetBotChatHistory(newId) {
  return apiClient.post(
    `/chat/custom-message`,
    {
      uniqueId: newId,
    },
    {
      headers: { Authorization: getBotAccessToken() },
    },
  )
}

function* getBotChatHistory() {
  return yield takeLatest(
    actionTypes.GET_BOT_CHAT_HISTORY_REQUEST,
    function* (action) {
      try {
        const data = yield call(shooterGetBotChatHistory, action.payload)
        yield put({
          type: actionTypes.ADD_CHAT_BOT_MESSAGE,
          payload: {
            ...data,
          },
        })
      } catch (error) {
        console.log(error)
      }
    },
  )
}

async function shooterGetBotAnswer(message) {
  return apiClient.post(
    "/chat/custom-bot",
    {
      finalChat: message,
      uniqueId: localStorage.getItem("botUniqueUserId"),
    },
    { headers: { Authorization: getBotAccessToken() } },
  )
}

function* getBotAnswer() {
  return yield takeLatest(
    actionTypes.GET_BOT_ANSWER_REQUEST,
    function* (action) {
      try {
        yield put({
          type: actionTypes.ADD_BOT_MESSAGE,
          payload: action.payload,
        })
        const data = yield call(shooterGetBotAnswer, action.payload)
        yield put({
          type: actionTypes.ADD_CHAT_BOT_MESSAGE,
          payload: {
            ...data,
          },
        })
        // yield put({
        // 	type: actionTypes.GET_BOT_ANSWER_REQUEST_SUCCESS,
        // 	payload: {
        // 		...data,
        // 	},
        // });
      } catch (error) {
        yield put({
          type: actionTypes.GET_BOT_ANSWER_REQUEST_ERROR,
        })
        console.log(error)
      }
    },
  )
}

function* getBotAnswerAgain() {
  return yield takeLatest(
    actionTypes.GET_BOT_ANSWER_AGAIN_REQUEST,
    function* (action) {
      try {
        const data = yield call(shooterGetBotAnswer, action.payload)
        yield put({
          type: actionTypes.ADD_CHAT_BOT_MESSAGE,
          payload: {
            ...data,
          },
        })
      } catch (error) {
        yield put({
          type: actionTypes.GET_ANSWER_REQUEST_ERROR,
        })
        console.log(error)
      }
    },
  )
}

async function shooterhandleUploadFiles(file) {
  return apiClient.post("/user/image-uploader", file, {
    headers: {
      Authorization: getAccessToken(),
      "Content-type": "multipart/form-data",
    },
  })
}

function* handleUploadImages() {
  return yield takeLatest(actionTypes.UPLOAD_IMAGE_REQUEST, function* (action) {
    try {
      const data = yield call(shooterhandleUploadFiles, action.payload)
      action.callBack({ status: "success", returnData: data })
    } catch (error) {
      action.callBack({
        status: "error",
        returnData: error?.response?.data?.message
          ? error?.response?.data
          : error,
      })
    }
  })
}

async function shooterGetBotData() {
  return apiClient.post(
    `/user/findBot`,
    {},
    {
      headers: { Authorization: getAccessToken() },
    },
  )
}

function* getBotDetails() {
  return yield takeLatest(
    actionTypes.GET_BOT_DETAILS_REQUEST,
    function* (action) {
      try {
        const data = yield call(shooterGetBotData)
        yield put({
          type: actionTypes.GET_BOT_DETAILS_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        })
      } catch (error) {
        console.log(error)
      }
    },
  )
}

async function shooterGetBotMycData() {
  return apiClient.get(`/user/magic-myc`, {
    headers: { Authorization: getAccessToken() },
  })
}

function* getBotDataMyc() {
  return yield takeLatest(
    actionTypes.GET_BOT_DATA_MYC_REQUEST,
    function* (action) {
      try {
        const data = yield call(shooterGetBotMycData)
        yield put({
          type: actionTypes.GET_BOT_DATA_MYC_SUCCESS,
          payload: {
            ...data,
          },
        })
      } catch (error) {
        console.log(error)
      }
    },
  )
}
async function shooterBotDeleteChatHistory() {
  return apiClient.delete(`/chat/custom-message/all`, {
    headers: { Authorization: getBotAccessToken() },
    data: {
      uniqueId: localStorage.getItem("botUniqueUserId"),
    },
  })
}

function* deleteBotChatHistory() {
  return yield takeLatest(
    actionTypes.DELETE_BOT_CHAT_HISTORY_REQUEST,
    function* (action) {
      try {
        const data = yield call(shooterBotDeleteChatHistory)
        yield put({
          type: actionTypes.DELETE_BOT_CHAT_HISTORY_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        })
        let botUserId = localStorage.getItem("botUniqueUserId")
        yield put({
          type: actionTypes.GET_BOT_CHAT_HISTORY_REQUEST,
          payload: botUserId,
        })
      } catch (error) {
        console.log(error)
      }
    },
  )
}

function shooterGetBotQuickResponses() {
  return apiClient.get("/user/quick-replies/custom-bot", {
    headers: { Authorization: getBotAccessToken() },
  })
}

function* getBotQuickResponses() {
  return yield takeLatest(
    actionTypes.GET_BOT_QUICK_RESPONSES_REQUEST,
    function* (action) {
      try {
        const data = yield call(shooterGetBotQuickResponses)
        action.callBack({ status: "success", returnData: data })
      } catch (error) {
        console.log(error)
        action.callBack("error")
      }
    },
  )
}

async function shooterhandleUpgradeToPro() {
  return apiClient.post(
    "user/apply-for-pro",
    {},
    {
      headers: {
        Authorization: getAccessToken(),
      },
    },
  )
}

function* handleUpgradeToPro() {
  return yield takeLatest(
    actionTypes.UPGRADE_TO_PRO_REQUEST,
    function* (action) {
      try {
        const data = yield call(shooterhandleUpgradeToPro)
        action.callBack({ status: "success", returnData: data })
      } catch (error) {
        action.callBack({
          status: "error",
          returnData: error?.response?.data?.message
            ? error?.response?.data
            : error,
        })
      }
    },
  )
}

async function shooterEmbedding() {
  return apiClient.post(
    `/user/genEmbedding`,
    {},
    {
      headers: { Authorization: getAccessToken() },
    },
  )
}

function* Embedding() {
  return yield takeLatest(
    actionTypes.EMBEDDING_HISTORY_REQUEST,
    function* (action) {
      try {
        const data = yield call(shooterEmbedding)
        yield put({
          type: actionTypes.EMBEDDING_HISTORY_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        })
      } catch (error) {
        console.log(error)
        action.helpers.setStatus({ success: false })
        action.helpers.setErrors({
          submit: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
        })
        action.helpers.setSubmitting(false)
      }
    },
  )
}

export default function* runBlockingCallsExample() {
  yield all([
    registerBot(),
    updateBot(),
    getBotDetails(),
    updateBotMyc(),
    getBotChatHistory(),
    handleUploadImages(),
    getBotAnswer(),
    deleteBotChatHistory(),
    getBotDataMyc(),
    getBotQuickResponses(),
    getBotAnswerAgain(),
    handleUpgradeToPro(),
    Embedding(),
  ])
}
