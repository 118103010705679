import * as actionTypes from "./actionTypes"

export function getAnswer(data) {
  return {
    type: actionTypes.GET_ANSWER_REQUEST,
    payload: data,
  }
}

export function getAnswerAgain(data) {
  return {
    type: actionTypes.GET_ANSWER_AGAIN_REQUEST,
    payload: data,
  }
}

export function getChatHistory(chatId = "last") {
  return {
    type: actionTypes.GET_CHAT_HISTORY_REQUEST,
    payload: chatId,
  }
}

export function getMagicMycChatHistory(chatId = "last") {
  return {
    type: actionTypes.GET_CHAT_Magic_HISTORY_REQUEST,
    payload: chatId,
  }
}

export function deleteChatHistory() {
  return {
    type: actionTypes.DELETE_CHAT_HISTORY_REQUEST,
  }
}

export function cleanMessages() {
  return {
    type: actionTypes.CLEAR_MESSAGES_HISTORY,
  }
}

export function getMycQuickResponses(callBack) {
  return {
    type: actionTypes.GET_MYC_QUICK_RESPONSES_REQUEST,
    callBack,
  }
}
