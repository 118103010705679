import { call, all, put, takeLatest } from "redux-saga/effects"
import * as actionTypes from "./actionTypes"

import apiClient from "../../helpers/apiClient"
import { getAccessToken } from "../../helpers/jwt-token-access/accessToken"

async function shooterGetAllSubstances() {
  let response = await apiClient.get(`/user/substances/getAllSubstances`, {
    headers: { Authorization: getAccessToken() },
  })
  return response.data.records
}

function* getAllSubstances() {
  return yield takeLatest(actionTypes.GET_ALL_SUBSTANCES, function* (action) {
    try {
      const data = yield call(shooterGetAllSubstances, action.payload)
      yield put({
        type: actionTypes.GET_ALL_SUBSTANCES_SUCCESS,
        payload: [...data],
      })
    } catch (error) {
      console.log(error)
    }
  })
}

async function shooterGetAllConditions() {
  let response = await apiClient.get(`/user/substances/getAllConditions`, {
    headers: { Authorization: getAccessToken() },
  })
  return response.data.records
}

function* getAllConditions() {
  return yield takeLatest(actionTypes.GET_ALL_CONDITIONS, function* (action) {
    try {
      const data = yield call(shooterGetAllConditions, action.payload)
      yield put({
        type: actionTypes.GET_ALL_CONDITIONS_SUCCESS,
        payload: [...data],
      })
    } catch (error) {
      console.log(error)
    }
  })
}
async function shooterGetAllContraindications(data) {
  let response = await apiClient.get(
    `/user/substances/getAllContra?cp=${data?.cp ? data?.cp : 1}&ps=${
      data?.ps ? data?.ps : 11
    }`,
    {
      headers: { Authorization: getAccessToken() },
    },
  )
  return response.data.records
}

function* getAllContraindications() {
  return yield takeLatest(
    actionTypes.GET_ALL_CONTRAINIDATIONS,
    function* (action) {
      try {
        const data = yield call(shooterGetAllContraindications, action.payload)
        yield put({
          type: actionTypes.GET_ALL_CONTRAINIDATIONS_SUCCESS,
          payload: [...data],
        })
      } catch (error) {
        console.log(error)
      }
    },
  )
}

async function shooterGetAllGroups() {
  let response = await apiClient.get(`/user/substances/getAllGroups`, {
    headers: { Authorization: getAccessToken() },
  })
  return response.data.records
}

function* getAllGroups() {
  return yield takeLatest(actionTypes.GET_ALL_GROUPS, function* (action) {
    try {
      const data = yield call(shooterGetAllGroups, action.payload)
      yield put({
        type: actionTypes.GET_ALL_GROUPS_SUCCESS,
        payload: [...data],
      })
    } catch (error) {
      console.log(error)
    }
  })
}

export default function* runBlockingCallsExample() {
  yield all([
    getAllSubstances(),
    getAllConditions(),
    getAllGroups(),
    getAllContraindications(),
  ])
}
