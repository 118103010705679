import React from "react"
import PrefineryForm from "./PrefineryForm"

const style = {
  backgroundImg: {
    // backgroundImage: `url(${projectBj})`,
    background: "#ffffff",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "flow-root",
  },
}

function CallOfAction({ className }) {
  return (
    <>
      <section className={`appie-project-3-area ${className} `}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                style={style.backgroundImg}
                className="
                appie-project-3-box
                d-block d-md-flex
                flex-col
                justify-content-center
                align-items-center
                wow
                text-center
                animated
                fadeInUp
              "
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <div className=" sm:text-2xl text-base  mb-3">
                  <div className="appie-section-title text-center">
                    <h3 className="appie-title">Join the waitlist!</h3>
                  </div>
                  <p className="opacity-85  sm:text-2xl text-base font-bold">
                    Join the waitlist and be the first to transform your
                    coaching practice with Mush.Pro.
                  </p>
                </div>
                <PrefineryForm />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CallOfAction
