import axios from "axios"
import {
  getAccessToken,
  getBotAccessToken,
} from "./jwt-token-access/accessToken"

//pass new generated access token here
const token = getAccessToken()
const botToken = getBotAccessToken()

//apply base url for axios
export const API_URL = process.env.REACT_APP_API_BASE_URL

const axiosApi = axios.create({
  baseURL: API_URL,
})
const axiosApiJwt = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = token
axiosApiJwt.defaults.headers.common["Authorization"] = botToken

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error),
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function getBotMessages(url, data, config = {}) {
  return await axiosApiJwt
    .post(url, { uniqueId: data }, (config = {}))
    .then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(
      url,
      {
        firstName: data.firstName,
        phoneNumber: data.phoneNumber,
        email: data.email,
        password: data.password,
        confirmPassword: data.confirmPassword,
      },
      { ...config },
    )
    .then(response => response.data)
}
export async function postJwt(url, data, config = {}) {
  return axiosApi.post(url, {}, { ...config }).then(response => response.data)
}

export async function postForget(url, data, config = {}) {
  return axiosApi.post(url, data, { ...config }).then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

export async function findBot(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function uploadFiles(url, file) {
  let res = await axiosApi.post(url, file, {
    headers: {
      Authorization: getAccessToken(),
      "Content-type": "multipart/form-data",
    },
  })
  let response = res.data.imageUrl
  return response
}
