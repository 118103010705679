// Bot.js
import React, { useEffect, useRef, useState } from "react"
import { useMutation } from "react-query"
import { IoSend } from "react-icons/io5"
import "./Bot.css"
import { BotMessage, BotThinking } from "./BotThinking"
import http from "services/http-common"
import toast from "react-hot-toast"
import { useSelector } from "react-redux"
import { ArrowPathIcon } from "@heroicons/react/20/solid"
import { getBotDetails } from "store/bot/actions"
import { useDispatch } from "react-redux"
import { Col, Container, Row } from "reactstrap"
import parse from "html-react-parser"
import Appointment from "./Appointment"

const Bot = () => {
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.Login)
  const [suggestions, setSuggestions] = useState(null)
  const { botProfile } = useSelector(state => state.bot)
  const messagesEndRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [userMessage, setUserMessage] = useState({
    role: "Human",
    content: "",
  })
  const [allMessages, setAllMessages] = useState(null)
  const [isAppointment, setIsAppointment] = useState(false)

  const handleSubmit = () => {
    allMessages.push(userMessage)
    setLoading(true)
    scrollToBottom()
    postMessage.mutate(null)
  }

  const getAllMessages = useMutation(
    () => {
      return http.getPBotMessages(
        `/user/bots/getAllMessages`,
        { botId: botProfile?.id },
        null,
      )
    },
    {
      onSuccess: ({ data }) => {
        setAllMessages(data?.messages)
        setSuggestions(data?.questions ? data?.questions : null)
        setLoading(false)
      },
      onError: error => {
        toast.error(error.response.data.message)
        setLoading(false)
      },
      onMutate: () => {
        setLoading(true)
      },
      onSettled: () => {
        setLoading(false)
      },
    },
  )

  const postMessage = useMutation(
    test => {
      let content = test ? test : userMessage.content
      setUserMessage({
        role: "Human",
        content: "",
      })
      return http.postPBotMessages(
        `user/bots/writeMessage`,
        {
          botId: botProfile?.id,
          question: content,
          llm: localStorage.getItem("model").toLowerCase(),
        },
        null,
      )
    },
    {
      onSuccess: ({ data }) => {
        allMessages.push(data?.messages[1])
        setIsAppointment(
          data?.messages[1].bookAppointment
            ? data?.messages[1].bookAppointment
            : false,
        )
        setSuggestions(data?.questions ? data?.questions : null)
        scrollToBottom()

        setUserMessage({
          role: "Human",
          content: "",
        })
        setLoading(false)
      },
      onError: error => {
        toast.error(error.response.data.message)
        setLoading(false)
        setUserMessage({
          role: "Human",
          content: "",
        })
      },
      onMutate: () => {
        setLoading(true)
      },
      onSettled: () => {
        setLoading(false)
      },
    },
  )

  const handleKeyPress = event => {
    if (event.key === "Enter" && !event.shiftKey) {
      handleSubmit()
    }
  }

  const resetMessages = useMutation(
    async () => {
      return await http.delete("user/bots/resetMessages", null)
    },
    {
      onSuccess: () => {
        toast.success("Reset Successfully !")
        getAllMessages.mutate()
      },
      onError: error => {
        console.log(error)
      },
    },
  )

  const handleResetMessages = () => {
    resetMessages.mutate()
  }
  useEffect(() => {
    botProfile?.id && getAllMessages.mutate()
  }, [botProfile])

  const scrollToBottom = () => {
    messagesEndRef?.current.scroll({
      top: messagesEndRef?.current?.scrollHeight,
      behavior: "smooth",
    })
  }

  const onSubmit = text => {
    allMessages.push({
      role: "Human",
      content: text,
    })
    postMessage.mutate(text)
  }

  useEffect(() => {
    scrollToBottom()
  }, [loading])

  useEffect(() => {
    dispatch(getBotDetails())
  }, [])

  return (
    <>
      <div className=" rounded-md">
        <div className={`chatbot  w-auto `}>
          <div className="chat-header">
            <div className="headTop flex justify-between items-center">
              <div className="avatars-wrapper  operators-avatar-1 flex justify-start !w-full">
                <div className="header-ava">
                  <img
                    src={botProfile ? botProfile?.iconUrl : ""}
                    alt="logo"
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <h2 className="oneline ms-3 ">
                  <span>Chat with </span>
                  <br />
                  <span id="bot-name">
                    {botProfile ? botProfile?.botName + "!" : ""}
                  </span>
                </h2>
              </div>
            </div>
            <div className="offline-message">
              <span>
                <span>We typically reply within a few minutes.</span>
              </span>
            </div>
          </div>
          <ul
            className="chatbox"
            id="suggestions-container"
            ref={messagesEndRef}
          >
            {allMessages?.map((message, index) => {
              return (
                <>
                  {message.role === "assistant" ? (
                    <li class="chat incoming">
                      <span class="material-symbols-outlined">
                        <img
                          src={botProfile?.iconUrl}
                          alt="ico-mushgpt"
                          className="w-full"
                        />
                      </span>
                      <BotMessage
                        message={message.content}
                        messageIndex={index}
                        allMessages={allMessages}
                        questionId={message?.id && message?.id}
                        question={message?.question ? message?.question : ""}
                        refer={
                          message?.refer ? JSON.parse(message.refer) : null
                        }
                      />
                      {isAppointment && allMessages.length - 1 === index && (
                        <Appointment />
                      )}
                    </li>
                  ) : (
                    <li class="chat outgoing ">
                      <span>
                        <img
                          src={
                            user.avatar
                              ? user.avatar
                              : "https://chat.mushgpt.com/images/seeker.png"
                          }
                          alt="ico-mushgpt"
                          className="rounded-circle !h-[inherit] "
                        />
                      </span>
                      <p>{message.content}</p>
                    </li>
                  )}
                </>
              )
            })}
            {loading && <BotThinking />}

            {/* <div className="my-4">
            {!loading &&
              suggestions &&
              suggestions.map(item => (
                <li class="chat suggestions opacity-70 ">
                  <span className="!text-sm" onClick={() => onSubmit(item)}>
                    {item}
                  </span>
                </li>
              ))}
          </div> */}
          </ul>
          <div>
            <div className="flex sm:justify-normal justify-normal items-center gap-2 px-2 bg-[#ffffff] py-2 w-full overflow-x-auto  whitespace-nowrap ">
              {!loading &&
                suggestions &&
                suggestions?.map((item, idx) => {
                  return (
                    <>
                      <span onClick={() => onSubmit(item)} key={idx}>
                        <button
                          className=" truncate h-auto p-[10px] opacity-70  text-start text-ellipsis overflow-hidden bg-[#f2f2f7] inline-flex items-center justify-center text-sm transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-80 bg-zinc-900 text-zinc-50 hover:bg-zinc-800/90 dark:bg-zinc-50 dark:text-zinc-900 dark:hover:bg-zinc-50/90 rounded-md px-3 whitespace-nowrap font-normal shadow-none group-[.cb-dark]:bg-zinc-800 group-[.cb-dark]:text-white group-[.cb-dark]:hover:bg-zinc-700 group-[.cb-light]:bg-zinc-200/50 group-[.cb-light]:text-black group-[.cb-light]:hover:bg-zinc-200"
                          type="button"
                        >
                          <p className="truncate sm:w-auto ">
                            <p className="truncate sm:w-auto ">{item}</p>
                          </p>
                        </button>
                      </span>
                    </>
                  )
                })}
            </div>
            <div className="chat-input">
              <ArrowPathIcon
                width={32}
                className=" cursor-pointer"
                onClick={handleResetMessages}
              />
              <textarea
                value={userMessage.content}
                onChange={e =>
                  setUserMessage({ ...userMessage, content: e.target.value })
                }
                onKeyDown={handleKeyPress}
                placeholder="Send a message"
                className="overflow-hidden px-2"
                required
              ></textarea>
              <span
                id="send-btn"
                type="submit"
                className="flex justify-center items-center"
                onClick={handleSubmit}
              >
                <IoSend />
              </span>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col sm={12}>
              <span className="d-sm-inline-block">
                {botProfile?.footerNote && parse(botProfile?.footerNote)}
              </span>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  )
}

export default Bot
