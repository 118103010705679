import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"

//i18n
import { withTranslation } from "react-i18next"
import { setSessionExpired, useMainController } from "context"
import { useMutation } from "react-query"
import http from "services/http-common"
import toast from "react-hot-toast"
import { useSelector } from "react-redux"

const NotificationDropdown = props => {
  const { user } = useSelector(state => state.Login)
  const { notifications } = useSelector(state => state.Profile)
  const [menu, setMenu] = useState(false)
  const [dispatch] = useMainController()
  const [allNotifications, setAllNotifications] = useState(null)

  const getNotifications = useMutation(
    () => {
      return http.get(
        `https://dev-api.mush.pro/user/notifications/fetch?code=${user.id}`,
        null,
        setSessionExpired,
        dispatch,
      )
    },
    {
      onSuccess: ({ data }) => {
        let unreadNotifications = data.notifications.filter(
          notification => !notification.clicked,
        )
        setAllNotifications(unreadNotifications)
      },
      onError: error => {
        toast.error(error.response.data.message)
      },
    },
  )

  const getAlerts = useCallback(() => {
    getNotifications.mutate()
  }, [])

  useEffect(() => {
    notifications?.length === 0
      ? getAlerts()
      : setAllNotifications(
          notifications.filter(notification => !notification.clicked),
        )
  }, [notifications])
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block ms-1"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="ti-bell"></i>
          <span className="badge text-bg-danger rounded-pill">
            {allNotifications && allNotifications?.length}
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h5 className="m-0">
                  {" "}
                  {props.t("Notifications")} (
                  {allNotifications && allNotifications?.length}){" "}
                </h5>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {allNotifications &&
              allNotifications.map(notification => (
                <Link
                  key={notification.id}
                  to="#"
                  className="text-reset notification-item w-full"
                >
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <div className="avatar-xs me-3">
                        <span className="avatar-title border-warning rounded-circle ">
                          <i className="mdi mdi-message"></i>
                        </span>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mt-0 mb-1">{notification.title}</h6>
                      <div className="text-muted">
                        <p className="mb-1 text-xs">
                          {notification.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to="/alerts"
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              {props.t("View all")}{" "}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
