import * as actionTypes from "./actionTypes"

export const getUserInfo = (accessToken, history) => {
  return {
    type: actionTypes.GET_USER_INFO_REQUEST,
    payload: { accessToken, history },
  }
}

export const loginUser = (user, history) => {
  return {
    type: actionTypes.LOGIN_USER,
    payload: { user, history },
  }
}
export const loginByKcId = () => {
  return {
    type: actionTypes.LOGIN_BY_KC,
  }
}
export const getEmbeddingToken = () => {
  return {
    type: actionTypes.GET_EMBEDDED_TOKEN,
  }
}

export const loginSuccess = user => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload: user,
  }
}

export function signInGoogle(data, navigate) {
  return {
    type: actionTypes.POST_GOOGLE_SIGNIN_REQUEST,
    payload: data.userinfo,
    navigate,
  }
}
export const profileSuccess = data => {
  return {
    type: actionTypes.PROFILE_SUCCESS,
    payload: data,
  }
}

export function resetPass(userInfo, history, helpers) {
  return {
    type: actionTypes.POST_RESET_PASSWORD_REQUEST,
    payload: userInfo,
    history,
    helpers,
  }
}

export const logoutUser = history => {
  return {
    type: actionTypes.LOGOUT_USER,
    payload: { history },
  }
}

export const apiError = error => {
  return {
    type: actionTypes.API_ERROR,
    payload: error,
  }
}
