import { useEffect, useState } from "react"
import { Button } from "@material-tailwind/react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"
import { Container, Row, Col, Label } from "reactstrap"
import * as messages from "services/messages"
import { useMutation } from "react-query"
import { httpForMushGpt } from "services/http-common"
import { toast } from "react-hot-toast"
import { LoadingText } from "pages/Loading/Loading"

function GenerateSummayQA(props) {
  const { open, toogleOpenEditor, id } = props
  const [scrapedContent, setScrapedContent] = useState(null)
  const [loading, setLoading] = useState(false)

  const errorFunc = error => {
    let message
    switch (error.response.status) {
      case 500: {
        message = messages.SERVER_ERROR
        break
      }
      case 404: {
        message = messages.NOT_FOUND_ERROR
        break
      }
      case 400: {
        message = messages.BAD_REQUEST
        break
      }
      default: {
        message = error.message
      }
    }
    toast.error(message)
  }

  const generateConenten = useMutation(
    docId => {
      return httpForMushGpt.post(`llms/generate-metadata`, { ids: [docId] })
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message)
        getDocDetailFunc()
      },
      onError: error => {
        errorFunc(error)
      },
      onMutate: () => {
        setLoading(true)
      },
      onSettled: () => {
        setLoading(false)
      },
    },
  )

  const toogleOpen = () => {
    if (loading) return false
    if (open) {
      setScrapedContent("")
    }
    toogleOpenEditor()
  }

  const onSubmit = () => {
    generateConenten.mutate(id)
  }

  const getDocDetails = useMutation(
    docId => {
      return httpForMushGpt.post(`llms/fetchQAs`, { docId: [docId] })
    },
    {
      onSuccess: ({ data }) => {
        // console.log(data.records)
        setScrapedContent(data.records)
      },
      onError: error => {
        toast.error(error.response.data.message)
      },
      onSettled: () => {
        setLoading(false)
      },
      onMutate: () => {
        setLoading(true)
      },
    },
  )

  const getDocDetailFunc = () => {
    if (id === null) return false
    getDocDetails.mutate(id)
  }

  useEffect(() => {
    id && getDocDetailFunc()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      onClose={toogleOpen}
      scroll={"paper"}
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0.9, y: -100 },
      }}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Document: QAs</DialogTitle>
      <DialogContent dividers={"paper"}>
        {scrapedContent ? (
          <Container>
            <Row className="flex flex-col justify-start my-2">
              <Col>
                <div className="mb-3 flex flex-col justify-start items-start">
                  <Label htmlFor="QA">Questions and Answers:</Label>
                  <span>
                    {scrapedContent ? (
                      <>
                        <ul className="list-[auto] ms-3">
                          {scrapedContent.map((item, idx) => (
                            <li>
                              <div className="flex flex-col justify-start items-start my-1">
                                <span>Question: {item.question}</span>
                                <span>Answer: {item.answer}</span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : (
                      "No QA"
                    )}
                  </span>
                </div>
              </Col>
            </Row>
          </Container>
        ) : (
          <LoadingText count={5} />
        )}
      </DialogContent>
      <DialogActions className="space-x-2">
        <Button
          variant="text"
          size="sm"
          color="gray"
          className="mr-1 rounded-md waves-effect waves-light"
          onClick={toogleOpen}
          disabled={loading}
        >
          <span>Close</span>
        </Button>
        <Button
          size="sm"
          className="rounded-md waves-effect waves-light !bg-[#7a6fbe]"
          onClick={onSubmit}
          disabled={loading}
        >
          <span>Regenerate</span>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default GenerateSummayQA
