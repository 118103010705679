import * as actionTypes from './actionTypes';


const initialState = {
	isLoading: false,
	pdfdata: {
		data: [],
		currentPage: 1,
		pageSize: 50,
		total: 0,
	},
	myDocs: {
		data: [],
		currentPage: 1,
		pageSize: 50,
		total: 0,
	},
	websiteData: { data: [], currentPage: 1, pageSize: 50, total: 0 },
	myWebsites: { data: [], currentPage: 1, pageSize: 50, total: 0 },
};

const reducer = (
	prevState = initialState,
	action
) => {
	switch (action.type) {
		case actionTypes.GET_PDFS_REQUEST_SUCCESS:
			return {
				...prevState,
				isLoading: false,
				pdfdata: {
					data: [...action.payload?.data?.docs],
					currentPage: action.payload?.data?.currentPage,
					pageSize: action.payload?.data?.pageSize,
					total: action.payload?.data?.total,
				},
			};
		case actionTypes.GET_MYDOCS_REQUEST_SUCCESS:
			return {
				...prevState,
				isLoading: false,
				myDocs: {
					data: [...action.payload?.data?.docs],
					currentPage: action.payload?.data?.currentPage,
					pageSize: action.payload?.data?.pageSize,
					total: action.payload?.data?.total,
				},
			};

		case actionTypes.GET_WEBSITES_REQUEST_SUCCESS:
			return {
				...prevState,
				isLoading: false,
				websiteData: {
					data: [...action.payload?.data?.websites],
					currentPage: action.payload?.data?.currentPage,
					pageSize: action.payload?.data?.pageSize,
					total: action.payload?.data?.total,
				},
			};

		case actionTypes.GET_MYWEBSITES_REQUEST_SUCCESS:
			return {
				...prevState,
				isLoading: false,
				myWebsites: {
					data: [...action.payload?.data?.websites],
					currentPage: action.payload?.data?.currentPage,
					pageSize: action.payload?.data?.pageSize,
					total: action.payload?.data?.total,
				},
			};
		case actionTypes.DELETE_BOT_CHAT_HISTORY_REQUEST_SUCCESS:
			return {
				end: true,
				messages: [],
			};

		default:
			return prevState;
	}
};

export default reducer;
