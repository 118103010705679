import { useId } from "react"
import { Typography } from "@material-tailwind/react"

export function LoadingText({ count }) {
  const uniqueId = useId()
  return (
    <div className="w-full animate-pulse">
      <Typography
        as="div"
        variant="h1"
        className="mb-4 h-3 w-2/3 rounded-full bg-gray-300"
      >
        &nbsp;
      </Typography>
      {Array.from({ length: count }, (value, index) => index).map(itm => {
        return (
          <Typography
            key={uniqueId}
            as="div"
            variant="paragraph"
            className="mb-2 h-2 w-full rounded-full bg-gray-300"
          >
            &nbsp;
          </Typography>
        )
      })}
    </div>
  )
}
