import { Button } from "@material-tailwind/react"
import { setSessionExpired, useMainController } from "context"
import React, { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useMutation } from "react-query"
import { Table, Card, CardBody, Row, Col, Spinner } from "reactstrap"
import http from "services/http-common"

document.title = "Entheo.Pro by The Entheology Project"

const Availability = () => {
  const [dispatch] = useMainController()
  const [workingHours, setWorkingHours] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isApiLoading, setIsApiLoading] = useState(true)

  const getAvailability = useMutation(
    () => {
      return http.get(
        "user/scheduling/availability",
        null,
        setSessionExpired,
        dispatch,
      )
    },
    {
      onSuccess: ({ data }) => {
        setWorkingHours(data.availability)
        setIsApiLoading(false)
      },
      onError: error => {
        toast.error(error.response.data.message)
        setIsApiLoading(false)
      },
    },
  )
  const updateAvailability = useMutation(
    () => {
      return http.post("user/scheduling/availability", workingHours, null)
    },
    {
      onSuccess: ({ data }) => {
        setWorkingHours(data.availability)
        toast.success(data.message)
        setLoading(false)
      },
      onError: error => {
        toast.error(error.response.data.message)
        setLoading(false)
      },
    },
  )
  const onChangeStartTime = (currentDay, newStartTime) => {
    setWorkingHours(prevAvailability =>
      prevAvailability.map(day =>
        day.dayOfWeek === currentDay
          ? { ...day, startTime: newStartTime }
          : day,
      ),
    )
  }
  const onChangeEndTime = (currentDay, newEndTime) => {
    setWorkingHours(prevAvailability =>
      prevAvailability.map(day =>
        day.dayOfWeek === currentDay ? { ...day, endTime: newEndTime } : day,
      ),
    )
  }
  const handleAvalability = (currentDay, startTime, endTime) => {
    if (startTime === "00:00:00" && endTime === "00:00:00") {
      setWorkingHours(prevAvailability =>
        prevAvailability.map(day =>
          day.dayOfWeek === currentDay
            ? { ...day, endTime: "09:00:00", startTime: "12:00:00" }
            : day,
        ),
      )
    } else {
      setWorkingHours(prevAvailability =>
        prevAvailability.map(day =>
          day.dayOfWeek === currentDay
            ? { ...day, endTime: "00:00:00", startTime: "00:00:00" }
            : day,
        ),
      )
    }
  }

  const onSubmit = () => {
    setLoading(true)
    updateAvailability.mutate()
  }
  useEffect(() => {
    getAvailability.mutate()
  }, [])

  return (
    <React.Fragment>
      <Row>
        <Col xl="5">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4 font-bold">Working hours</h4>
              {isApiLoading ? (
                <Spinner
                  color="primary"
                  className="position-absolute top-50 start-50"
                />
              ) : (
                <div className="table-responsive">
                  <Table className="align-middle table-centered table-vertical table-nowrap">
                    <tbody>
                      {workingHours?.map((workingHour, key) => (
                        <tr key={key}>
                          <td>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                onClick={() => {
                                  handleAvalability(
                                    workingHour.dayOfWeek,
                                    workingHour.startTime,
                                    workingHour.endTime,
                                  )
                                }}
                                defaultChecked={
                                  workingHour.startTime !== "00:00:00" &&
                                  workingHour.endTime !== "00:00:00"
                                }
                                className="form-check-input"
                                id={workingHour.dayOfWeek}
                              />
                              <label
                                className="form-check-label font-bold"
                                htmlFor={workingHour.dayOfWeek}
                              >
                                {workingHour.dayOfWeek}
                              </label>
                            </div>
                          </td>
                          <td>
                            {workingHour.startTime !== "00:00:00" &&
                            workingHour.endTime !== "00:00:00" ? (
                              <div className="flex justify-start items-center gap-2">
                                <input
                                  className="form-control w-auto"
                                  type="time"
                                  onChange={e => {
                                    onChangeStartTime(
                                      workingHour.dayOfWeek,
                                      e.target.value,
                                    )
                                  }}
                                  defaultValue={workingHour.startTime}
                                  id="example-time-input"
                                />{" "}
                                {" - "}
                                <input
                                  className="form-control w-auto"
                                  type="time"
                                  onChange={e => {
                                    onChangeEndTime(
                                      workingHour.dayOfWeek,
                                      e.target.value,
                                    )
                                  }}
                                  defaultValue={workingHour.endTime}
                                  id="example-time-input"
                                />
                              </div>
                            ) : (
                              <span className={"badge bg-dark "}>
                                Unavailable
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div className="flex justify-end">
                    <Button
                      size="sm"
                      className="flex gap-3 py-2 px-4 bg-[#7a6fbe]"
                      disabled={loading}
                      onClick={onSubmit}
                    >
                      Save Now
                    </Button>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Availability
