import axios from "axios"
import {
  post,
  del,
  get,
  put,
  findBot,
  postJwt,
  getBotMessages,
  uploadFiles,
  postForget,
} from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}
// Register Method
// const postRegister = async data => {
//   return axios
//     .post(url.POST_REGISTER, data)
//     .then(response => {
//       if (response.status >= 200 || response.status <= 299) return response.data
//       throw response.data
//     })
//     .catch(err => {
//       let message
//       if (err.response && err.response.status) {
//         switch (err.response.status) {
//           case 404:
//             message = "Sorry! the page you are looking for could not be found"
//             break
//           case 500:
//             message =
//               "Sorry! something went wrong, please contact our support team"
//             break
//           case 401:
//             message = "Invalid credentials"
//             break
//           default:
//             message = err[1]
//             break
//         }
//       }
//       throw message
//     })
// }
const postRegister = async data => post(url.POST_REGISTER, data)

// Login Method
const postLogin = data => post(url.POST_LOGIN, data)
const postJwtToken = data => postJwt(url.POST_JWT_Token)

// Verify Account
const getVerify = data => get(url.POST_VERIFY_LOGIN, data)

// postForgetPwd
const postForgetPwd = data => postForget(url.POST_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)
const postProfile = data => put(url.UPDATE_PROFILE, data.user)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postJwtLogin = data => post(url.POST_LOGIN, data)

export const getBot = () => findBot(url.GET_BOT)

// get messages
export const getMessages = (uniqueId = "17055600") =>
  getBotMessages(url.GET_BOT_MESSAGES, uniqueId)

export const addMessage = message => post(url.ADD_MESSAGE, message)

export const fileUpload = data => {
  uploadFiles(url.UPLOAD_FILE, data)
}

// delete message
export const deleteMessage = data =>
  del(url.DELETE_MESSAGE, { headers: { data } })

// export const getUserProfile = () => get(url.GET_USER_PROFILE)

// get Events
export const getEvents = () => get(url.GET_EVENTS)

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } })

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES)

export {
  getLoggedInUser,
  isUserAuthenticated,
  postRegister,
  postLogin,
  postJwtToken,
  getVerify,
  postFakeProfile,
  postProfile,
  postForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtProfile,
}
