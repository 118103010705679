import { useCallback, useEffect, useState } from "react"
import { setSessionExpired, useMainController } from "context"
import { useMutation } from "react-query"
import {
  Typography,
  Tooltip,
  IconButton,
  Card,
  CardHeader,
  CardBody,
} from "@material-tailwind/react"
import { FaTrashAlt } from "react-icons/fa"
import { httpForMushGpt } from "services/http-common"
import EditLLM from "./EditLLM"
import AddLLM from "./AddLLM"
import toast from "react-hot-toast"
import { Badge } from "reactstrap"

const AllLLMs = props => {
  const [dispatch] = useMainController()
  const [allLLMs, setAllLLMs] = useState(null)

  const getPrompt = useMutation(
    () => {
      return httpForMushGpt.post("llms/getall", {}, setSessionExpired, dispatch)
    },
    {
      onSuccess: ({ data }) => {
        setAllLLMs(data.records)
      },
      onError: error => {
        console.error(error)
      },
    },
  )

  const getLLMs = useCallback(() => {
    getPrompt.mutate()
  }, [])

  const deleteLlm = useMutation(
    async llmId => {
      return await httpForMushGpt.deleteLlm("llms/delete", {
        data: {
          id: llmId,
        },
      })
    },
    {
      onSuccess: () => {
        toast.success("Successfully Deleted!")
        getLLMs()
      },
      onError: error => {
        console.error(error.response.data)
      },
    },
  )

  const onDeleteFaq = id => {
    deleteLlm.mutate(id)
  }

  useEffect(() => {
    getLLMs()
  }, [])

  return (
    <>
      <div className="mt-4 mb-8 flex flex-col gap-12">
        <Card>
          <CardHeader color="gray" className="mb-8 p-6 bg-[#887bd9]">
            <Typography variant="h6" color="white">
              LLMs
            </Typography>
          </CardHeader>
          <CardBody className="overflow-x-auto px-2 pt-0 pb-2">
            <div className="flex w-full justify-center">
              <div className="flex w-full flex-col gap-4">
                <div className="flex justify-end gap-6 px-4">
                  <AddLLM getLLMs={getLLMs} />
                </div>
                <div>
                  {allLLMs && (
                    <table className="mt-4 w-full min-w-max table-auto text-left">
                      <tbody>
                        {allLLMs?.map((item, index) => {
                          return (
                            <tr key={item.id}>
                              <td
                                className={
                                  "!py-1 !px-2 border-b border-t border-blue-gray-50"
                                }
                              >
                                <div className="flex items-center gap-3">
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-bold"
                                    >
                                      <Badge
                                        className={
                                          item?.isEnabled
                                            ? "mx-2 bg-success"
                                            : "mx-2 bg-danger"
                                        }
                                      >
                                        {item?.isEnabled
                                          ? "Enabled"
                                          : "Disabled"}
                                      </Badge>

                                      {item.name}
                                      {item.status === "private" &&
                                        "  (Private)"}
                                    </Typography>
                                  </div>
                                </div>
                              </td>
                              <td
                                className={
                                  "flex justify-end items-center !p-1 border-b border-t border-blue-gray-50 "
                                }
                              >
                                <EditLLM llm={item} getLLMs={getLLMs} />
                                <Tooltip content="Delete">
                                  <IconButton
                                    variant="text"
                                    onClick={() => onDeleteFaq(item.id)}
                                  >
                                    <FaTrashAlt className="h-5 w-5 text-[#ec4561]" />
                                  </IconButton>
                                </Tooltip>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  )
}

export default AllLLMs
