import * as actionTypes from "./actionTypes"

// ** Import type

const initialState = {
  end: true,
  loading: false,
  allSubstances: [],
  allConditions: [],
  allContraindications: [],
  allGroups: [],
}

const reducer = (prevState = initialState, action) => {
  switch (action.type) {
    // ** When user press enterKey or send message button
    case actionTypes.GET_ALL_SUBSTANCES_SUCCESS:
      let substances = []
      action.payload?.map(_item => {
        if (_item.role !== "user") {
          substances.push({
            id: _item.id,
            label: _item.substanceName,
            value: _item.substanceName,
          })
        }
        return []
      })
      return {
        ...prevState,
        allSubstances: substances,
      }

    case actionTypes.GET_ALL_CONDITIONS_SUCCESS:
      let conditions = []
      action.payload?.map(_item => {
        if (_item.role !== "user") {
          conditions.push({
            id: _item.id,
            label: _item.condition,
            value: _item.condition,
          })
        }
        return []
      })
      return {
        ...prevState,
        allConditions: conditions,
      }
    case actionTypes.GET_ALL_CONTRAINIDATIONS_SUCCESS:
      return {
        ...prevState,
        allContraindications: action.payload,
      }
    case actionTypes.GET_ALL_GROUPS_SUCCESS:
      let groups = []
      action.payload?.map(_item => {
        if (_item.role !== "user") {
          groups.push({
            id: _item.id,
            label: _item.groupName,
            value: _item.groupName,
          })
        }
        return []
      })
      return {
        ...prevState,
        allGroups: groups,
      }
    case actionTypes.DELETE_ALL_CONTRAINIDATIONS:
      return {
        ...prevState,
        allSubstances: [],
        allConditions: [],
        allContraindications: [],
        allGroups: [],
      }

    default:
      return prevState
  }
}

export default reducer
