import React, { useCallback, useEffect, useState } from "react"

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import { useSelector } from "react-redux"
import {
  CardFooter,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  UncontrolledTooltip,
} from "reactstrap"
import { timeAgo } from "lib/util"
import { useDispatch } from "react-redux"
import { getAllNotifications, updateNotification } from "store/actions"

const Alerts = props => {
  const dispatch = useDispatch()
  const { notifications } = useSelector(state => state.Profile)
  const [allNotifications, setAllNotifications] = useState(null)
  const [loading, setLoading] = useState(false)
  document.title = "Alerts | Entheo.Pro by The Entheology Project"

  const getAlerts = useCallback(() => {
    dispatch(getAllNotifications())
  }, [])
  const updateAlert = notification => {
    dispatch(
      updateNotification(
        {
          id: notification.id,
          clicked: !notification.clicked,
        },
        setLoading,
      ),
    )
  }

  useEffect(() => {
    notifications?.length === 0
      ? getAlerts()
      : setAllNotifications(notifications)
  }, [notifications])

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card className="shadow">
            <CardBody>
              <CardTitle className="h4">All Notifications </CardTitle>
              <hr />
              {allNotifications && (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Alert</th>
                      <th scope="col">Date</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allNotifications.map(notification => (
                      <tr key={notification.id}>
                        <th
                          scope="row"
                          className={notification.clicked && "!bg-[#f2f6fc]"}
                        >
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">
                                {notification.title}
                              </span>
                              <div className="flex-grow-1">
                                <div className="text-muted">
                                  <p className="mb-1">
                                    {notification.description}
                                  </p>
                                </div>
                              </div>
                            </Media>
                          </Media>
                        </th>
                        <td className={notification.clicked && "!bg-[#f2f6fc]"}>
                          {timeAgo(notification.created_at)}
                        </td>
                        <td className={notification.clicked && "!bg-[#f2f6fc]"}>
                          {notification.clicked ? (
                            <>
                              <i
                                className="ion ion-md-mail-unread !text-xl text-primary  cursor-pointer"
                                id={`unread${notification.id}`}
                                onClick={() => updateAlert(notification)}
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target={`unread${notification.id}`}
                              >
                                Mark as unread
                              </UncontrolledTooltip>
                            </>
                          ) : (
                            <>
                              <i
                                className="ion ion-md-mail-open !text-xl text-primary cursor-pointer"
                                id={`read${notification.id}`}
                                onClick={() => updateAlert(notification)}
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target={`read${notification.id}`}
                              >
                                Mark as read
                              </UncontrolledTooltip>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </CardBody>
            <CardFooter className="py-4">
              <nav aria-label="...">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem className="disabled">
                    <PaginationLink
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                      tabIndex="-1"
                    >
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">Previous</span>
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem className="active">
                    <PaginationLink
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      1
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      2 <span className="sr-only">(current)</span>
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      3
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">Next</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}
export default Alerts
