import React, { useEffect, useState } from "react"
import { Modal } from "reactstrap"
import * as messages from "services/messages"
import { useMutation } from "react-query"
import http from "services/http-common"
import { toast } from "react-toastify"
import Select from "react-select"
import { useSelector } from "react-redux"
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { getAllContraindications } from "store/actions"
import { useDispatch } from "react-redux"

function AddContraindication({ substanceId }) {
  const dispatch = useDispatch()
  const { allSubstances, allConditions, allGroups } = useSelector(
    state => state.Contraindication,
  )
  const [activeTab, setActiveTab] = useState("1")
  const [open, setOpen] = React.useState(false)
  const [selectedSubstances, setSelectedSubstances] = useState(null)
  const [selectedConditions, setSelectedConditions] = useState(null)
  const [selectedGroups, setSelectedGroups] = useState(null)
  const [loading, setLoading] = useState(false)
  const [content, setContent] = useState({
    substanceIds: "",
    conditionIds: "",
    groupIds: "",
    substanceId: "",
  })

  const handleOpen = () => {
    setOpen(!open)
  }

  const errorFunc = error => {
    let message
    switch (error.response.status) {
      case 500: {
        message = messages.SERVER_ERROR
        break
      }
      case 404: {
        message = messages.NOT_FOUND_ERROR
        break
      }
      case 400: {
        message = messages.BAD_REQUEST
        break
      }
      default: {
        message = error.message
      }
    }
    toast.error(message)
  }

  const onSubmit = () => {
    setLoading(true)
    if (selectedSubstances || selectedConditions || selectedGroups) {
      CreateConditions.mutate()
    }
  }
  const CreateConditions = useMutation(
    () => {
      return http.post(`user/substances/contragrouping`, content, null)
    },
    {
      onSuccess: ({ data }) => {
        // toast.success("Successfully Added!")
        setOpen(!open)
        dispatch(getAllContraindications())
        setLoading(false)
      },
      onError: error => {
        errorFunc(error)
        setLoading(false)
      },
      onMutate: () => {
        setLoading(true)
      },
      onSettled: () => {
        setLoading(false)
      },
    },
  )

  function handleSubstance(values) {
    setSelectedSubstances(values)
    setContent(prevState => ({
      ...prevState,
      substanceIds: values?.map(obj => obj.id),
    }))
  }
  function handleConditions(values) {
    setSelectedConditions(values)
    setContent(prevState => ({
      ...prevState,
      conditionIds: values?.map(obj => obj.id),
    }))
  }
  function handleGroups(values) {
    setSelectedGroups(values)
    setContent(prevState => ({
      ...prevState,
      groupIds: values?.map(obj => obj.id),
    }))
  }

  useEffect(() => {
    setContent(prevState => ({
      ...prevState,
      substanceId: substanceId,
    }))
  }, [substanceId])

  return (
    <>
      <button
        color="secondary"
        size="sm"
        className="btn btn-primary waves-effect !w-max waves-light"
        onClick={handleOpen}
      >
        Add Data
      </button>

      <Modal
        isOpen={open}
        size="md"
        toggle={() => {
          handleOpen()
        }}
        centered={true}
      >
        <div className="modal-body w-full flex flex-col justify-center">
          <Card className="!mb-0">
            <CardBody>
              <Nav pills className="navtab-bg nav-justified">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={`${activeTab === "1" && "active"}`}
                    onClick={() => setActiveTab("1")}
                  >
                    Add Substances
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={`${activeTab === "2" && "active"}`}
                    onClick={() => setActiveTab("2")}
                  >
                    Add Condition
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={`${activeTab === "3" && "active"}`}
                    onClick={() => setActiveTab("3")}
                  >
                    Add Groups
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab} className="p-3 text-muted">
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <div className="modal-body w-full flex flex-col justify-center">
                        {allSubstances && (
                          <div className="w-full ">
                            <div className="mb-3">
                              <label className="control-label">
                                Select Substances
                              </label>
                              <Select
                                isMulti={true}
                                name="colors"
                                options={allSubstances}
                                className="basic-multi-select select2-selection"
                                onChange={v => {
                                  handleSubstance(v)
                                }}
                                classNamePrefix="select"
                              />
                            </div>
                          </div>
                        )}

                        <button
                          size="sm"
                          className="btn btn-primary py-2 px-4 mt-2 "
                          onClick={onSubmit}
                          disabled={loading}
                        >
                          Submit
                        </button>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <div className="modal-body w-full flex flex-col justify-center">
                        {allConditions && (
                          <div className="w-full ">
                            <div className="mb-3">
                              <label className="control-label">
                                Select Conditions
                              </label>
                              <Select
                                isMulti={true}
                                name="colors"
                                options={allConditions}
                                className="basic-multi-select select2-selection"
                                onChange={v => {
                                  handleConditions(v)
                                }}
                                classNamePrefix="select"
                              />
                            </div>
                          </div>
                        )}
                        <button
                          size="sm"
                          className="btn btn-primary py-2 px-4 mt-2 "
                          onClick={onSubmit}
                          disabled={loading}
                        >
                          Submit
                        </button>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col sm="12">
                      <div className="modal-body w-full flex flex-col justify-center">
                        {allGroups && (
                          <div className="w-full ">
                            <div className="mb-3">
                              <label className="control-label">
                                Select Groups
                              </label>
                              <Select
                                isMulti={true}
                                name="colors"
                                options={allGroups}
                                className="basic-multi-select select2-selection"
                                onChange={v => {
                                  handleGroups(v)
                                }}
                                classNamePrefix="select"
                              />
                            </div>
                          </div>
                        )}
                        <button
                          size="sm"
                          className="btn btn-primary py-2 px-4 mt-2 "
                          onClick={onSubmit}
                          disabled={loading}
                        >
                          Submit
                        </button>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  )
}

export default AddContraindication
