//REGISTER
export const POST_REGISTER = "/user"

//LOGIN
export const POST_VERIFY_LOGIN = "/user/verify"
export const POST_LOGIN = "/login"
export const POST_JWT_Token = "user/embedding-token"

export const POST_PASSWORD_FORGET = "/user/forget-password"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"
export const UPDATE_PROFILE = "/user/update"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_BOT = "/user/findBot"
export const GET_BOT_MESSAGES = "/chat/custom-message"
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"
export const DELETE_MESSAGE = "/delete/message"

//TASKS
export const GET_TASKS = "/tasks"
export const DELETE_KANBAN = "/delete/tasks"
export const ADD_CARD_DATA = "/add/tasks"
export const UPDATE_CARD_DATA = "/update/tasks"

// UPLOADER
export const UPLOAD_FILE = "/user/image-uploader"
